import {
    UPDATE_STUDENT_CLASSES_REQUEST,
    UPDATE_STUDENT_CLASSES_SUCCESS,
    UPDATE_STUDENT_CLASSES_FAILURE,
    PROMOTE_STUDENT_CLASSES_FAILURE,
    PROMOTE_STUDENT_CLASSES_SUCCESS,
    PROMOTE_STUDENT_CLASSES_REQUEST,
    CLEAR_PROMOTION_RESULTS,
  } from "../actions/Types";
  
  const initialState = {
    loading: false,
    error: null,
    success: false,
    promoted: {
      successStudents: [],
      failedStudents: [],
    }, 
   };
  
  const StudentsReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_STUDENT_CLASSES_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
          success: false,
        };
  
      case UPDATE_STUDENT_CLASSES_SUCCESS:
        return {
          ...state,
          loading: false,
          success: true,
          error: null,
        };
  
      case UPDATE_STUDENT_CLASSES_FAILURE:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload,
        };


        case CLEAR_PROMOTION_RESULTS:
          return {
            ...state,
            promoted: {
              successStudents: [],
              failedStudents: [],
            },
            error: null,
          };


        case PROMOTE_STUDENT_CLASSES_REQUEST:
          return {
            ...state,
            loading: true,
            error: null,
            promoted: { successStudents: [], failedStudents: [] },
          };
    
        case PROMOTE_STUDENT_CLASSES_SUCCESS:
          return {
            ...state,
            loading: false,
            promoted: {
              successStudents: action.payload.successStudents,
              failedStudents: action.payload.failedStudents,
            },
            error: null,
          };
    
        case PROMOTE_STUDENT_CLASSES_FAILURE:
          return {
            ...state,
            loading: false,
            promoted: { successStudents: [], failedStudents: [] },
            error: action.payload,
          };
  
      default:
        return state;
    }
  };
  
  export default StudentsReducer;
  