// reducers/feesReducer.js
import {
    FETCH_FEE_TYPES_REQUEST,
    FETCH_FEE_TYPES_SUCCESS,
    FETCH_FEE_TYPES_FAILURE,
    CREATE_FEE_TYPE_REQUEST,
    CREATE_FEE_TYPE_SUCCESS,
    CREATE_FEE_TYPE_FAILURE,
    UPDATE_FEE_TYPE_REQUEST,
    UPDATE_FEE_TYPE_SUCCESS,
    UPDATE_FEE_TYPE_FAILURE,
    FETCH_ACTIVE_FEE_TYPES_REQUEST,
    CREATE_FEE_GROUP_REQUEST,
    FETCH_FEE_GROUPS_REQUEST,
    FETCH_ACTIVE_FEE_TYPES_SUCCESS,
    FETCH_FEE_GROUPS_SUCCESS,
    CREATE_FEE_GROUP_SUCCESS,
    FETCH_ACTIVE_FEE_TYPES_FAILURE,
    CREATE_FEE_GROUP_FAILURE,
    FETCH_FEE_GROUPS_FAILURE,
    UPDATE_FEE_GROUP_FAILURE,
    UPDATE_FEE_GROUP_SUCCESS,
    UPDATE_FEE_GROUP_REQUEST,
  } from '../actions/Types';
  
  const initialState = {
    feeTypes: [],
    loading: false,
    error: null,

    activeFeeTypes: [],
    feeGroups: [],
  };
  
  const FeesReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_FEE_TYPES_REQUEST:
        return { ...state, loading: true, error: null };
      case FETCH_FEE_TYPES_SUCCESS:
        return { ...state, loading: false, feeTypes: action.payload };
      case FETCH_FEE_TYPES_FAILURE:
        return { ...state, loading: false, error: action.payload };
        
      case CREATE_FEE_TYPE_REQUEST:
        return { ...state, loading: true, error: null };
      case CREATE_FEE_TYPE_SUCCESS:
        return { ...state, loading: false, feeTypes: [...state.feeTypes] };
        // return { ...state, loading: false, feeTypes: [...state.feeTypes, action.payload] };
      case CREATE_FEE_TYPE_FAILURE:
        return { ...state, loading: false, error: action.payload };
  
      case UPDATE_FEE_TYPE_REQUEST:
        return { ...state, loading: true, error: null };
      case UPDATE_FEE_TYPE_SUCCESS:
        return {
          ...state,
          loading: false,
        //   feeTypes: state.feeTypes.map(feeType => 
        //     feeType.id === action.payload.id ? action.payload : feeType
        //   ),
        };
      case UPDATE_FEE_TYPE_FAILURE:
        return { ...state, loading: false, error: action.payload };




        case FETCH_ACTIVE_FEE_TYPES_REQUEST:
          case CREATE_FEE_GROUP_REQUEST:
          case FETCH_FEE_GROUPS_REQUEST:
          case UPDATE_FEE_GROUP_REQUEST:
            return {
              ...state,
              loading: true,
              error: null,
            };
      
          case FETCH_ACTIVE_FEE_TYPES_SUCCESS:
            return {
              ...state,
              loading: false,
              activeFeeTypes: action.payload,
              error: null,
            };
      
          case FETCH_FEE_GROUPS_SUCCESS:
            return {
              ...state,
              loading: false,
              feeGroups: action.payload,
              error: null,
            };
      
          case CREATE_FEE_GROUP_SUCCESS:
            return {
              ...state,
              loading: false,
              // feeGroups: [...state.feeGroups, action.payload],
              error: null,
            };
      
          case UPDATE_FEE_GROUP_SUCCESS:
            return {
              ...state,
              loading: false,
              // feeGroups: state.feeGroups.map(group =>
              //   group.id === action.payload.id ? { ...group, ...action.payload } : group
              // ),
              error: null,
            };
      
          case FETCH_ACTIVE_FEE_TYPES_FAILURE:
          case CREATE_FEE_GROUP_FAILURE:
          case FETCH_FEE_GROUPS_FAILURE:
          case UPDATE_FEE_GROUP_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.payload,
            };
  
      default:
        return state;
    }
  };
  
  export default FeesReducer;
  