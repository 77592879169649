import { doc, updateDoc, arrayUnion, getDoc } from "firebase/firestore";
import {
  UPDATE_STUDENT_CLASSES_REQUEST,
  UPDATE_STUDENT_CLASSES_SUCCESS,
  UPDATE_STUDENT_CLASSES_FAILURE,
  PROMOTE_STUDENT_CLASSES_FAILURE,
  PROMOTE_STUDENT_CLASSES_SUCCESS,
  PROMOTE_STUDENT_CLASSES_REQUEST,
  CLEAR_PROMOTION_RESULTS,
} from "./Types";
import { db } from "../config/firebase";

export const updateStudentClasses = (selectedYear, selectedStudents, changeToClass, changeToStream) => async (dispatch) => {
  dispatch({ type: UPDATE_STUDENT_CLASSES_REQUEST });

  try {
    const updatePromises = selectedStudents.map(async (studentId) => {
      const studentRef = doc(db, "students", studentId);
      const studentSnap = await getDoc(studentRef);

      if (studentSnap.exists()) {
        const studentData = studentSnap.data();
        const updatedClasses = studentData.classes.map((entry) => {
          if (entry.year === selectedYear) {
            return { ...entry, class: changeToClass, stream: changeToStream };
          }
          return entry;
        });

        await updateDoc(studentRef, { classes: updatedClasses });
      }
    });

    await Promise.all(updatePromises); // Wait for all updates to complete
    dispatch({ type: UPDATE_STUDENT_CLASSES_SUCCESS });
  } catch (error) {
    console.error("Error updating student classes:", error);
    dispatch({
      type: UPDATE_STUDENT_CLASSES_FAILURE,
      payload: error.message,
    });
  }
};



/**
 * Promote students to a new class and stream for a specific year.
 * 
 * @param {string} newYear - The year to promote the students to.
 * @param {Array<string>} selectedStudents - Array of student IDs to promote.
 * @param {string} newClass - The new class to assign.
 * @param {string} newStream - The new stream to assign.
 */
export const promoteStudentClasses = (newYear, notToUpdate, selectedStudents, newClass, newStream) => async (dispatch) => {
  dispatch({ type: PROMOTE_STUDENT_CLASSES_REQUEST });

  try {
    const failedStudents = [...notToUpdate];
    const successStudents = [];

    const updatePromises = selectedStudents.map(async (studentId) => {
      const studentRef = doc(db, "students", studentId);
      const studentSnap = await getDoc(studentRef);

      if (studentSnap.exists()) {
        const studentData = studentSnap.data();
        const classes = studentData.classes || [];

        // Check if newYear already exists
        const yearExists = classes.some((entry) => entry.year === newYear);

        if (yearExists) {
          // Add to failedStudents if year already exists
          failedStudents.push(studentId);
        } else {
          // Add the new class entry
          const updatedClasses = [...classes, { year: newYear, class: newClass, stream: newStream }];
          await updateDoc(studentRef, { classes: updatedClasses });
          successStudents.push(studentId);
        }
      } else {
        // If student document doesn't exist, consider it a failure
        failedStudents.push(studentId);
      }
    });

    // Wait for all updates to complete
    await Promise.all(updatePromises);

    // Dispatch success with both success and failed student IDs
    dispatch({
      type: PROMOTE_STUDENT_CLASSES_SUCCESS,
      payload: { successStudents, failedStudents },
    });

    // Optionally, handle messages or further actions here
  } catch (error) {
    console.error("Error promoting student classes:", error);
    dispatch({
      type: PROMOTE_STUDENT_CLASSES_FAILURE,
      payload: error.message,
    });
  }
};


// export const clearPromotionResults = () => ({
//   type: CLEAR_PROMOTION_RESULTS,
// });
export const clearPromotionResults = () => (dispatch) => {

  dispatch({ type: CLEAR_PROMOTION_RESULTS });

  // type: CLEAR_PROMOTION_RESULTS,
};


