import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input, Select, Button, DatePicker, message, Tooltip, Breadcrumb, Layout } from 'antd';
import dayjs from 'dayjs';
import { createExpenseRecord, fetchExpenseTypes } from '../../actions/ExpensesActions';
import { fetchActiveStudyYears } from '../../actions/StudyYearActions';
import { Link } from 'react-router-dom';
import { DashboardOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const { Option } = Select;
const { Content } = Layout;

const StyledContent = styled(Content)`
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: var(--primary-background);
  border: 1px solid #bbb;
      border-radius: 8px;
`;

const RecordExpenses = () => {
  const dispatch = useDispatch();
  const [isOther, setIsOther] = useState(false);
  const { expenseTypes } = useSelector((state) => state.expenses);
  const activeYears = useSelector(state => state.studyYear.activeYears);

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(fetchExpenseTypes());
    // dispatch(fetchActiveStudyYears());

  }, [dispatch]);


  useEffect(() => {
    // Set initial value for yearID when activeYears is updated
    if (activeYears?.length > 0) {
      form.setFieldsValue({ yearID: activeYears[0].id });
    }
  }, [activeYears, form]);

  // const activeExpenseTypes = expenseTypes.filter((expense) => expense.active);

  const handleSubmit = (values) => {
    const { yearID, expenseTypeID, name, description, amount, dateOfExpense } = values;

    if (isOther && !name) {
      message.error('Please provide a name for the expense.');
      return;
    }

    const payload = {
      name: isOther ? name : '',
      expenseTypeID: isOther ? '' : expenseTypeID,
      amount: parseInt(amount),
      dateOfExpense: dayjs(dateOfExpense).valueOf(),
      yearID,
      description,
      active: true,
      time: dayjs().valueOf(),
    };
    // console.log(payload)
    dispatch(createExpenseRecord(payload));
    message.success('Expense record added successfully.');
    form.resetFields();

  };

  return (
    <div style={{ padding: 20 }}>
      {/* <h2>Record Expense</h2> */}
      
      <Breadcrumb
                style={{ marginBottom: 10 }}
                items={[
                    {
                        title: <Link to='/'><DashboardOutlined /></Link>,
                    },
                    {
                        title: "Expenses",
                    },
                    {
                        title: 'Record Expenses'
                    },

                ]}
            />
        <StyledContent>

      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          dateOfExpense: dayjs(),
          // yearID: activeYears?.length > 0 ? activeYears[0].id : undefined, // Set the first option as default
        }}
      >
        <Form.Item
          name="yearID"
          label="Study Year"
          rules={[{ required: true, message: 'Please select Study Year!' }]}
        >
          <Select placeholder="Select Study Year">
            {activeYears?.map((year) => (
              <Option key={year.id} value={year.id}>
                <Tooltip title={year.school}>{year.name}</Tooltip>
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="expenseTypeID"
          label="Expense Type"
          rules={[{ required: true, message: 'Please select an expense type!' }]}
        >
          <Select
            placeholder="Select an expense type"
            onChange={(value) => setIsOther(value === 'other')}
          >
            {expenseTypes?.map((type) => (
              <Option key={type.id} value={type.id}>
                <Tooltip title={type.description}>
                  {type.name}
                </Tooltip>
              </Option>
            ))}
            <Option value="other">Other</Option>
          </Select>
        </Form.Item>

        {isOther && (
          <Form.Item
            name="name"
            label="Expense Name"
            rules={[{ required: true, message: 'Please provide a name!' }]}
          >
            <Input placeholder="Enter the name of the expense" />
          </Form.Item>
        )}

        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please provide a description!' }]}
        >
          <Input.TextArea rows={3} placeholder="Enter a description of the expense" />
        </Form.Item>

        <Form.Item
          name="amount"
          label="Amount"
          rules={[
            { required: true, message: 'Please provide an amount!' },
            // { type: 'number', min: 0, message: 'Amount must be a positive number!' },
          ]}
        >
          <Input type="number" placeholder="Enter the amount" />
        </Form.Item>

        <Form.Item name="dateOfExpense" label="Date of Expense">
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      </StyledContent>
    </div>
  );
};

export default RecordExpenses;
