
export const TOGGLE_THEME = "TOGGLE_THEME";

//............CONTACT ACTIONS
export const ADD_EMAIL = "add_email";
export const ADD_EMAIL_SUCCESS = "add_email_success";
export const ADD_EMAIL_FAILURE = "add_email_failure";

export const CREATE_MESSAGE = "create_message";
export const CREATE_MESSAGE_SUCCESS = "create_message_success";
export const CREATE_MESSAGE_FAILURE = "create_message_failure";

export const FETCH_ALL_NEWSLETTERS_REQUEST = 'FETCH_ALL_NEWSLETTERS_REQUEST';
export const FETCH_ALL_NEWSLETTERS_SUCCESS = 'FETCH_ALL_NEWSLETTERS_SUCCESS';
export const FETCH_ALL_NEWSLETTERS_FAILURE = 'FETCH_ALL_NEWSLETTERS_FAILURE';

export const DELETE_NEWSLETTER_REQUEST = 'DELETE_NEWSLETTER_REQUEST';
export const DELETE_NEWSLETTER_SUCCESS = 'DELETE_NEWSLETTER_SUCCESS';
export const DELETE_NEWSLETTER_FAILURE = 'DELETE_NEWSLETTER_FAILURE';

export const FETCH_ALL_MESSAGES_REQUEST = 'FETCH_ALL_MESSAGES_REQUEST';
export const FETCH_ALL_MESSAGES_SUCCESS = 'FETCH_ALL_MESSAGES_SUCCESS';
export const FETCH_ALL_MESSAGES_FAILURE = 'FETCH_ALL_MESSAGES_FAILURE';

export const DELETE_MESSAGE_REQUEST = 'DELETE_MESSAGE_REQUEST';
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_FAILURE = 'DELETE_MESSAGE_FAILURE';




//.........AUTH ACTIONS
export const AUTH_FIELD_CHANGED = "auth_field_changed";
export const LOGIN_USER = "login_user";
export const LOGIN_USER_SUCCESSFUL = "login_user_successful";
export const LOGIN_USER_FAILED = "login_user_failed";
export const LOGOUT_USER = "logout_user";
export const LOGOUT_USER_SUCCESSFUL = "logout_user_successful";
export const LOGOUT_USER_FAILED = "logout_user_successful";
export const REGISTER_USER = "register_user";
export const REGISTER_USER_SUCCESSFUL = "register_user_successful";
export const REGISTER_USER_FAILED = "register_user_failed";

//.......MESSAGE ACTIONS
export const SET_MESSAGE = "set_message";
export const CLEAR_MESSAGE = "clear_message";

//.......LAUNCH ACTIONS
export const LOGOUT = 'logout';

//.......ACTIVITY ACTIONS
export const DELETE_ACTIVITY_SUCCESS = 'delete_activity_success';
export const DELETE_ACTIVITY_FAILURE = 'delete_activity_failure';



//.......PICTURE ACTIONS
export const FETCH_PICTURES_REQUEST = 'fetch_pictures_request';
export const FETCH_PICTURES_SUCCESS = 'fetch_pictures_success';
export const FETCH_PICTURES_FAILURE = 'fetch_pictures_failure';

export const ADD_PICTURE_COLLECTION_REQUEST = 'add_picture_collection_request';
export const ADD_PICTURE_COLLECTION_SUCCESS = 'add_picture_collection_success';
export const ADD_PICTURE_COLLECTION_FAILURE = 'add_picture_collection_failure';

export const EDIT_PICTURE_COLLECTION_REQUEST = 'edit_picture_collection_request';
export const EDIT_PICTURE_COLLECTION_SUCCESS = 'edit_picture_collection_success';
export const EDIT_PICTURE_COLLECTION_FAILURE = 'edit_picture_collection_failure';

export const DELETE_PICTURE_COLLECTION_REQUEST = 'delete_picture_collection_request';
export const DELETE_PICTURE_COLLECTION_SUCCESS = 'delete_picture_collection_success';
export const DELETE_PICTURE_COLLECTION_FAILURE = 'delete_picture_collection_failure';

export const ADD_IMAGE_TO_COLLECTION_REQUEST = 'add_image_to_collection_request';
export const ADD_IMAGE_TO_COLLECTION_SUCCESS = 'add_image_to_collection_success';
export const ADD_IMAGE_TO_COLLECTION_FAILURE = 'add_image_to_collection_failure';

export const DELETE_IMAGE_FROM_COLLECTION_REQUEST = 'delete_image_from_collection_request';
export const DELETE_IMAGE_FROM_COLLECTION_SUCCESS = 'delete_image_from_collection_success';
export const DELETE_IMAGE_FROM_COLLECTION_FAILURE = 'delete_image_from_collection_failure';






















/////////// STUDY YEAR ACTIONS
export const FETCH_ALL_STUDY_YEARS_REQUEST = 'FETCH_ALL_STUDY_YEARS_REQUEST';
export const FETCH_ALL_STUDY_YEARS_SUCCESS = 'FETCH_ALL_STUDY_YEARS_SUCCESS';
export const FETCH_ALL_STUDY_YEARS_FAILURE = 'FETCH_ALL_STUDY_YEARS_FAILURE';

export const CREATE_STUDY_YEAR_REQUEST = 'CREATE_STUDY_YEAR_REQUEST';
export const CREATE_STUDY_YEAR_SUCCESS = 'CREATE_STUDY_YEAR_SUCCESS';
export const CREATE_STUDY_YEAR_FAILURE = 'CREATE_STUDY_YEAR_FAILURE';

export const DEACTIVATE_STUDY_YEAR_REQUEST = 'DEACTIVATE_STUDY_YEAR_REQUEST';
export const DEACTIVATE_STUDY_YEAR_SUCCESS = 'DEACTIVATE_STUDY_YEAR_SUCCESS';
export const DEACTIVATE_STUDY_YEAR_FAILURE = 'DEACTIVATE_STUDY_YEAR_FAILURE';

export const ACTIVATE_STUDY_YEAR_REQUEST = 'ACTIVATE_STUDY_YEAR_REQUEST';
export const ACTIVATE_STUDY_YEAR_SUCCESS = 'ACTIVATE_STUDY_YEAR_SUCCESS';
export const ACTIVATE_STUDY_YEAR_FAILURE = 'ACTIVATE_STUDY_YEAR_FAILURE';




// Action Types
export const EDIT_CLASS_REQUEST = 'EDIT_CLASS_REQUEST';
export const EDIT_CLASS_SUCCESS = 'EDIT_CLASS_SUCCESS';
export const EDIT_CLASS_FAILURE = 'EDIT_CLASS_FAILURE';



// Action types
export const FETCH_CLASSES_REQUEST = 'FETCH_CLASSES_REQUEST';
export const FETCH_CLASSES_SUCCESS = 'FETCH_CLASSES_SUCCESS';
export const FETCH_CLASSES_FAILURE = 'FETCH_CLASSES_FAILURE';



// Action Types
export const CREATE_STUDENT_REQUEST = 'CREATE_STUDENT_REQUEST';
export const CREATE_STUDENT_SUCCESS = 'CREATE_STUDENT_SUCCESS';
export const CREATE_STUDENT_FAILURE = 'CREATE_STUDENT_FAILURE';

export const FETCH_STUDENTS_REQUEST = 'FETCH_STUDENTS_REQUEST';
export const FETCH_STUDENTS_SUCCESS = 'FETCH_STUDENTS_SUCCESS';
export const FETCH_STUDENTS_FAILURE = 'FETCH_STUDENTS_FAILURE';

export const EDIT_STUDENT_REQUEST = 'EDIT_STUDENT_REQUEST';
export const EDIT_STUDENT_SUCCESS = 'EDIT_STUDENT_SUCCESS';
export const EDIT_STUDENT_FAILURE = 'EDIT_STUDENT_FAILURE';




// Action Types
export const FETCH_FEE_TYPES_REQUEST = 'FETCH_FEE_TYPES_REQUEST';
export const FETCH_FEE_TYPES_SUCCESS = 'FETCH_FEE_TYPES_SUCCESS';
export const FETCH_FEE_TYPES_FAILURE = 'FETCH_FEE_TYPES_FAILURE';

export const CREATE_FEE_TYPE_REQUEST = 'CREATE_FEE_TYPE_REQUEST';
export const CREATE_FEE_TYPE_SUCCESS = 'CREATE_FEE_TYPE_SUCCESS';
export const CREATE_FEE_TYPE_FAILURE = 'CREATE_FEE_TYPE_FAILURE';

export const UPDATE_FEE_TYPE_REQUEST = 'UPDATE_FEE_TYPE_REQUEST';
export const UPDATE_FEE_TYPE_SUCCESS = 'UPDATE_FEE_TYPE_SUCCESS';
export const UPDATE_FEE_TYPE_FAILURE = 'UPDATE_FEE_TYPE_FAILURE';




// actions/actionTypes.js

export const FETCH_ACTIVE_FEE_TYPES_REQUEST = 'FETCH_ACTIVE_FEE_TYPES_REQUEST';
export const FETCH_ACTIVE_FEE_TYPES_SUCCESS = 'FETCH_ACTIVE_FEE_TYPES_SUCCESS';
export const FETCH_ACTIVE_FEE_TYPES_FAILURE = 'FETCH_ACTIVE_FEE_TYPES_FAILURE';

export const CREATE_FEE_GROUP_REQUEST = 'CREATE_FEE_GROUP_REQUEST';
export const CREATE_FEE_GROUP_SUCCESS = 'CREATE_FEE_GROUP_SUCCESS';
export const CREATE_FEE_GROUP_FAILURE = 'CREATE_FEE_GROUP_FAILURE';

export const UPDATE_FEE_GROUP_REQUEST = 'UPDATE_FEE_GROUP_REQUEST';
export const UPDATE_FEE_GROUP_SUCCESS = 'UPDATE_FEE_GROUP_SUCCESS';
export const UPDATE_FEE_GROUP_FAILURE = 'UPDATE_FEE_GROUP_FAILURE';

export const FETCH_FEE_GROUPS_REQUEST = 'FETCH_FEE_GROUPS_REQUEST';
export const FETCH_FEE_GROUPS_SUCCESS = 'FETCH_FEE_GROUPS_SUCCESS';
export const FETCH_FEE_GROUPS_FAILURE = 'FETCH_FEE_GROUPS_FAILURE';

// Add other action types as needed...


// Action Types
export const FETCH_ACTIVE_ALLOCATIONS_START = 'FETCH_ACTIVE_ALLOCATIONS_START';
export const FETCH_ACTIVE_ALLOCATIONS_SUCCESS = 'FETCH_ACTIVE_ALLOCATIONS_SUCCESS';
export const FETCH_ACTIVE_ALLOCATIONS_ERROR = 'FETCH_ACTIVE_ALLOCATIONS_ERROR';

export const SAVE_ALLOCATION_START = 'SAVE_ALLOCATION_START';
export const SAVE_ALLOCATION_SUCCESS = 'SAVE_ALLOCATION_SUCCESS';
export const SAVE_ALLOCATION_ERROR = 'SAVE_ALLOCATION_ERROR';

export const DEACTIVATE_ALLOCATION_START = 'DEACTIVATE_ALLOCATION_START';
export const DEACTIVATE_ALLOCATION_SUCCESS = 'DEACTIVATE_ALLOCATION_SUCCESS';
export const DEACTIVATE_ALLOCATION_ERROR = 'DEACTIVATE_ALLOCATION_ERROR';



// Action Types
export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";


// Action types
export const FETCH_PAYMENT_SYSTEMS_REQUEST = 'FETCH_PAYMENT_SYSTEMS_REQUEST';
export const FETCH_PAYMENT_SYSTEMS_SUCCESS = 'FETCH_PAYMENT_SYSTEMS_SUCCESS';
export const FETCH_PAYMENT_SYSTEMS_FAILURE = 'FETCH_PAYMENT_SYSTEMS_FAILURE';

export const CREATE_PAYMENT_SYSTEM_REQUEST = 'CREATE_PAYMENT_SYSTEM_REQUEST';
export const CREATE_PAYMENT_SYSTEM_SUCCESS = 'CREATE_PAYMENT_SYSTEM_SUCCESS';
export const CREATE_PAYMENT_SYSTEM_FAILURE = 'CREATE_PAYMENT_SYSTEM_FAILURE';

export const UPDATE_PAYMENT_SYSTEM_REQUEST = 'UPDATE_PAYMENT_SYSTEM_REQUEST';
export const UPDATE_PAYMENT_SYSTEM_SUCCESS = 'UPDATE_PAYMENT_SYSTEM_SUCCESS';
export const UPDATE_PAYMENT_SYSTEM_FAILURE = 'UPDATE_PAYMENT_SYSTEM_FAILURE';

export const FETCH_ACTIVE_PAYMENT_SYSTEMS_REQUEST = 'FETCH_ACTIVE_PAYMENT_SYSTEMS_REQUEST';
export const FETCH_ACTIVE_PAYMENT_SYSTEMS_SUCCESS = 'FETCH_ACTIVE_PAYMENT_SYSTEMS_SUCCESS';
export const FETCH_ACTIVE_PAYMENT_SYSTEMS_FAILURE = 'FETCH_ACTIVE_PAYMENT_SYSTEMS_FAILURE';


export const CREATE_PAYMENT_RECORD_REQUEST = 'CREATE_PAYMENT_RECORD_REQUEST';
export const CREATE_PAYMENT_RECORD_SUCCESS = 'CREATE_PAYMENT_RECORD_SUCCESS';
export const CREATE_PAYMENT_RECORD_FAILURE = 'CREATE_PAYMENT_RECORD_FAILURE';

export const FETCH_ACTIVE_PAYMENT_RECORDS_REQUEST = 'FETCH_ACTIVE_PAYMENT_RECORDS_REQUEST';
export const FETCH_ACTIVE_PAYMENT_RECORDS_SUCCESS = 'FETCH_ACTIVE_PAYMENT_RECORDS_SUCCESS';
export const FETCH_ACTIVE_PAYMENT_RECORDS_FAILURE = 'FETCH_ACTIVE_PAYMENT_RECORDS_FAILURE';

export const DELETE_PAYMENT_RECORD_REQUEST = "DELETE_PAYMENT_RECORD_REQUEST";
export const DELETE_PAYMENT_RECORD_SUCCESS = "DELETE_PAYMENT_RECORD_SUCCESS";
export const DELETE_PAYMENT_RECORD_FAILURE = "DELETE_PAYMENT_RECORD_FAILURE";





// Expense Types
export const FETCH_EXPENSE_TYPES_REQUEST = 'FETCH_EXPENSE_TYPES_REQUEST';
export const FETCH_EXPENSE_TYPES_SUCCESS = 'FETCH_EXPENSE_TYPES_SUCCESS';
export const FETCH_EXPENSE_TYPES_FAILURE = 'FETCH_EXPENSE_TYPES_FAILURE';

export const CREATE_EXPENSE_TYPE_REQUEST = 'CREATE_EXPENSE_TYPE_REQUEST';
export const CREATE_EXPENSE_TYPE_SUCCESS = 'CREATE_EXPENSE_TYPE_SUCCESS';
export const CREATE_EXPENSE_TYPE_FAILURE = 'CREATE_EXPENSE_TYPE_FAILURE';

export const UPDATE_EXPENSE_TYPE_REQUEST = 'UPDATE_EXPENSE_TYPE_REQUEST';
export const UPDATE_EXPENSE_TYPE_SUCCESS = 'UPDATE_EXPENSE_TYPE_SUCCESS';
export const UPDATE_EXPENSE_TYPE_FAILURE = 'UPDATE_EXPENSE_TYPE_FAILURE';

export const DELETE_EXPENSE_TYPE_REQUEST = 'DELETE_EXPENSE_TYPE_REQUEST';
export const DELETE_EXPENSE_TYPE_SUCCESS = 'DELETE_EXPENSE_TYPE_SUCCESS';
export const DELETE_EXPENSE_TYPE_FAILURE = 'DELETE_EXPENSE_TYPE_FAILURE';




export const CREATE_EXPENSE_RECORD_REQUEST = 'CREATE_EXPENSE_RECORD_REQUEST';
export const CREATE_EXPENSE_RECORD_SUCCESS = 'CREATE_EXPENSE_RECORD_SUCCESS';
export const CREATE_EXPENSE_RECORD_FAILURE = 'CREATE_EXPENSE_RECORD_FAILURE';

export const FETCH_EXPENSE_RECORDS_REQUEST = 'FETCH_EXPENSE_RECORDS_REQUEST';
export const FETCH_EXPENSE_RECORDS_SUCCESS = 'FETCH_EXPENSE_RECORDS_SUCCESS';
export const FETCH_EXPENSE_RECORDS_FAILURE = 'FETCH_EXPENSE_RECORDS_FAILURE';

export const DELETE_EXPENSE_RECORD_REQUEST = 'DELETE_EXPENSE_RECORD_REQUEST';
export const DELETE_EXPENSE_RECORD_SUCCESS = 'DELETE_EXPENSE_RECORD_SUCCESS';
export const DELETE_EXPENSE_RECORD_FAILURE = 'DELETE_EXPENSE_RECORD_FAILURE';




export const UPDATE_STUDENT_CLASSES_REQUEST = "UPDATE_STUDENT_CLASSES_REQUEST";
export const UPDATE_STUDENT_CLASSES_SUCCESS = "UPDATE_STUDENT_CLASSES_SUCCESS";
export const UPDATE_STUDENT_CLASSES_FAILURE = "UPDATE_STUDENT_CLASSES_FAILURE";

export const CLEAR_PROMOTION_RESULTS = "CLEAR_PROMOTION_RESULTS";

export const PROMOTE_STUDENT_CLASSES_REQUEST = "PROMOTE_STUDENT_CLASSES_REQUEST";
export const PROMOTE_STUDENT_CLASSES_SUCCESS = "PROMOTE_STUDENT_CLASSES_SUCCESS";
export const PROMOTE_STUDENT_CLASSES_FAILURE = "PROMOTE_STUDENT_CLASSES_FAILURE";





// Fetch schools
export const FETCH_SCHOOLS_REQUEST = 'FETCH_SCHOOLS_REQUEST';
export const FETCH_SCHOOLS_SUCCESS = 'FETCH_SCHOOLS_SUCCESS';
export const FETCH_SCHOOLS_FAILURE = 'FETCH_SCHOOLS_FAILURE';

// Create school
export const CREATE_SCHOOL_REQUEST = 'CREATE_SCHOOL_REQUEST';
export const CREATE_SCHOOL_SUCCESS = 'CREATE_SCHOOL_SUCCESS';
export const CREATE_SCHOOL_FAILURE = 'CREATE_SCHOOL_FAILURE';

// Update school
export const UPDATE_SCHOOL_REQUEST = 'UPDATE_SCHOOL_REQUEST';
export const UPDATE_SCHOOL_SUCCESS = 'UPDATE_SCHOOL_SUCCESS';
export const UPDATE_SCHOOL_FAILURE = 'UPDATE_SCHOOL_FAILURE';
