// Login.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Form, Input, Button, Typography, Spin, Alert, AutoComplete, Row, Col, Divider, Image } from 'antd';
import styled from 'styled-components';
import { loginUser } from '../../actions/AuthActions';
import logo from '../../assets/swillaLogo.png';
import { Navigate } from 'react-router-dom';
import { MoonFilled, SunFilled } from '@ant-design/icons';
import { toggleTheme } from '../../actions/UserActions';

const { Content } = Layout;
const { Title, Text } = Typography;

const Login = () => {
    const dispatch = useDispatch();
    const { loading, error } = useSelector((state) => state.auth); // Assuming state.auth has loading & error
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const user = useSelector((state) => state.user.user); // Assuming user data is in Redux

    const isLightTheme = useSelector((state) => state.user.theme.isLightTheme);

    const handleToggle = () => {
        dispatch(toggleTheme()); // Dispatch toggle action
    };
    useEffect(() => {
        const newTheme = isLightTheme ? "light" : "dark";
    
        document.documentElement.setAttribute("data-theme", newTheme);
      }, [isLightTheme]);

    if (user) {
        // User logged in
        return <Navigate to="/" />;
    }

    const handleLogin = () => {
        dispatch(loginUser(email, password));
    };

    const mapFirebaseAuthError = (errorCode) => {
        switch (errorCode) {
            case 'auth/invalid-email':
                return 'The email address is not valid.';
            case 'auth/user-disabled':
                return 'The user account has been disabled.';
            case 'auth/user-not-found':
                return 'No user found with this email address.';
            case 'auth/wrong-password':
                return 'Incorrect password. Please try again.';
            case 'auth/email-already-in-use':
                return 'The email address is already in use by another account.';
            case 'auth/operation-not-allowed':
                return 'Password sign-in is disabled for this project.';
            case 'auth/weak-password':
                return 'The password is too weak. Please choose a stronger password.';
            case 'auth/too-many-requests':
                return 'We have blocked all requests from this device due to unusual activity. Please try again later.';
            case 'auth/requires-recent-login':
                return 'Please log in again to perform this action.';
            case 'auth/network-request-failed':
                return 'A network error occurred. Please check your connection.';
            case 'auth/popup-closed-by-user':
                return 'The popup was closed before completing the sign in. Please try again.';
            case 'auth/account-exists-with-different-credential':
                return 'An account already exists with the same email address but different sign-in credentials.';
            case 'auth/invalid-credential':
                return 'Incorrect password. Please try again.';
            case 'auth/invalid-verification-code':
                return 'The verification code is not valid.';
            case 'auth/invalid-verification-id':
                return 'The verification ID is not valid.';
            case 'auth/missing-verification-code':
                return 'The verification code is missing.';
            case 'auth/missing-verification-id':
                return 'The verification ID is missing.';
            case 'auth/credential-already-in-use':
                return 'This credential is already associated with a different user account.';
            case 'auth/invalid-phone-number':
                return 'The phone number provided is not valid.';
            case 'auth/missing-phone-number':
                return 'Please provide a valid phone number.';
            case 'auth/invalid-password':
                return 'The password is invalid. Please ensure your password is correct.';
            case 'auth/quota-exceeded':
                return 'The quota for sending SMS messages has been exceeded. Please try again later.';
            case 'auth/missing-email':
                return 'An email address must be provided.';
            case 'auth/unverified-email':
                return 'Your email address is not verified. Please verify your email first.';
            case 'auth/app-not-authorized':
                return 'This app is not authorized to use Firebase Authentication.';
            case 'auth/expired-action-code':
                return 'The action code has expired. Please try again.';
            case 'auth/invalid-action-code':
                return 'The action code is invalid. Please try again.';
            case 'auth/user-token-expired':
                return 'Your session has expired. Please log in again.';
            case 'auth/user-mismatch':
                return 'The supplied credentials do not match the previously signed-in user.';
            case 'auth/invalid-api-key':
                return 'The API key provided is invalid.';
            case 'auth/app-deleted':
                return 'The Firebase app has been deleted. Please contact support.';
            case 'auth/cors-unsupported':
                return 'CORS is not supported by your browser.';
            default:
                return 'An unexpected error occurred. Please try again.';
        }
    };

    return (
        <LayoutStyled>
            <ContentStyled>
                <FormStyled onFinish={handleLogin}>
                    <RowStyled align="middle">
                        {/* Logo */}
                        <ColStyled>
                            <ImageStyled style={{ width: '150px', height: '150px' }} src={logo} alt="Logo" preview={false} />
                        </ColStyled>

                        {/* Divider */}
                        <ColStyled>
                            <DividerStyled type="vertical" />
                        </ColStyled>

                        {/* Title */}
                        <Col style={{ alignItems: 'center' }}>
                            <Title level={2} style={{ color: 'var(--primary)', margin: 0 }}>Swilla Schools</Title>
                            <Title level={2} style={{ color: 'var(--primary)', margin: 0 }}>Fees System</Title>
                            <Title level={2} style={{ color: 'var(--primary)', margin: 0 }}>Login</Title>
                        </Col>
                    </RowStyled>
                    {error && <AlertStyled type="error" message={mapFirebaseAuthError(error.code)} />}

                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please enter your email' }]}
                    >
                        <AutoComplete
                            options={[
                                // { value: 'example@tanzator.com' },
                                // { value: 'user@yahoo.com' }
                            ]}
                            placeholder="Email"
                            onChange={(value) => setEmail(value)}
                        >
                            <InputStyled />
                        </AutoComplete>
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please enter your password' }]}
                    >
                        <InputStyled.Password
                            style={{ borderColor: 'var(--secondary)' }}
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <ButtonStyled type="primary" htmlType="submit" disabled={loading}>
                            {loading ? <Spin /> : 'Log In'}
                        </ButtonStyled>
                    </Form.Item>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={{ textAlign: 'center', color: 'var(--secondary-text)' }}>
                            {/* Don’t have an account? <a href="/register">Register now</a> */}
                            Authorized personnel only!
                        </Text>
                        <Button onClick={handleToggle} htmlType="button" style={{ border: "none", background: "transparent", cursor: "pointer" }}>
                            {isLightTheme ? <MoonFilled style={{ fontSize: "20px", color: "var(--primary-text)" }} /> : <SunFilled style={{ fontSize: "20px", color: "var(--primary-text)" }} />}
                        </Button>
                    </div>

                </FormStyled>
            </ContentStyled>
        </LayoutStyled>
    );
};

export default Login;

// Styled Components
const LayoutStyled = styled(Layout)`
  min-height: 100vh;
  background-color: var(--primary-background);
`;

const ContentStyled = styled(Content)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
`;

const FormStyled = styled(Form)`
  max-width: 600px;
  width: 100%;
  padding: 24px;
  background-color: var(--secondary-background);
  border-radius: 8px;
  border: 1px solid var(--secondary);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const InputStyled = styled(Input)`
  border-radius: 4px;

&.ant-input-outlined:hover {
    border-color: var(--secondary);
  }
  
`;

const ButtonStyled = styled(Button)`
  width: 100%;
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;

  &:hover, &:focus {
    background-color: var(--secondary);
    border-color: var(--secondary);
    color: #fff;
  }

  // Target solid variant specifically, if you're using variant styles
  &.ant-btn-primary {
    background-color: var(--primary);
    border-color: var(--primary);
  }

  &.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover,
  &.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):focus {
    background-color: var(--secondary);
    border-color: var(--secondary);
  }
`;


const AlertStyled = styled(Alert)`
  margin-bottom: 16px;
`;

const RowStyled = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom:30px;
`;

const ColStyled = styled(Col)`
  display: flex;
  align-items: center;
`;

const DividerStyled = styled(Divider)`
  height: 50px;
  background-color: var(--primary);
  margin-left:20px;
  margin-right:20px;
`;

const ImageStyled = styled(Image)`
// &.ant-image .ant-image-img{
//     width: 40px;
//     height: 40px;
//     }
`;