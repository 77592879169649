// actions/FeesActions.js
import { collection, onSnapshot, addDoc, updateDoc, doc } from 'firebase/firestore';
import { CREATE_FEE_GROUP_FAILURE, CREATE_FEE_GROUP_REQUEST, CREATE_FEE_GROUP_SUCCESS, CREATE_FEE_TYPE_FAILURE, CREATE_FEE_TYPE_REQUEST, CREATE_FEE_TYPE_SUCCESS, FETCH_ACTIVE_FEE_TYPES_FAILURE, FETCH_ACTIVE_FEE_TYPES_REQUEST, FETCH_ACTIVE_FEE_TYPES_SUCCESS, FETCH_FEE_GROUPS_FAILURE, FETCH_FEE_GROUPS_REQUEST, FETCH_FEE_GROUPS_SUCCESS, FETCH_FEE_TYPES_FAILURE, FETCH_FEE_TYPES_REQUEST, FETCH_FEE_TYPES_SUCCESS, UPDATE_FEE_GROUP_FAILURE, UPDATE_FEE_GROUP_REQUEST, UPDATE_FEE_GROUP_SUCCESS, UPDATE_FEE_TYPE_FAILURE, UPDATE_FEE_TYPE_REQUEST, UPDATE_FEE_TYPE_SUCCESS } from './Types';
import { db } from '../config/firebase';



// Fetch Fee Types
export const fetchFeeTypes = () => dispatch => {
  dispatch({ type: FETCH_FEE_TYPES_REQUEST });
  
  const unsubscribe = onSnapshot(collection(db, 'feeTypes'), (snapshot) => {
    const feeTypes = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    dispatch({
      type: FETCH_FEE_TYPES_SUCCESS,
      payload: feeTypes,
    });
  }, (error) => {
    dispatch({
      type: FETCH_FEE_TYPES_FAILURE,
      payload: error.message,
    });
  });

  return unsubscribe; // Optionally return the unsubscribe function
};

// Create Fee Type
export const createFeeType = (feeType) => async dispatch => {
  dispatch({ type: CREATE_FEE_TYPE_REQUEST });

  try {
    const docRef = await addDoc(collection(db, 'feeTypes'), feeType);
    const newFeeType = { id: docRef.id, ...feeType };

    dispatch({
      type: CREATE_FEE_TYPE_SUCCESS,
      payload: newFeeType,
    });
  } catch (error) {
    dispatch({
      type: CREATE_FEE_TYPE_FAILURE,
      payload: error.message,
    });
  }
};

// Update Fee Type
export const updateFeeType = (feeType) => async dispatch => {
  dispatch({ type: UPDATE_FEE_TYPE_REQUEST });

  try {
    const feeDoc = doc(db, 'feeTypes', feeType.id);
    await updateDoc(feeDoc, feeType);

    dispatch({
      type: UPDATE_FEE_TYPE_SUCCESS,
      payload: feeType,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_FEE_TYPE_FAILURE,
      payload: error.message,
    });
  }
};


// Fetch Active Fee Types
export const fetchActiveFeeTypes = () => dispatch => {
  dispatch({ type: FETCH_ACTIVE_FEE_TYPES_REQUEST });

  const unsubscribe = onSnapshot(collection(db, 'feeTypes'), snapshot => {
    const feeTypes = snapshot.docs
      ?.filter(doc => doc.data()?.active !== false) // Only fetch active fee types
      ?.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));


    dispatch({ type: FETCH_ACTIVE_FEE_TYPES_SUCCESS, payload: feeTypes });
  }, error => {
    console.error("Error fetching active fee types: ", error);
    dispatch({ type: FETCH_ACTIVE_FEE_TYPES_FAILURE, payload: error.message });
  });

  return unsubscribe; // Return the unsubscribe function to clean up the listener when necessary
};

// Create Fee Group
export const createFeeGroup = (feeGroup) => async dispatch => {
  dispatch({ type: CREATE_FEE_GROUP_REQUEST });

  try {
    const docRef = await addDoc(collection(db, 'feeGroups'), feeGroup);
    const createdGroup = { id: docRef.id, ...feeGroup };

    dispatch({ type: CREATE_FEE_GROUP_SUCCESS, payload: createdGroup });
  } catch (error) {
    console.error("Error creating fee group: ", error);
    dispatch({ type: CREATE_FEE_GROUP_FAILURE, payload: error.message });
  }
};

// Fetch Fee Groups
export const fetchActiveFeeGroups = () => dispatch => {
  dispatch({ type: FETCH_FEE_GROUPS_REQUEST });

  const unsubscribe = onSnapshot(collection(db, 'feeGroups'), snapshot => {
    const feeGroups = snapshot.docs
      .filter(doc => doc.data().active) // Only fetch active fee groups
      .map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

    dispatch({ type: FETCH_FEE_GROUPS_SUCCESS, payload: feeGroups });
  }, error => {
    console.error("Error fetching fee groups: ", error);
    dispatch({ type: FETCH_FEE_GROUPS_FAILURE, payload: error.message });
  });

  return unsubscribe; // Return the unsubscribe function to clean up the listener when necessary
};

// Update Fee Group
export const updateFeeGroup = (feeGroupId, updatedData) => async dispatch => {
  dispatch({ type: UPDATE_FEE_GROUP_REQUEST });

  try {
    const feeGroupRef = doc(db, 'feeGroups', feeGroupId);
    await updateDoc(feeGroupRef, updatedData);

    dispatch({ type: UPDATE_FEE_GROUP_SUCCESS, payload: { id: feeGroupId, ...updatedData } });
  } catch (error) {
    console.error("Error updating fee group: ", error);
    dispatch({ type: UPDATE_FEE_GROUP_FAILURE, payload: error.message });
  }
};