import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Typography, Select, Breadcrumb } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { createSchool, fetchSchools, updateSchool } from '../../actions/SchoolActions';
import { DashboardOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Title } = Typography;
const { Option } = Select;

const SchoolsContainer = styled.div`
  padding: 20px;
`;

const StyledButton = styled(Button)`
  margin-bottom: 16px;
`;

const Schools = () => {
  const dispatch = useDispatch();
  const schools = useSelector(state => state.school.schoolList); // Assume your Redux state has schools
  const loading = useSelector(state => state.school.loading);
  const error = useSelector(state => state.school.error);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [editingSchool, setEditingSchool] = useState(null);
  const [dataSource, setDataSource] = useState([]);

  // useEffect(() => {
  //   const unsubscribe = dispatch(fetchSchools());
  //   return () => unsubscribe && unsubscribe();
  // }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const source = schools
      ?.map((school) => ({
        ...school,
        key: school.id, // Assuming school has a unique id
      })) || [];
    setDataSource(source);
  }, [schools]);

  const handleOpenModal = () => {
    setVisible(true);
    form.resetFields();
    setEditingSchool(null);
  };

  const handleCloseModal = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleCreateOrUpdateSchool = async (values) => {
    if (editingSchool) {
      await dispatch(updateSchool({ ...values, id: editingSchool.id }));
    } else {
      await dispatch(createSchool({
        ...values,
        time: dayjs().valueOf(),
        active: true,
      }));
    }
    handleCloseModal();
  };

  const handleEditSchool = (school) => {
    setEditingSchool(school);
    form.setFieldsValue({ schoolName: school.schoolName, description: school.description });
    setVisible(true);
  };

  const handleDeleteSchool = async (id) => {
    await dispatch(updateSchool({ id, active: false }));
  };

  const handleActivateSchool = async (id) => {
    await dispatch(updateSchool({ id, active: true }));
  };

  const columns = [
    {
      title: 'S/No',
      dataIndex: 'key',
      key: 'key',
      render: (_, __, index) => index + 1, // Serial number
    },
    {
      title: 'Name',
      dataIndex: 'schoolName',
      key: 'schoolName',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      render: (active) => (active ? 'Active' : 'Disabled'),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <>
          <Button type="link" onClick={() => handleEditSchool(record)}>Edit</Button>
          {record?.active ? (
            <Button type="link" danger onClick={() => handleDeleteSchool(record.id)}>Disable</Button>
          ) : (
            <Button type="link" onClick={() => handleActivateSchool(record.id)}>Enable</Button>
          )}
        </>
      ),
    },
  ];

  return (
    <SchoolsContainer>
      <Breadcrumb
        style={{ marginBottom: 10 }}
        items={[
          {
            title: <Link to='/'><DashboardOutlined /></Link>,
          },
          {
            title: "School Settings",
          },
          {
            title: 'Schools'
          },

        ]}
      />
      <StyledButton type="primary" onClick={handleOpenModal} loading={loading} disabled={loading}>
        Add School
      </StyledButton>

      <Table
        dataSource={dataSource}
        columns={columns}
        // pagination={false}
        pagination={{ pageSize: 10 }}
        bordered
      />

      <Modal
        title={editingSchool ? 'Edit School' : 'Add School'}
        visible={visible}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Form
          form={form}
          onFinish={handleCreateOrUpdateSchool}
          layout="vertical"
        >
          <Form.Item
            name="schoolName"
            label="School Name"
            rules={[{ required: true, message: 'Please enter the school name' }]}
          >
            <Input placeholder="Enter school name" />
          </Form.Item>

          {/* <Form.Item
            label="Category"
            name="category"
            rules={[{ required: true, message: 'Please select a category' }]}
          >
            <Select defaultValue={"public"} placeholder="Select Category">
              <Option value="public">Public</Option>
              <Option value="private">Private</Option>
              <Option value="international">International</Option>
            </Select>
          </Form.Item> */}

          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: 'Please enter a description' }]}
          >
            <Input.TextArea rows={4} placeholder="Enter description" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingSchool ? 'Update School' : 'Create School'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </SchoolsContainer>
  );
};

export default Schools;
