import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoutes = ({ allowedRoles }) => {
    const user = useSelector((state) => state.user.user); // Assuming user data is in Redux

    if (!user) {
        // User not logged in
        return <Navigate to="/login" />;
    } else {

        if (!allowedRoles.includes(user.role)) {
            // User doesn't have the required role
            return <Navigate to="/unauthorized" />;
        }

        return <Outlet />;
    }


};

export default ProtectedRoutes;
