import { 
     LOGIN_USER,
     LOGIN_USER_FAILED,
     LOGIN_USER_SUCCESSFUL
     } from "../actions/Types";

const initialState = {
    success:false,
    loading: false,
    error:null
};

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                loading: true,
                error: null
            };
        case LOGIN_USER_SUCCESSFUL:
            return {
                ...state,
                loading: false
            };
        case LOGIN_USER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

            case 'CHANGE_PASSWORD_REQUEST':
                return {
                  ...state,
                  loading: true,
                  error: null,
                  success:false
                };
              case 'CHANGE_PASSWORD_SUCCESS':
                return {
                  ...state,
                  loading: false,
                  success:true
                //   users: action.payload,
                };
              case 'CHANGE_PASSWORD_FAILURE':
                return {
                  ...state,
                  loading: false,
                  error: action.payload,
                };
       
        default:
            return state;
    }
};

export default AuthReducer;
