import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Checkbox, Button, Modal, Form, Select, Spin, Breadcrumb } from 'antd';
import styled from 'styled-components';
import { fetchClasses, fetchStudents } from '../../actions/SchoolActions';
import { fetchFeeGroups } from '../../actions/FeesActions';
// import { saveFeeAllocation } from '../actions/feeAllocationActions'; // Adjust to use a single action
// import { fetchStudents, fetchGroups, fetchClasses } from '../actions/dataActions';
import dayjs from 'dayjs';
import { deactivateFeeAllocation, fetchAllActiveAllocations, saveFeeAllocation } from '../../actions/AllocationActions';
import { Link } from 'react-router-dom';
import { DashboardOutlined } from '@ant-design/icons';

const { Option } = Select;

const StyledContainer = styled.div`
  padding: 20px;
`;

const StyledButton = styled(Button)`
  margin-bottom: 16px;
`;

const FeeAllocations = () => {
  const dispatch = useDispatch();
  const [selectedStudents, setSelectedStudents] = useState([]);

  const [originalSelectedStudents, setOriginalSelectedStudents] = useState([]); // initial selected students
  const [addedStudents, setAddedStudents] = useState([]);
  const [removedStudents, setRemovedStudents] = useState([]);

  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [feeGroup, setFeeGroup] = useState(null);
  // const [selectedGroup, setSelectedGroup] = useState(null);
  const [classFilter, setClassFilter] = useState(null);
  const [streamFilter, setStreamFilter] = useState(null);
  const [genderFilter, setGenderFilter] = useState(null);
  // const [studentsData, setStudentsData] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);

  // Updated Redux states
  const students = useSelector((state) => state.school.students);
  const feeGroups = useSelector((state) => state.fees.feeGroups);
  const activeYears = useSelector(state => state.studyYear.activeYears);

  const classes = useSelector((state) => state.school.classes);
  const activeAllocations = useSelector((state) => state.allocations.activeAllocations);
  // const feeAllocations = useSelector((state) => state.feeAllocations[groupID]);
  const feeAllocations = activeAllocations.find((alloc) => alloc.groupID === feeGroup);

  // useEffect(() => {
  //   // dispatch(fetchFeeGroups());
  //   // dispatch(fetchStudents());
  //   // dispatch(fetchClasses());

  //   setLoading(false);
  // }, [dispatch]);
  // useEffect(() => {
  //   if (groupID) {
  //     if (feeAllocations) {
  //       setSelectedStudents(feeAllocations.studentIDs || []);
  //     }
  //   }
  // }, [groupID, feeAllocations]);

  // useEffect(() => {
  //   if (feeGroup && feeAllocations) {
  //     setSelectedStudents(feeAllocations.studentIDs || []);
  //     // handleLoadStudents();
  //   }
  // }, [feeGroup, JSON.stringify(feeAllocations)]);

  useEffect(() => {
    if (feeGroup) {
      handleLoadStudents();
    }
  }, [feeGroup, activeAllocations]);


  // useEffect(() => {
  //   if (feeGroup) {
  //     setSelectedGroup(feeGroups.filter((group) => group?.id === feeGroup) || {});
  //   }
  // }, [feeGroup, feeGroups]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   // const unsubscribe =
  //   // dispatch(fetchAllActiveAllocations());

  //   // Clean up listener on component unmount
  //   // return () => unsubscribe && unsubscribe();
  // }, [dispatch]);


  const handleCheckboxChange = (studentID) => {
    setSelectedStudents((prevSelected) =>
      prevSelected.includes(studentID)
        ? prevSelected.filter((id) => id !== studentID)
        : [...prevSelected, studentID]
    );
  };


  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedStudents(filteredStudents.map(student => student.id));
    } else {
      setSelectedStudents([]);
    }
  };

  const handleSubmit = () => {
    if (addedStudents?.length === 0 && removedStudents?.length === 0) {
      Modal.error({ 
        title: <span style={{ color: 'var(--primary-text)' }}>Processing Error</span>,
        content: <p style={{ color: 'var(--primary-text)' }}>Please make at least one change</p>,
         });
      return;
    }

    const payload = {
      studentIDs: selectedStudents,
      groupID: feeGroup,
      addedStudents,
      removedStudents
    };
    console.log(payload)
    dispatch(saveFeeAllocation(payload));
    setIsModalVisible(false);

    // handleLoadStudents();

    // setAddedStudents([]);
    // setRemovedStudents([]);
  };

  const handleDelete = () => {
    Modal.confirm({
      title: <span style={{ color: 'var(--primary-text)' }}>Confirm Deactivate</span>,
      content: <p style={{ color: 'var(--primary-text)' }}>Are you sure you want to deactivate this allocation?</p>,
      onOk: () => {
        // console.log(feeGroup)
        dispatch(deactivateFeeAllocation(feeGroup));
      },
    });
  };

  const handleLoadStudents = () => {
    // console.log('students')
    // const filteredStudents = students.filter((student) => {
    //   // const matchesFeeGroup = feeGroup ? student.feeGroup === feeGroup : true;
    //   const matchesClass = classFilter ? student.class === classes.filter(clas => clas.id === classFilter) : true;
    //   const matchesStream = streamFilter ? student.stream === streamFilter : true;
    //   const matchesGender = genderFilter ? student.gender === genderFilter : true;
    //   return true && matchesClass && matchesStream && matchesGender;
    // });

    const filteredStudents = students.filter((student) => {
      const selectedClass = classes.find(clas => clas.id === classFilter); // Get the class matching the filter

      // Check if the student's class, stream, and gender match the selected filters
      // const matchesClass = classFilter ? student.class === selectedClass?.className : true;
      const matchesClass = classFilter
        ? student.classes.some(classItem => classItem.class === selectedClass?.id)
        : true;

      // const matchesStream = streamFilter ? student.stream === streamFilter : true;
      const matchesStream = streamFilter
        ? student.classes.some(classItem => classItem.stream === streamFilter)
        : true;

      const matchesGender = genderFilter ? student.gender === genderFilter : true;

      const matchingGroup = feeGroups.find(group => group?.id === feeGroup) || {}; // `find` returns the first match or undefined
      const matchesFeeGroup = feeGroup
        ? student.classes?.some(classItem => classItem.year === matchingGroup.yearID)
        : true;

      return matchesClass && matchesStream && matchesGender && matchesFeeGroup;
    });


    setFilteredStudents(filteredStudents);
    const allocatedStudentIDs = feeAllocations ? feeAllocations.studentIDs : [];
    setSelectedStudents(
      filteredStudents.map(student => student.id).filter(id => allocatedStudentIDs?.includes(id))
    );
    setOriginalSelectedStudents(
      filteredStudents.map(student => student.id).filter(id => allocatedStudentIDs?.includes(id))
    );

    //Clear all inserts and deletes
    //Put error message if possible
    setAddedStudents([]);
    setRemovedStudents([]);

  };


  const columns = [
    // {
    //   title: 'Select',
    //   dataIndex: 'select',
    //   render: (_, record) => (
    //     <Checkbox
    //       checked={selectedStudents.includes(record.id)}
    //       onChange={() => handleCheckboxChange(record.id)}
    //     />
    //   ),
    // },
    {
      title: 'S/No',
      dataIndex: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name) => name || 'N/A',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      render: (gender) => gender || 'N/A',
    },
    // {
    //   title: 'Registered',
    //   dataIndex: 'dateRegistered',
    //   render: (dateRegistered) => dayjs(dateRegistered).format('YYYY-MM-DD') || 'N/A',
    // },
    {
      title: 'Date Joined', dataIndex: 'dateOfAdmission', key: 'dateOfAdmission',
      render: (dateOfAdmission) => dayjs(dateOfAdmission).format('YYYY-MM-DD') || 'N/A',
    },
    // {
    //   title: 'Class',
    //   dataIndex: 'class',
    //   render: (className) => className || 'N/A',
    // },
    // {
    //   title: 'Stream',
    //   dataIndex: 'stream',
    //   render: (stream) => stream || 'N/A',
    // },
    {
      title: 'Class',
      dataIndex: 'classes',
      render: (renderClass) => {
        const matchingGroup = feeGroups?.filter((group) => group?.id === feeGroup) || {};
        const matchingClassInStudent = renderClass?.find(classItem => classItem.year === matchingGroup[0]?.yearID);
        const matchingClass = classes.find(clas => clas.id === matchingClassInStudent?.class); // Get the class matching the filter
        return matchingClass?.className || 'N/A'; // assuming `className` holds the class name
      },
    },
    {
      title: 'Stream',
      dataIndex: 'classes',
      render: (renderClass) => {
        const matchingGroup = feeGroups?.filter((group) => group?.id === feeGroup) || {};
        const matchingClassInStudent = renderClass?.find(classItem => classItem.year === matchingGroup[0]?.yearID);
        return matchingClassInStudent?.stream || 'N/A'; // assuming `stream` holds the stream name
      },
    },
  ];

  const dataSource = filteredStudents.map((student) => ({
    key: student.id,
    id: student.id,
    name: student.name,
    gender: student.gender,
    dateRegistered: student.dateRegistered,
    dateOfAdmission: student.dateOfAdmission,
    classes: student.classes,
    // stream: student.stream,
  }));

  // const isSaveDisabled = JSON.stringify(selectedStudents) === JSON.stringify(feeAllocations?.studentIDs || []);
  const isSaveDisabled = addedStudents?.length === 0 && removedStudents?.length === 0;

  // Dynamic stream options based on selected class
  const getStreamOptions = () => {
    const selectedClass = classes.find((cls) => cls.id === classFilter);
    return selectedClass ? selectedClass.streams : [];
  };

   // Filter Fee Groups by Active Years
   const filteredFeeGroups = feeGroups.filter((group) =>
    activeYears.some((year) => year.id === group.yearID)
  );

  return (
    <StyledContainer>
       <Breadcrumb
                style={{ marginBottom: 10 }}
                items={[
                    {
                        title: <Link to='/'><DashboardOutlined /></Link>,
                    },
                    {
                        title: "Fee Settings",
                    },
                    {
                        title: 'Fee Allocations'
                    },

                ]}
            />
      {/* <h2>Fee Allocations</h2> */}
      <Form layout="inline" style={{ marginBottom: '20px' }}>
        <Form.Item label="Fee Group" required>
          <Select
            value={feeGroup}
            onChange={(value) => {
              setFeeGroup(value);
              setClassFilter(null);
            }}
            placeholder="Select Fee Group"
            style={{ width: 200 }}
          >
            {filteredFeeGroups.map((group) => (
              <Option key={group.id} value={group.id}>
                {group.groupName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Class">
          <Select
            allowClear
            value={classFilter}
            onChange={(value) => {
              setClassFilter(value);
              setStreamFilter(null); // Reset stream when class changes
            }}
            placeholder="Select Class"
            style={{ width: 100 }}
          >
            {classes?.filter((clas) => clas.yearID === feeGroups?.find(group => group?.id === feeGroup)?.yearID)?.map((classItem) => (
              <Option key={classItem.id} value={classItem.id}>
                {classItem.className}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Stream">
          <Select
            allowClear
            value={streamFilter}
            onChange={setStreamFilter}
            placeholder="Select Stream"
            style={{ width: 100 }}
            disabled={!classFilter} // Disable if no class is selected
          >
            {getStreamOptions().map((stream) => (
              <Option key={stream} value={stream}>
                {stream}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Gender">
          <Select
            allowClear
            value={genderFilter}
            onChange={setGenderFilter}
            placeholder="Select Gender"
            style={{ width: 100 }}
          >
            <Option value="Male">Male</Option>
            <Option value="Female">Female</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <StyledButton
            type="primary"
            onClick={handleLoadStudents}
            style={{margin:0}}
            disabled={!feeGroup
              //  || (!classFilter && !streamFilter && !genderFilter)
            }
          >
            Load
          </StyledButton>
        </Form.Item>
      </Form>


        {/* {feeAllocations?.active ? */}

        <StyledButton type="primary" onClick={() => setIsModalVisible(true)} disabled={isSaveDisabled}>
              Allocate
            </StyledButton> 
          {/* //   :

          //   <StyledButton type="primary" disabled>
          //     Deactivated
          //   </StyledButton>

          // } */}
          {
            feeAllocations?.active &&
            <StyledButton type="danger" onClick={handleDelete}>
              Deactivate Group Allocation
            </StyledButton>
          }



      {loading ? (
        <Spin />
      ) : (
        <>
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={{ pageSize: 10 }}
            bordered
            rowSelection={{
              selectedRowKeys: selectedStudents,
              onChange: (selectedRowKeys) => {
                const newAddedStudents = [];
                const newRemovedStudents = [];

                selectedRowKeys.forEach((key) => {
                  // If key is newly selected and wasn't part of original, add to `addedStudents`
                  if (!originalSelectedStudents.includes(key) && !addedStudents.includes(key)) {
                    newAddedStudents.push(key);
                  }
                });

                selectedStudents.forEach((key) => {
                  // If key is unselected and was part of original, add to `removedStudents`
                  if (!selectedRowKeys.includes(key) && originalSelectedStudents.includes(key) && !removedStudents.includes(key)) {
                    newRemovedStudents.push(key);
                  }
                });

                // Update addedStudents and removedStudents while ensuring no alternation
                setAddedStudents((prev) => {
                  // Filter out any students deselected in this operation
                  const stillAdded = prev.filter((key) => selectedRowKeys.includes(key));
                  return [...stillAdded, ...newAddedStudents];
                });

                setRemovedStudents((prev) => {
                  // Filter out any students reselected in this operation
                  const stillRemoved = prev.filter((key) => !selectedRowKeys.includes(key));
                  return [...stillRemoved, ...newRemovedStudents];
                });

                // Update the current selection
                setSelectedStudents(selectedRowKeys);

              },
            }}
          />
        
        </>
      )}
      <Modal
        title="Allocate Students to Fee Group"
        visible={isModalVisible}
        onOk={handleSubmit}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form>
          {/* Additional modal form fields */}
        </Form>
      </Modal>
    </StyledContainer>
  );
};

export default FeeAllocations;
