import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Typography, Input, Button, Spin, Alert, Form, Breadcrumb } from 'antd';
import styled from 'styled-components';
import { fetchUser, updateUser } from '../../actions/UserActions';
import { auth } from '../../config/firebase';
import { UserOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Title } = Typography;

const StyledContent = styled(Content)`
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: var(--primary-background);
  border: 1px solid #bbb;
      border-radius: 8px;
`;

const FormItem = styled(Form.Item)`
  label {
    color: var(--primary-text);
  }
`;

const User = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const loading = useSelector(state => state.user.userLoading);
  const error = useSelector(state => state.user.userError);

  const userID= auth.currentUser.uid;
  
  const [editableFields, setEditableFields] = useState({
    names: '',
    mobile: '',
    address: '',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Update local state when user data is available
  useEffect(() => {
    if (user) {
      setEditableFields({
        names: user.names || '',
        mobile: user.mobile || '',
        address: user.address || '',
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditableFields(prevFields => ({ ...prevFields, [name]: value }));
  };

  const handleSave = () => {
    dispatch(updateUser(userID,editableFields));
  };

  return (
    <div style={{ padding: 20 }}>
        <Breadcrumb
                style={{ marginBottom: 10 }}
                items={[
                    {
                        title:  <UserOutlined />,
                    },
                    {
                        title: 'My Profile'
                    },
                 
                ]}
            />
    <StyledContent>
      {/* <Title level={2}>User Profile</Title> */}
    
      
      {loading ? (
        <Spin size="large" />
      ) : error ? (
        <Alert message="Error" description={error} type="error" showIcon />
      ) : (
        <Form layout="vertical">
          {/* Name Field */}
          <FormItem label="Name">
            <Input
              name="names"
              value={editableFields.names}
              onChange={handleChange}
              placeholder="Enter your names"
            />
          </FormItem>

          {/* Mobile Number Field */}
          <FormItem label="Mobile Number">
            <Input
              name="mobile"
              value={editableFields.mobile}
              onChange={handleChange}
              placeholder="Enter your mobile number"
            />
          </FormItem>

          {/* Address Field */}
          <FormItem label="Address">
            <Input
              name="address"
              value={editableFields.address}
              onChange={handleChange}
              placeholder="Enter your address"
            />
          </FormItem>

          {/* Non-editable Email Field */}
          <FormItem label="Email">
            <Input value={user?.email || ''} disabled />
          </FormItem>

          {/* Non-editable Role Field */}
          <FormItem label="Role">
            <Input value={user?.role || ''} disabled />
          </FormItem>

          <Button type="primary" onClick={handleSave} 
          disabled={
            !editableFields.names 
        //   || !editableFields.mobile
          }>
            Save Changes
          </Button>
        </Form>
      )}
    </StyledContent>
    </div>
  );
};

export default User;
