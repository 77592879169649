import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Typography, Spin, Alert, Layout, Breadcrumb } from 'antd';
import styled from 'styled-components';
import { changePassword } from '../../actions/AuthActions';
import { UserOutlined } from '@ant-design/icons';

const { Content } = Layout;

const { Title } = Typography;

const StyledContent = styled(Content)`
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: var(--primary-background);
  border: 1px solid #bbb;
      border-radius: 8px;
`;

const Container = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const mapFirebaseAuthError = (errorCode) => {
    switch (errorCode) {
        case 'auth/invalid-email':
            return 'The email address is not valid.';
        case 'auth/user-disabled':
            return 'The user account has been disabled.';
        case 'auth/user-not-found':
            return 'No user found with this email address.';
        case 'auth/wrong-password':
            return 'Incorrect password. Please try again.';
        case 'auth/email-already-in-use':
            return 'The email address is already in use by another account.';
        case 'auth/operation-not-allowed':
            return 'Password sign-in is disabled for this project.';
        case 'auth/weak-password':
            return 'The password is too weak. Please choose a stronger password.';
        case 'auth/too-many-requests':
            return 'We have blocked all requests from this device due to unusual activity. Please try again later.';
        case 'auth/requires-recent-login':
            return 'Please log in again to perform this action.';
        case 'auth/network-request-failed':
            return 'A network error occurred. Please check your connection.';
        case 'auth/popup-closed-by-user':
            return 'The popup was closed before completing the sign in. Please try again.';
        case 'auth/account-exists-with-different-credential':
            return 'An account already exists with the same email address but different sign-in credentials.';
        case 'auth/invalid-credential':
            return 'Incorrect password. Please try again.';
        case 'auth/invalid-verification-code':
            return 'The verification code is not valid.';
        case 'auth/invalid-verification-id':
            return 'The verification ID is not valid.';
        case 'auth/missing-verification-code':
            return 'The verification code is missing.';
        case 'auth/missing-verification-id':
            return 'The verification ID is missing.';
        case 'auth/credential-already-in-use':
            return 'This credential is already associated with a different user account.';
        case 'auth/invalid-phone-number':
            return 'The phone number provided is not valid.';
        case 'auth/missing-phone-number':
            return 'Please provide a valid phone number.';
        case 'auth/invalid-password':
            return 'The password is invalid. Please ensure your password is correct.';
        case 'auth/quota-exceeded':
            return 'The quota for sending SMS messages has been exceeded. Please try again later.';
        case 'auth/missing-email':
            return 'An email address must be provided.';
        case 'auth/unverified-email':
            return 'Your email address is not verified. Please verify your email first.';
        case 'auth/app-not-authorized':
            return 'This app is not authorized to use Firebase Authentication.';
        case 'auth/expired-action-code':
            return 'The action code has expired. Please try again.';
        case 'auth/invalid-action-code':
            return 'The action code is invalid. Please try again.';
        case 'auth/user-token-expired':
            return 'Your session has expired. Please log in again.';
        case 'auth/user-mismatch':
            return 'The supplied credentials do not match the previously signed-in user.';
        case 'auth/invalid-api-key':
            return 'The API key provided is invalid.';
        case 'auth/app-deleted':
            return 'The Firebase app has been deleted. Please contact support.';
        case 'auth/cors-unsupported':
            return 'CORS is not supported by your browser.';
        default:
            return 'An unexpected error occurred. Please try again.';
    }
};

const ChangePassword = () => {
    const [form] = Form.useForm();
    const [confirmPassword, setConfirmPassword] = useState('');
    const dispatch = useDispatch();

    const { loading, error, success } = useSelector(state => state.auth);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleFinish = (values) => {
        if (values.newPassword !== confirmPassword) {
            form.setFields([{ name: 'confirmPassword', errors: ['Passwords do not match'] }]);
            return;
        }
        dispatch(changePassword(values.password, values.newPassword));
    };



    return (
        <div style={{ padding: 20 }}>
            <Breadcrumb
                style={{ marginBottom: 10 }}
                items={[
                    {
                        title: <UserOutlined />,
                    },
                    {
                        title: 'Change Password'
                    },

                ]}
            />
            <StyledContent>

                {/* <Title level={3}>Change Password</Title> */}
                {
                    error &&
                    <Alert style={{ background: 'var(--primary-background)' }}
                        message={mapFirebaseAuthError(error?.code)} type="error"
                        showIcon />
                }
                {
                    success && (
                        <Alert
                            message="Password changed successfully!"
                            // description="Password changed successfully!"
                            type="success"
                            showIcon
                            style={{ background: 'var(--primary-background)' }}
                        />
                    )}
                <Form form={form} layout="vertical" onFinish={handleFinish}>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[{ required: true, message: "Please enter your password" }]}
                    >
                        <Input.Password placeholder="Enter your password" />
                    </Form.Item>
                    <Form.Item
                        name="newPassword"
                        label="New Password"
                        rules={[
                            { required: true, message: 'Please enter the new password' },
                            { min: 6, message: 'Password must be at least 6 characters' },
                        ]}
                    >
                        <Input.Password placeholder="Enter new password" />
                    </Form.Item>

                    <Form.Item
                        name="confirmPassword"
                        label="Confirm Password"
                        rules={[
                            { required: true, message: 'Please confirm the new password' },
                        ]}
                    >
                        <Input.Password
                            placeholder="Confirm new password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" disabled={loading} >
                            {loading ? <Spin size="small" /> : 'Change Password'}
                        </Button>
                    </Form.Item>
                </Form>

            </StyledContent>
        </div>
    );
};

export default ChangePassword;
