import React from "react";
import { Row, Col, Card, Table } from "antd";
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import {
  SmileOutlined,
  UserOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import "./Dashboard.css";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "../../utils";

import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"; // Import the plugin

// Extend dayjs with plugins
dayjs.extend(isSameOrAfter);


const Dashboard = () => {

  const students = useSelector(state => state.school.students);
  const activeYears = useSelector(state => state.studyYear.activeYears);
  const users = useSelector(state => state.user.users);
  const activeFeeTypes = useSelector(state => state.fees.activeFeeTypes);
  const classes = useSelector(state => state.school.classes); // Assuming you have a reducer for classes
  const activePaymentSystems = useSelector(state => state.payments.activePaymentSystems);
  const activeFeeGroups = useSelector(state => state.fees.feeGroups);
  const activeAllocations = useSelector((state) => state.allocations.activeAllocations);


  const schools = useSelector(state => state.school.schoolList); // Assume your Redux state has schools


  // console.log(activeYears)


  const today = dayjs();

  // Filter Fee Groups by Active Years
  const filteredFeeGroups = activeFeeGroups.filter((group) =>
    activeYears.some((year) => year.id === group.yearID)
  );


  const filteredStudents = students
    ?.filter(student => student?.classes?.some(classItem => activeYears?.map(year => year.id)?.includes(classItem.year)))
    ?.sort((a, b) => a.name.localeCompare(b.name));

  const activeClasses = classes
    ?.filter(classItem => activeYears?.map(year => year.id)?.includes(classItem.yearID));


  // Generate data for the pie chart
  const pieData = activeYears?.map((year) => {
    const studentCount = filteredStudents?.filter((student) =>
      student?.classes?.some((classItem) => classItem.year === year.id)
    ).length;

    const school = schools?.find((sch)=>sch.id === year?.school);

    return {
      name: `${year?.name} (${capitalizeFirstLetter(school?.schoolName)})`,
      value: studentCount,
      color: `#${Math.floor(Math.random() * 16777215).toString(16)}`, // Random color
    };
  });

  // Generate barData based on activeClasses and filteredStudents
  const barData = activeClasses.map((activeClass) => {
    const maleCount = filteredStudents?.filter(
      (student) =>
        student.gender === "Male" &&
        student.classes.some((classItem) => classItem.class === activeClass.id)
    ).length;

    const femaleCount = filteredStudents?.filter(
      (student) =>
        student.gender === "Female" &&
        student.classes.some((classItem) => classItem.class === activeClass.id)
    ).length;

    return {
      name: activeClass.className, // XAxis data
      Male: maleCount || 0, // Male students count
      Female: femaleCount || 0, // Female students count
      Total: (maleCount + femaleCount) || 0, // Female students count
    };
  })?.sort((a, b) => a.name.localeCompare(b.name));

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const dataPoint = payload[0].payload;
      return (
        <div
          style={{
            background: "var(--secondary-background)",
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
          }}
        >
          <p style={{ margin: 0, fontWeight: "bold" }}>{label}</p>
          <p>Male: {dataPoint.Male}</p>
          <p>Female: {dataPoint.Female}</p>
          <p style={{ fontWeight: "bold", color: "#4A90E2" }}>Total: {dataPoint.Total}</p>
        </div>
      );
    }
  }

  // Data for the Table
  const tableData = activeClasses.map((item, index) => ({
    key: index,
    className: item.className,
    streams: item.streams?.join(", ") || "No streams available",
  }))?.sort((a, b) => a.className.localeCompare(b.className));

  // Table Columns
  const tableColumns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Classes",
      dataIndex: "className",
      key: "className",
      // align: "center",
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "Streams",
      dataIndex: "streams",
      key: "streams",
      // align: "center",
      render: (text) => <span style={{ color: "#4A90E2" }}>{text}</span>,
    },
  ];


  // Data for the Table
  const paymentSystemTableData = activePaymentSystems.map((system, index) => ({
    key: index,
    no: index + 1,
    systemName: system.systemName,
  }));

  // Table Columns
  const paymentSystemTableColumns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      // align: "center",
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "System Name",
      dataIndex: "systemName",
      key: "systemName",
      // align: "center",
      render: (text) => <span style={{ color: "#4A90E2" }}>{text}</span>,
    },
  ];



  // Extract Fee Details
  const feeDetails = filteredFeeGroups.flatMap((group) =>
    group.feeDetails.map((detail) => ({
      ...detail,
      groupName: group.groupName,
      feeName: activeFeeTypes.find((type) => type.id === detail.feeTypeID)?.feeName || "Unknown",
    }))
  );

  // Sort by Due Date
  const sortedFeeDetails = feeDetails.sort((a, b) => b.dueDate - a.dueDate);

  // Separate Upcoming and Overdue Fees

  const overdueFees = sortedFeeDetails.filter((detail) => dayjs(detail.dueDate).isBefore(today)).sort((a, b) => b.dueDate - a.dueDate);
  const upcomingFees = sortedFeeDetails.filter((detail) => dayjs(detail.dueDate).isSameOrAfter(today)).sort((a, b) => a.dueDate - b.dueDate);

  // Columns for AntD Table
  const columns = [
    { title: "No", dataIndex: "no", key: "no", render: (_, __, index) => index + 1 },
    { title: "Fee Name", dataIndex: "feeName", key: "feeName" },
    { title: "Group Name", dataIndex: "groupName", key: "groupName" },
    { title: "Due Date", dataIndex: "dueDate", key: "dueDate", render: (dueDate) => dayjs(dueDate).format("YYYY-MM-DD") },
  ];

  // Data for Tables
  const formatData = (data) =>
    data.map((item, index) => ({
      ...item,
      key: index,
    }));


  // Process data to generate table rows
  const allocationsTableData = activeAllocations
    ?.filter(allocation => {
      const feeGroup = filteredFeeGroups.find(group => group.id === allocation.groupID);
      // Exclude allocations if feeGroup does not satisfy the condition
      return feeGroup && activeYears.some(year => year.id === feeGroup.yearID);
    })
    ?.map((allocation, index) => {
      const feeGroup = filteredFeeGroups.find(group => group.id === allocation.groupID);

      // Calculate total amount
      const totalAmount = feeGroup?.feeDetails
        ? feeGroup.feeDetails.reduce((sum, fee) => sum + parseFloat(fee.amount || 0), 0)
        : 0;

      return {
        key: allocation.id, // Unique key for AntD Table
        no: index + 1,
        groupName: feeGroup?.groupName || "Unknown Group",
        description: feeGroup?.description || "No description available",
        studentsAllocated: allocation.studentIDs.length,
        amount: totalAmount.toLocaleString(), // Format amount as a readable string
      };
    });

  // Define table columns
  const allocationsTableColumns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Group Name",
      dataIndex: "groupName",
      key: "groupName",
      render: (_, record) => {
        return (
          <>
            {/* <Tooltip title={record.description}> */}
            {record.groupName}
            {/* </Tooltip> */}
          </>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => (amount ? `Tsh ${amount}` : "N/A"),
    },
    {
      title: "Students Allocated",
      dataIndex: "studentsAllocated",
      key: "studentsAllocated",
    },

  ];


  return (
    <div className="dashboard-container">
      {/* <h1 className="dashboard-title">Dashboard Overview</h1> */}

      {/* Overview Cards */}
      <Row gutter={[16, 16]} className="dashboard-row">
        <Col span={6}>
          <Card
            className="hover-card"
            style={{ backgroundColor: "#4A90E2", color: "black" }}
            bordered={false}
          >
            <div className="card-content">
              <UserOutlined style={{ fontSize: "2.5rem", color: "black" }} />
              <div>
                <h3>Students</h3>
                <h2>{filteredStudents?.length}</h2>
              </div>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className="hover-card"
            style={{ backgroundColor: "#3EA321", color: "black" }}
            bordered={false}
          >
            <div className="card-content">
              <UserOutlined style={{ fontSize: "2.5rem", color: "black" }} />
              <div>
                <h3>Active Years/Schools</h3>
                <h2>{activeYears?.length}</h2>
              </div>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className="hover-card"
            style={{ backgroundColor: "#E8C70C", color: "black" }}
            bordered={false}
          >
            <div className="card-content">
              <AppstoreOutlined style={{ fontSize: "2.5rem", color: "black" }} />
              <div>
                <h3>Fee Types</h3>
                <h2>{activeFeeTypes?.length}</h2>
              </div>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className="hover-card"
            style={{ backgroundColor: "#E91E63", color: "black" }}
            bordered={false}
          >
            <div className="card-content">
              <SettingOutlined style={{ fontSize: "2.5rem", color: "black" }} />
              <div>
                <h3>Users</h3>
                <h2>{users?.length}</h2>
              </div>
            </div>
          </Card>
        </Col>
      </Row>

      {/* Charts Section */}
      <Row gutter={[16, 16]} className="dashboard-row">
        <Col span={8}>
          <Card className="animated-card" bordered={false}>
            <h3>Students Distribution by Study Years</h3>
            <PieChart width={300} height={300}>
              <Pie
                data={pieData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={120}
                fill="#8884d8"
                label={(entry) => `${entry.name}: ${entry.value}`}
              >
                {pieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </Card>
        </Col>
        <Col span={16}>
          <Card className="animated-card" bordered={false}>
            <h3
            // style={{ color: "#4A90E2" }}
            >Students Distribution by Class and Gender</h3>
            <BarChart
              width={600}
              height={300}
              data={barData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" label={{ value: "Class", position: "insideBottom", dy: 10 }} />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar dataKey="Male" fill="#8e5f96" />
              <Bar dataKey="Female" fill="#4A90E2" />
            </BarChart>
          </Card>
        </Col>
      </Row>

      {/* Recent Activities Table */}
      <Row gutter={[16, 16]} className="dashboard-row">
        {/* First Table */}
        <Col xs={24} md={8}>
          <Card
            className="animated-card"
            bordered={false}
          // title="Active Classes and Streams"
          // style={{
          //   margin: "20px auto",
          //   borderRadius: "8px",
          //   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          //   maxWidth: "80%",
          // }}
          // headStyle={{
          //   textAlign: "center",
          //   fontSize: "18px",
          //   color: "#4A90E2",
          // }}
          >
            <h3>Active Classes and Streams</h3>
            <Table
              dataSource={tableData}
              columns={tableColumns}
              pagination={{ pageSize: 2 }}
            bordered
            // style={{ borderRadius: "8px", overflow: "hidden" }}
            />
          </Card>
        </Col>

        {/* Second Table */}
        <Col xs={24} md={8}>
          <Card className="animated-card" bordered={false}>
            <h3>Student Allocations</h3>
            <Table
              dataSource={allocationsTableData}
              columns={allocationsTableColumns}
              pagination={{ pageSize: 2 }}
              bordered
            />
          </Card>
        </Col>


        {/* Third Table */}
        <Col xs={24} md={8}>
          <Card className="animated-card" bordered={false}>
            <h3>Active Payment Systems</h3>
            <Table
              dataSource={paymentSystemTableData}
              columns={paymentSystemTableColumns}
              pagination={{ pageSize: 2 }}
            bordered
            // style={{ borderRadius: "8px", overflow: "hidden" }}
            />
          </Card>
        </Col>


      </Row>
      <Row gutter={[16, 16]} className="dashboard-row">

        <Col xs={24} md={12}>
          <Card className="animated-card" bordered={false}>
            <h3>Recent Overdue Fees</h3>
            <Table
              dataSource={formatData(overdueFees)}
              columns={columns}
              pagination={{ pageSize: 3 }}
            bordered
            />
          </Card>
        </Col>

        <Col xs={24} md={12}>
          <Card className="animated-card" bordered={false}>
            <h3>Fees with Upcoming Due Dates</h3>
            <Table
              dataSource={formatData(upcomingFees)}
              columns={columns}
              pagination={{ pageSize: 3 }}
            bordered
            />
          </Card>
        </Col>

      </Row>

    </div>
  );
};

export default Dashboard;
