import React, { useEffect, useState } from 'react';
import { Table, Button, Form, Input, Select, Modal, Typography, Tag, Breadcrumb } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { fetchActiveStudyYears, fetchAllStudyYears } from '../../actions/StudyYearActions';
import { createClassWithStreams, editClass, fetchClasses } from '../../actions/SchoolActions';

import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { DashboardOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { Option } = Select;

const ClassesAndStreamsContainer = styled.div`
  padding: 20px;
`;

const StyledButton = styled(Button)`
  margin-bottom: 16px;
`;

const ClassesAndStreams = () => {
    const dispatch = useDispatch();
    const activeYears = useSelector(state => state.studyYear.activeYears);
    const otherYears = useSelector(state => state.studyYear.studyYears).filter((year) => year?.active === false);
    const activeClasses = useSelector(state => state.school.classes); // Assuming you have a reducer for classes
    const { loading, error } = useSelector(state => state.school);
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentClass, setCurrentClass] = useState(null);

    const [dataSource, setDataSource] = useState([]);
    const [otherDataSource, setOtherDataSource] = useState([]);

    // useEffect(() => {
    //     // dispatch(fetchActiveStudyYears());
    //     // dispatch(fetchAllStudyYears());
    // }, [dispatch]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // useEffect(() => {
    //     const unsubscribe = dispatch(fetchClasses());

    //     // Clean up listener on component unmount
    //     return () => unsubscribe && unsubscribe();
    // }, [dispatch]);

    useEffect(() => {
        const source = activeYears?.map(year => ({
            key: year.id,
            year: year.name,
            activeClasses: activeClasses.filter(cls => cls.yearID === year.id),
        }));

        setDataSource(source);
    }, [activeYears, activeClasses]);

    useEffect(() => {
        const source = otherYears?.map(year => ({
            key: year.id,
            year: year.name,
            activeClasses: activeClasses.filter(cls => cls.yearID === year.id),
        }));

        setOtherDataSource(source);
    }, [otherYears, activeClasses]);

    const handleCreateClass = (values) => {
        const { year, className, streams } = values;
        const newClass = {
            yearID: year,
            year: activeYears.find(y => y.id === year).name, className,
            streams: streams.split(',').map(stream => stream.trim()),
            time: dayjs().valueOf()
        };
        dispatch(createClassWithStreams(newClass)); // Dispatch the action to create class with streams
        form.resetFields();
        setVisible(false);
    };

    const handleEditClass = (values) => {
        const { className, streams } = values;

        const updatedClass = { ...currentClass, className, streams: streams.split(',').map(stream => stream.trim()) };
        console.log(updatedClass)
        dispatch(editClass(updatedClass)); // Dispatch the action to edit class
        form.resetFields();
        setVisible(false);
        setIsEditing(false);
        setCurrentClass(null);
    };

    const handleOpenModal = (classData = null) => {
        if (classData) {
            setIsEditing(true);
            setCurrentClass(classData);
            form.setFieldsValue({
                year: classData.year,
                className: classData.className,
                streams: classData.streams.join(', '),
            });
        } else {
            setIsEditing(false);
            form.resetFields();
        }
        setVisible(true);
    };

    const handleCloseModal = () => {
        setVisible(false);
        setIsEditing(false);
        setCurrentClass(null);
    };

    const columns = [
        {
            title: 'Year',
            dataIndex: 'year',
            key: 'year',
        },
        {
            title: 'Active Classes and Streams',
            dataIndex: 'activeClasses',
            key: 'activeClasses',
            render: (text, record) => (
                <div>
                    {record.activeClasses.map(cls => (
                        <div key={cls.className}>
                            {cls.className} - {cls.streams.map(stream => (
                                <Tag key={stream} color="blue">{stream}</Tag>
                            ))}
                            <Button type="link" onClick={() => handleOpenModal(cls)}>Edit</Button>
                        </div>
                    ))}
                </div>
            ),
        },
    ];
    const otherColumns = [
        {
            title: 'Year',
            dataIndex: 'year',
            key: 'year',
        },
        {
            title: 'Classes and Streams',
            dataIndex: 'activeClasses',
            key: 'activeClasses',
            render: (text, record) => (
                <div>
                    {record.activeClasses?.map(cls => (
                        <div key={cls.className}>
                            {cls.className} - {cls.streams?.map(stream => (
                                <Tag key={stream} color="blue">{stream}</Tag>
                            ))}
                            {/* <Button type="link" onClick={() => handleOpenModal(cls)}>Edit</Button> */}
                        </div>
                    ))}
                </div>
            ),
        },
    ];



    return (
        <ClassesAndStreamsContainer>
            <Breadcrumb
                style={{ marginBottom: 10 }}
                items={[
                    {
                        title: <Link to='/'><DashboardOutlined /></Link>,
                    },
                    {
                        title: "School Settings",
                    },
                    {
                        title: 'Classes and Streams'
                    },

                ]}
            />
            {/* <Title level={2}>Classes and Streams</Title> */}
            <StyledButton type="primary" onClick={() => handleOpenModal()} loading={loading} disabled={loading}>
                Create Class
            </StyledButton>

            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                bordered
            />

            <Typography.Title level={3}>Past Years</Typography.Title>

            <Table
                dataSource={otherDataSource}
                columns={otherColumns}
                pagination={false}
                bordered
            />

            <Modal
                title={isEditing ? "Edit Class" : "Create Class"}
                visible={visible}
                onCancel={handleCloseModal}
                footer={null}
            >
                <Form
                    form={form}
                    onFinish={isEditing ? handleEditClass : handleCreateClass}
                    layout="vertical"
                >
                    <Form.Item
                        name="year"
                        label="Year"
                        rules={[{ required: true, message: 'Please select a year' }]}
                    >
                        <Select placeholder="Select a year">
                            {activeYears.map(year => (
                                <Option key={year.id} value={year.id}>
                                    {year.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="className"
                        label="Class Name"
                        rules={[{ required: true, message: 'Please input class name' }]}
                    >
                        <Input placeholder="Enter class name" />
                    </Form.Item>
                    <Form.Item
                        name="streams"
                        label="Streams"
                        rules={[{ required: true, message: 'Please input streams' }]}
                    >
                        <Input placeholder="Enter streams (comma-separated)" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {isEditing ? 'Update Class' : 'Create Class'}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </ClassesAndStreamsContainer>
    );
};

export default ClassesAndStreams;
