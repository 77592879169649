// components/FeeGroups.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Select, Checkbox, Table, message, DatePicker, Breadcrumb, Modal } from 'antd';
import styled from 'styled-components';
import { fetchActiveFeeTypes, createFeeGroup, fetchFeeGroups } from '../../actions/FeesActions';
import { fetchActiveStudyYears } from '../../actions/StudyYearActions';

import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { DashboardOutlined } from '@ant-design/icons';

const { Option } = Select;

const Container = styled.div`
  padding: 20px;
  // background: #f7f9fc;
  // border-radius: 8px;
`;

const StyledForm = styled(Form)`
  // margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  margin-bottom: 16px;
`;

const FeeGroups = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedFeeTypes, setSelectedFeeTypes] = useState([]);

  const activeYears = useSelector(state => state.studyYear.activeYears);
  const activeFeeTypes = useSelector(state => state.fees.activeFeeTypes);
  const feeGroups = useSelector(state => state.fees.feeGroups);
  const loading = useSelector(state => state.fees.loading);

  const [visible, setVisible] = useState(false);

  // useEffect(() => {
  //   // dispatch(fetchActiveStudyYears());
  //   // dispatch(fetchFeeGroups());
  //   // dispatch(fetchActiveFeeTypes());

  // }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOpenModal = () => {
    // if (classData) {
    //     // setIsEditing(true);
    //     setCurrentClass(classData);
    //     form.setFieldsValue({
    //         year: classData.year,
    //         className: classData.className,
    //         streams: classData.streams.join(', '),
    //     });
    // } else {
    //     // setIsEditing(false);
    //     form.resetFields();
    // }
    setVisible(true);
  };

  const handleCloseModal = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleYearChange = (value) => {
    setSelectedYear(value);
    // dispatch(fetchActiveFeeTypes(value)); // Fetch fee types for selected year
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedFeeTypes(activeFeeTypes.map(feeType => feeType.id));
    } else {
      setSelectedFeeTypes([]);
    }
  };

  const handleFeeTypeChange = (checkedValues) => {
    setSelectedFeeTypes(checkedValues);
  };

  const onFinish = (values) => {
    console.log(selectedFeeTypes)
    console.log(values)
    const feeGroup = {
      groupName: values.groupName,
      yearID: selectedYear,
      description: values.description,
      feeDetails: selectedFeeTypes?.map(feeTypeID => ({
        feeTypeID,
        dueDate: values[`dueDate[${feeTypeID}]`] ? values[`dueDate[${feeTypeID}]`].valueOf() : null,  // Access due date for each feeTypeID
        // dueDate: values[`dueDate[${feeTypeID}]`] || "",  // Access due date for each feeTypeID
        amount: values[`amount[${feeTypeID}]`] || 0,     // Access amount for each feeTypeID    
        // dueDate: values.dueDate[feeTypeID],
        // amount: values.amount[feeTypeID],
      })),
      time: dayjs().valueOf(),
      active: true,
    };
    dispatch(createFeeGroup(feeGroup));
    form.resetFields();
    message.success('Fee Group created successfully!');
  };

  const feeColumns = [
    {
      title: (
        <Checkbox
          onChange={handleSelectAll}
          checked={selectedFeeTypes?.length === activeFeeTypes?.length}
        >
          {/* Select All */}
        </Checkbox>
      ),
      dataIndex: 'checkbox',
      key: 'checkbox',
      render: (_, record) => (
        <Checkbox
          checked={selectedFeeTypes.includes(record.id)}
          onChange={() => handleFeeTypeChange(
            selectedFeeTypes.includes(record.id)
              ? selectedFeeTypes.filter(id => id !== record.id)
              : [...selectedFeeTypes, record.id]
          )}
        />
      ),
    },
    {
      title: 'Fee Type',
      dataIndex: 'feeName',
      key: 'name',
      // render: (text) => text,  // Display feeType.name directly
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (_, record) => (
        <Form.Item
          name={`dueDate[${record.id}]`}
          rules={[{ required: selectedFeeTypes.includes(record.id), message: 'Please select a due date!' }]}
          style={{ marginBottom: 0 }}
        >
          {/* <Input type="date" disabled={!selectedFeeTypes.includes(record.id)} /> */}
          <DatePicker
            style={{ width: '100%' }}
            placeholder='Select Due Date'
            disabled={!selectedFeeTypes.includes(record.id)}
          // format="YYYY-MM-DD"
          />
        </Form.Item>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => (
        <Form.Item
          name={`amount[${record.id}]`}
          rules={[{ required: selectedFeeTypes.includes(record.id), message: 'Please input an amount!' }]}
          style={{ marginBottom: 0 }}
        >
          <Input type="number" disabled={!selectedFeeTypes.includes(record.id)} />
        </Form.Item>
      ),
    },
  ];

  const groupColumns = [
    {
      title: 'S/No',
      dataIndex: 'key',
    },
    {
      title: 'Name',
      dataIndex: 'groupName',
    },
    {
      title: 'Total Fees',
      dataIndex: 'groupTotal',
    },
    {
      title: 'Year',
      dataIndex: 'yearID',
      render: (yearID) => {
        const year = activeYears.find(yr => yr.id === yearID);
        return year ? year.name : null; // Return the name or null if not found
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Fee Types',
      dataIndex: 'feeTypes',
      // render: (feeTypes) => feeTypes.join(', '),
      render: (feeTypes) => (
        <div style={{ whiteSpace: 'pre-line' }}>
          {feeTypes.map(ft => `${ft.feeName}: ${parseInt(ft.associatedAmount).toLocaleString()}`).join('\n')}
        </div>
      ),
    },
  ];

  const activeYearIDs = activeYears?.map(year => year?.id);
  //the active years filter makes that only groups falling in the active years are shown

  const dataSource = feeGroups?.filter(item => activeYearIDs.includes(item.yearID))?.map((group, index) => ({
    key: index + 1,
    ...group,
    // Create an object mapping feeName to associatedAmount
    feeTypes: group?.feeDetails?.map(detail => {
      const feeType = activeFeeTypes.find(ft => ft.id === detail.feeTypeID);
      return feeType ? { feeName: feeType.feeName, associatedAmount: detail.amount } : null; // Map to feeName and associatedAmount
    }).filter(item => item), // Filter out any null values
    groupTotal: group?.feeDetails?.reduce((total, detail) => total + (parseInt(detail.amount) || 0), 0).toLocaleString(), // Calculate the sum of amounts
  }));

  return (
    <Container>
      <Breadcrumb
        style={{ marginBottom: 10 }}
        items={[
          {
            title: <Link to='/'><DashboardOutlined /></Link>,
          },
          {
            title: "Fee Settings",
          },
          {
            title: 'Fee Groups'
          },

        ]}
      />

      <StyledButton type="primary" onClick={() => handleOpenModal()} loading={loading} disabled={loading}>
        Create Fee Group
      </StyledButton>

      <Table
        dataSource={dataSource}
        columns={groupColumns}
        rowKey="id" // Ensure your data has a unique key
        // pagination={false}
        pagination={{ pageSize: 10 }}
        bordered
      />

      <Modal
        title={"Create Fee Group"}
        visible={visible}
        onCancel={handleCloseModal}
        footer={null}
        style={{minWidth:650}}
      >
        <StyledForm form={form} onFinish={onFinish}>
          <Form.Item
            name="groupName"
            label="Group Name"
            rules={[{ required: true, message: 'Please input group name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="yearID"
            label="Select Year"
            rules={[{ required: true, message: 'Please select a year!' }]}
          >
            <Select onChange={handleYearChange}>
              {activeYears?.map(year => (
                <Option key={year.id} value={year.id}>{year.name}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: 'Please input description!' }]}
          >
            <Input.TextArea />
          </Form.Item>

          <Table
            dataSource={activeFeeTypes}
            columns={feeColumns}
            rowKey="id"
            pagination={false}
            bordered
            style={{marginBottom:30}}
          />

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create Fee Group
            </Button>
          </Form.Item>
        </StyledForm>
      </Modal>
    </Container>
  );
};

export default FeeGroups;
