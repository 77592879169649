import React, { useEffect, useState } from 'react';
import { Table, Select, Button, Input, Modal, Affix, message, Breadcrumb } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchActiveStudyYears } from '../../actions/StudyYearActions';
import { fetchClasses, fetchStudents } from '../../actions/SchoolActions';
import { stream } from 'xlsx';
import { updateStudentClasses } from '../../actions/StudentsActions';
import { Link } from 'react-router-dom';
import { DashboardOutlined } from '@ant-design/icons';

const { Search } = Input;
const { Option } = Select;

const Wrapper = styled.div`
  padding: 20px;
  // background-color: #fff;
`;


const FiltersContainer = styled.div`
  display: flex;
flex-wrap: wrap;
  gap: 16px;
    margin-bottom: 20px;
  justify-content:center;
`;

const ChangeClass = () => {
  const dispatch = useDispatch();

  const activeYears = useSelector(state => state.studyYear.activeYears);
  const classes = useSelector(state => state.school.classes);
  const students = useSelector(state => state.school.students);
  const loading = useSelector(state => state.school.loading);


  const [filterOptions, setFilterOptions] = useState({});
  const [searchText, setSearchText] = useState('');
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [changeToClass, setChangeToClass] = useState(null);
  const [changeToStream, setChangeToStream] = useState(null);

  // useEffect(() => {
  //   // dispatch(fetchActiveStudyYears());
  //   // dispatch(fetchStudents());
  //   // dispatch(fetchClasses());
  // }, [dispatch]);

  // useEffect(() => {
  //   setFilteredStudents(students);
  // }, [students]);

  useEffect(() => {
    setChangeToStream(null);
  }, [changeToClass]);

  useEffect(() => {
    setFilterOptions((prev) => ({ ...prev, year: activeYears[0]?.id }));
  }, [activeYears]);

  useEffect(() => {
    if (filterOptions.year) {
      const yearFilter = filterOptions.year;

      const filteredStudents = students.filter((student) => {
        const matchesYear = yearFilter
          ? student.classes.some(classItem => classItem.year === yearFilter)
          : true;

        return matchesYear;
      });

      setSelectedStudents([]);
      setFilterOptions((prev) => ({ ...prev, class: null, stream: null, gender: null }));
      setSearchText('');
      setChangeToClass(null);

      setFilteredStudents(filteredStudents);
    }
  }, [students, filterOptions.year]);


  // Handlers for search and filter
  const handleSearchText = (e) => setSearchText(e.target.value);

  const handleSearch = () => {
    const yearFilter = filterOptions.year;

    const searchedStudents=
      students.filter(
        (student) =>
          student.name.toLowerCase().includes(searchText.toLowerCase()) ||
          student.regNumber.toLowerCase().includes(searchText.toLowerCase())
      );

    const filteredStudents = searchedStudents.filter((student) => {
      const matchesYear = yearFilter
        ? student.classes.some(classItem => classItem.year === yearFilter)
        : true;

      return matchesYear;
    });

    setFilteredStudents(filteredStudents);

    setSelectedStudents([]);

  };

  const handleFilter = () => {
    const { class: classFilter, stream: streamFilter, gender: genderFilter, year: yearFilter } = filterOptions;

    const filteredStudents = students.filter((student) => {
      const selectedClass = classes.find(clas => clas.id === classFilter); // Get the class matching the filter

      const matchesSearch = (searchText && searchText !== '')
        ? student?.name?.toLowerCase().includes(searchText?.toLowerCase()) || student?.regNumber.toLowerCase().includes(searchText?.toLowerCase())
        : true;

      // Check if the student's class, stream, and gender match the selected filters
      // const matchesClass = classFilter ? student.class === selectedClass?.className : true;
      const matchesClass = classFilter
        ? student.classes.some(classItem => classItem.class === selectedClass?.id)
        : true;

      // const matchesStream = streamFilter ? student.stream === streamFilter : true;
      const matchesStream = streamFilter
        ? student.classes.some(classItem => classItem.stream === streamFilter)
        : true;

      const matchesGender = genderFilter ? student.gender === genderFilter : true;

      const matchesYear = yearFilter
        ? student.classes.some(classItem => classItem.year === yearFilter)
        : true;

      return matchesSearch && matchesClass && matchesStream && matchesGender && matchesYear;
    });

    setFilteredStudents(filteredStudents);
    setSelectedStudents([]);

  };

  // Columns for the Table
  const columns = [
    {
      title: (
        <input
          type="checkbox"
          onChange={(e) =>
            setSelectedStudents(e.target.checked ? filteredStudents.map((s) => s.id) : [])
          }
        />
      ),
      dataIndex: 'id',
      key: 'select',
      render: (id) => (
        <input
          type="checkbox"
          checked={selectedStudents.includes(id)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedStudents((prev) => [...prev, id]);
            } else {
              setSelectedStudents((prev) => prev.filter((studentID) => studentID !== id));
            }
          }}
        />
      ),
    },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Reg Number', dataIndex: 'regNumber', key: 'regNumber' },
    {
      title: 'Class',
      dataIndex: 'classes',
      render: (renderClass) => {
        const activeYearIDs = activeYears
          ?.map(year => year?.id);
        const matchingClassInStudent = renderClass?.find(classItem =>
          activeYearIDs?.includes(classItem.year)
        );
        const matchingClass = classes?.find(clas => clas.id === matchingClassInStudent?.class); // Get the class matching the filter
        return matchingClass?.className || 'N/A'; // assuming `className` holds the class name
      },
    },
    {
      title: 'Stream',
      dataIndex: 'classes',
      render: (renderClass) => {
        const activeYearIDs = activeYears
        ?.map(year => year?.id);
        const matchingClassInStudent = renderClass?.find(classItem =>
          activeYearIDs?.includes(classItem.year)
        );
        return matchingClassInStudent?.stream || 'N/A'; // assuming `stream` holds the stream name
      },
    },
    { title: 'Gender', dataIndex: 'gender', key: 'gender' },
  ];

  // Modal Handlers
  const showChangeToModal = () => setIsModalVisible(true);
  const handleModalOk = () => {
    const selectedYear = filterOptions.year;
    if (changeToClass && changeToStream) {
      dispatch(updateStudentClasses(selectedYear,selectedStudents, changeToClass, changeToStream));
      setSelectedStudents([]);
      setChangeToClass(null);
      setChangeToStream(null);
      setIsModalVisible(false);
    } else{
      message.error('Select both Class and Stream to continue!');
    }
  };
  const handleModalCancel = () => setIsModalVisible(false);

  return (
    <Wrapper>
        <Breadcrumb
                style={{ marginBottom: 10 }}
                items={[
                    {
                        title: <Link to='/'><DashboardOutlined /></Link>,
                    },
                    {
                        title: "Students",
                    },
                    {
                        title: 'Change Class and/or Stream'
                    },

                ]}
            />

      {/* <Select
          
          placeholder="Select Study Year"
          onChange={(value) => setFilterOptions((prev) => ({ ...prev, year: value }))}
          options={activeYears.map((year) => ({ label: year.name, value: year.id }))}
          style={{ width: 200 }}
        /> */}
      <Select
        placeholder="Select Study Year"
        value={filterOptions.year}
        onChange={(value) => setFilterOptions((prev) => ({ ...prev, year: value }))}
        options={activeYears.map((year) => ({ label: year.name, value: year.id }))}
        style={{width: 200, marginBottom: 20 }}
        required
      />

      <Search
        placeholder="Search by Name or Registration Number"
        onSearch={handleSearch}
        value={searchText || undefined} // Bind to state and reset to undefined when cleared
        onChange={handleSearchText}
        enterButton
        style={{ width: '80%', marginBottom: 20, justifySelf: 'center', display: 'flex' }}
      />

      <FiltersContainer>


        <Select
          allowClear
          placeholder="Select Class"
          style={{ width: 150 }}
          onChange={(value) => setFilterOptions((prev) => ({ ...prev, class: value, stream: null }))}
          value={filterOptions.class || undefined} // Bind to state and reset to undefined when cleared
        >
          {filterOptions.year
            ? classes
              ?.filter((clas) => clas.yearID === filterOptions.year)
              ?.map((classItem) => (
                <Option key={classItem.id} value={classItem.id}>
                  {classItem.className}
                </Option>
              ))
            : classes?.map((classItem) => (
              <Option key={classItem.id} value={classItem.id}>
                {classItem.className}
              </Option>
            ))}
        </Select>

        <Select
          allowClear
          placeholder="Select Stream"
          style={{ width: 150 }}
          disabled={!filterOptions.class}
          onChange={(value) => setFilterOptions((prev) => ({ ...prev, stream: value }))}
          value={filterOptions.stream || undefined} // Bind to state and reset to undefined when cleared
        >
          {filterOptions.class
            ? classes.find((classItem) => classItem.id === filterOptions.class)?.streams
              ?.map((stream) => (
                <Option key={stream} value={stream}>
                  {stream}
                </Option>
              ))
            : classes
              ?.flatMap((classItem) => classItem.streams)
              ?.map((stream) => (
                <Option key={stream} value={stream}>
                  {stream}
                </Option>
              ))}
        </Select>

        <Select
          allowClear
          placeholder="Select Gender"
          style={{ width: 150 }}
          onChange={(value) => setFilterOptions((prev) => ({ ...prev, gender: value }))}
          value={filterOptions.gender || undefined} // Bind to state and reset to undefined when cleared
        >
          <Option value="Male">Male</Option>
          <Option value="Female">Female</Option>
        </Select>

        <Button type="primary" onClick={handleFilter}>
          Filter
        </Button>
      </FiltersContainer>

      <Affix
        offsetTop={100}
        style={{ marginTop: 15, marginBottom: 15 }}
      // style={{ position: 'absolute', top: 100, right: 20 }}
      >
        <Button
          type="primary"
          disabled={selectedStudents?.length === 0}
          onClick={showChangeToModal}
        >
          Change Class
        </Button>
      </Affix>

      <Table
        dataSource={filteredStudents.sort((a, b) => a.name.localeCompare(b.name))}
        columns={columns}
        pagination={{ pageSize: 10 }}
        rowKey="id"
        bordered
      />

      <Modal
        title="Change Class to:"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Select
          allowClear
          placeholder="Select Class"
          style={{ width: '100%', marginBottom: 16 }}
          onChange={setChangeToClass}
          value={changeToClass || undefined}
        >
          {classes
            ?.filter((clas) => clas.yearID === filterOptions.year)
            .map((classItem) => (
              <Option key={classItem.id} value={classItem.id}>
                {classItem.className}
              </Option>
            ))}
        </Select>

        <Select
          allowClear
          placeholder="Select Stream"
          style={{ width: '100%' }}
          value={changeToStream || undefined}
          onChange={setChangeToStream}
        >
          {classes.find((classItem) => classItem.id === changeToClass)?.streams?.map((stream) => (
            <Option key={stream} value={stream}>
              {stream}
            </Option>
          ))}
        </Select>
      </Modal>
    </Wrapper>
  );
};

export default ChangeClass;
