import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Typography, Select, Breadcrumb } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFeeTypes, createFeeType, updateFeeType } from '../../actions/FeesActions'; // Adjust imports as necessary
import styled from 'styled-components';

import dayjs from 'dayjs';
import { DashboardOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Title } = Typography;
const { Option } = Select;

const FeeTypesContainer = styled.div`
  padding: 20px;
`;

const StyledButton = styled(Button)`
  margin-bottom: 16px;
`;

const FeeTypes = () => {
  const dispatch = useDispatch();
  const feeTypes = useSelector(state => state.fees.feeTypes); // Assume your Redux state has feeTypes
  const loading = useSelector(state => state.fees.loading); // Assume your Redux state has feeTypes
  const error = useSelector(state => state.fees.error); // Assume your Redux state has feeTypes
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [editingFeeType, setEditingFeeType] = useState(null);
  const [dataSource, setDataSource] = useState([]);

  // useEffect(() => {
  //   dispatch(fetchFeeTypes()); // Fetch fee types on component mount
  // }, [dispatch]);

  useEffect(() => {
    const unsubscribe = dispatch(fetchFeeTypes());

    // Clean up listener on component unmount
    return () => unsubscribe && unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const source = feeTypes
      // ?.filter(feeType => feeType.active !== false) // Only display active fee types
      ?.map((feeType, index) => ({
        ...feeType,
        key: feeType.id, // Assuming feeType has a unique id
      })) || [];

    setDataSource(source);
  }, [feeTypes]);

  const handleOpenModal = () => {
    setVisible(true);
    form.resetFields();
    setEditingFeeType(null);
  };

  const handleCloseModal = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleCreateOrUpdateFeeType = async (values) => {
    if (editingFeeType) {
      // Update existing fee type
      await dispatch(updateFeeType({ ...values, id: editingFeeType.id }));
    } else {
      // Create new fee type
      await dispatch(createFeeType({
        ...values,
        dateCreated: dayjs().valueOf(),
        time: dayjs().valueOf(),
        active: true
      }));
    }
    handleCloseModal();
  };

  const handleEditFeeType = (feeType) => {
    setEditingFeeType(feeType);
    form.setFieldsValue({ feeName: feeType.feeName, description: feeType.description });
    setVisible(true);
  };

  const handleDeleteFeeType = async (id) => {
    // Mark fee type as inactive (soft delete)
    await dispatch(updateFeeType({ id, active: false }));
  };

  const handleActivateFeeType = async (id) => {
    // Mark fee type as inactive (soft delete)
    await dispatch(updateFeeType({ id, active: true }));
  };

  const columns = [
    {
      title: 'S/No',
      dataIndex: 'key',
      key: 'key',
      render: (_, __, index) => index + 1, // Serial number
    },
    {
      title: 'Name',
      dataIndex: 'feeName',
      key: 'feeName',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      render: (active) => (active ? 'Active' : 'Disabled'),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <>
          <Button type="link" onClick={() => handleEditFeeType(record)}>Edit</Button>
          {
            record?.active &&
            <Button type="link" danger onClick={() => handleDeleteFeeType(record.id)}>Disable</Button>
          }
          {
            !record?.active &&
            <Button type="link" onClick={() => handleActivateFeeType(record.id)}>Enable</Button>
          }
        </>
      ),
    },
  ];



  return (
    <FeeTypesContainer>
          <Breadcrumb
                style={{ marginBottom: 10 }}
                items={[
                    {
                        title: <Link to='/'><DashboardOutlined /></Link>,
                    },
                    {
                        title: "Fee Settings",
                    },
                    {
                        title: 'Fee Types'
                    },

                ]}
            />
      {/* <Title level={2}>Manage Fee Types</Title> */}
      <StyledButton type="primary" onClick={handleOpenModal} loading={loading} disabled={loading}>
        Add Fee Type
      </StyledButton>

      <Table
        dataSource={dataSource} // Pass filtered dataSource
        columns={columns}
        // pagination={false}
        pagination={{ pageSize: 10 }}
        rowKey="id"
        bordered
      />

      <Modal
        title={editingFeeType ? 'Edit Fee Type' : 'Add Fee Type'}
        visible={visible}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Form
          form={form}
          onFinish={handleCreateOrUpdateFeeType}
          layout="vertical"
        >
          <Form.Item
            name="feeName"
            label="Fee Name"
            rules={[{ required: true, message: 'Please enter the fee name' }]}
          >
            <Input placeholder="Enter fee name" />
          </Form.Item>

          <Form.Item
            label="Payment Order"
            name="paymentOrder"
            rules={[{ required: true, message: 'Please select order in transaction' }]}
          >
            <Select defaultValue={"normal"} placeholder="Select School">
              <Option value="first">First</Option>
              <Option value="normal">Normal</Option>
              <Option value="last">Last</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: 'Please enter a description' }]}
          >
            <Input.TextArea rows={4} placeholder="Enter description" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingFeeType ? 'Update Fee Type' : 'Create Fee Type'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </FeeTypesContainer>
  );
};

export default FeeTypes;
