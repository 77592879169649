// StudyYearActions.js
import { collection, addDoc, getDocs, updateDoc, onSnapshot, doc, query, where } from 'firebase/firestore';
import {
  CREATE_STUDY_YEAR_FAILURE,
  CREATE_STUDY_YEAR_REQUEST,
  CREATE_STUDY_YEAR_SUCCESS,
  FETCH_ALL_STUDY_YEARS_FAILURE,
  FETCH_ALL_STUDY_YEARS_REQUEST,
  FETCH_ALL_STUDY_YEARS_SUCCESS ,
     DEACTIVATE_STUDY_YEAR_FAILURE,
  DEACTIVATE_STUDY_YEAR_REQUEST,
  DEACTIVATE_STUDY_YEAR_SUCCESS, 
     ACTIVATE_STUDY_YEAR_FAILURE,
  ACTIVATE_STUDY_YEAR_REQUEST,
  ACTIVATE_STUDY_YEAR_SUCCESS
} from './Types';
import { db } from '../config/firebase';
import { message } from 'antd';


/// i'm moving its implementations to fetchStudyYears... hence will be useless
export const fetchActiveStudyYears = () => {
  return async (dispatch) => {
    try {
      const studyYearsRef = collection(db, 'studyYears');
      const querySnapshot = await getDocs(studyYearsRef);
      const activeYears = [];

      querySnapshot.forEach((doc) => {
        const yearData = doc.data();

        // Check if the year is active
        if ((yearData?.active !== false)          ) {
          activeYears.push({ id: doc.id, ...yearData });
        }
      });
      dispatch({
        type: 'FETCH_ACTIVE_YEARS_SUCCESS',
        payload: activeYears,
      });
    } catch (error) {
      dispatch({
        type: 'FETCH_ACTIVE_YEARS_FAILURE',
        payload: error.message,
      });
    }
  };
};

// Action to fetch all study years with real-time updates
export const fetchAllStudyYears = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_ALL_STUDY_YEARS_REQUEST });

    const studyYearsRef = collection(db, "studyYears");

    // Subscribe to real-time updates using onSnapshot
    const unsubscribe = onSnapshot(
      studyYearsRef,
      (querySnapshot) => {
        const studyYears = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const activeYears = [];

        querySnapshot.forEach((doc) => {
          const yearData = doc.data();
  
          // Check if the year is active
          if ((yearData?.active !== false)          ) {
            activeYears.push({ id: doc.id, ...yearData });
          }
        });

        dispatch({
          type: FETCH_ALL_STUDY_YEARS_SUCCESS,
          payload: {studyYears,activeYears},
        });
      },
      (error) => {
        dispatch({
          type: FETCH_ALL_STUDY_YEARS_FAILURE,
          payload: error.message,
        });
      }
    );

    // Return unsubscribe function to stop listening when component unmounts
    return unsubscribe;
  };
};

// Action to create a new study year
export const createStudyYear = (studyYear) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_STUDY_YEAR_REQUEST });

    try {
      // Step 1: Fetch all existing study years
      const studyYearsRef = collection(db, 'studyYears');
      // try {
      //     const querySnapshot = await getDocs(studyYearsRef);
      //     const updates = [];

      //     // Step 2: Prepare updates for all existing study years to set active to false
      //     querySnapshot.forEach((doc) => {
      //         const currentYearData = doc.data();
      //         if (currentYearData.active) {
      //             updates.push(updateDoc(doc.ref, { active: false }));
      //         }
      //     });

      //     // Wait for all updates to complete
      //     await Promise.all(updates);
      // } catch (error) {

      // }

      // Step 3: Add the new study year
      const newDocRef = await addDoc(studyYearsRef, studyYear);

      message.success(`Study year created successful!`);

      dispatch({
        type: CREATE_STUDY_YEAR_SUCCESS,
        payload: { id: newDocRef.id, ...studyYear },
      });
    } catch (error) {
      message.error(`Failed to create study year!`);

      dispatch({
        type: CREATE_STUDY_YEAR_FAILURE,
        payload: error.message,
      });
    }
  };
};



export const deactivateStudyYear = (studyYearId) => async (dispatch) => {   
   dispatch({ type: DEACTIVATE_STUDY_YEAR_REQUEST });

  try {
    const studyYearRef = doc(db, 'studyYears', studyYearId);
    await updateDoc(studyYearRef, { active: false });

    message.success(`Study year deactivated successful!`);

    dispatch({ type: DEACTIVATE_STUDY_YEAR_SUCCESS });
  } catch (error) {
    message.error(`Failed to deactivate study year!`);

    dispatch({
      type: DEACTIVATE_STUDY_YEAR_FAILURE,
      payload: error.message,
    });
  }
};


export const activateStudyYear = (studyYearId, school) => async (dispatch) => {
  dispatch({ type: ACTIVATE_STUDY_YEAR_REQUEST });

  try {
    const studyYearsRef = collection(db, 'studyYears');
    const schoolQuery = query(studyYearsRef, where('school', '==', school));
    const querySnapshot = await getDocs(schoolQuery);

    const updates = [];

    // Deactivate all study years with the specified school
    querySnapshot.forEach((doc) => {
      const currentYearData = doc.data();
      if (currentYearData.active) {
        updates.push(updateDoc(doc.ref, { active: false }));
      }
    });

    // Wait for all deactivations to complete
    await Promise.all(updates);

    // Now activate the specified study year
    const studyYearRef = doc(db, 'studyYears', studyYearId);
    await updateDoc(studyYearRef, { active: true });

    message.success(`Study year activated successful!`);

    dispatch({ type: ACTIVATE_STUDY_YEAR_SUCCESS });
  } catch (error) {
    message.error(`Failed to activate study year!`);

    dispatch({
      type: ACTIVATE_STUDY_YEAR_FAILURE,
      payload: error.message,
    });
  }
};
