// LaunchActions.js
import { EmailAuthProvider, reauthenticateWithCredential, signInWithEmailAndPassword, signOut, updatePassword } from 'firebase/auth';
import { LOGIN_USER, LOGIN_USER_FAILED, LOGIN_USER_SUCCESSFUL, LOGOUT } from './Types';
import { message } from 'antd';
import { auth } from '../config/firebase';

export const loginUser = (email, password) => async (dispatch) => {
    dispatch({ type: LOGIN_USER });

    try {
        await signInWithEmailAndPassword(auth, email, password);
        // Optionally dispatch any actions or handle post-login logic here
        dispatch({ type: LOGIN_USER_SUCCESSFUL });

        message.success('Welcome!');

    } catch (error) {
        dispatch({ type: LOGIN_USER_FAILED, payload: error });

        message.error('Login failed!');

        console.error("Login error:", error);
        // Optionally dispatch an error action or show a notification
    }
};



export const changePassword = (password,newPassword) => async (dispatch) => {
  try {
    dispatch({ type: 'CHANGE_PASSWORD_REQUEST' });

    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, password);

    if (user) {
        await reauthenticateWithCredential(user, credential);
        
        await updatePassword(user,newPassword);
      dispatch({ type: 'CHANGE_PASSWORD_SUCCESS' });
    } else {
    //   throw new Error('No user is currently logged in');
      dispatch({
        type: 'CHANGE_PASSWORD_FAILURE',
        payload: 'No user is currently logged in',
      });
    }
  } catch (error) {
    dispatch({
      type: 'CHANGE_PASSWORD_FAILURE',
      payload: error || 'Password change failed',
    });
  }
};




// Action creator for logging out
export const logoutUser = () => async (dispatch) => {

    try {
        await signOut(auth);
        dispatch({ type: LOGOUT });
        // Optionally handle additional logic after logout
    } catch (error) {
        console.error("Logout error:", error);
        // Optionally dispatch an error action or show a notification
    }
};

