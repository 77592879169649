import { CREATE_EXPENSE_RECORD_FAILURE, CREATE_EXPENSE_RECORD_REQUEST, CREATE_EXPENSE_RECORD_SUCCESS, CREATE_EXPENSE_TYPE_FAILURE, CREATE_EXPENSE_TYPE_REQUEST, 
    CREATE_EXPENSE_TYPE_SUCCESS, 
    DELETE_EXPENSE_RECORD_FAILURE, 
    DELETE_EXPENSE_RECORD_REQUEST, 
    DELETE_EXPENSE_RECORD_SUCCESS, 
    DELETE_EXPENSE_TYPE_FAILURE, 
    DELETE_EXPENSE_TYPE_REQUEST, 
    DELETE_EXPENSE_TYPE_SUCCESS, 
    FETCH_EXPENSE_RECORDS_FAILURE, 
    FETCH_EXPENSE_RECORDS_REQUEST, 
    FETCH_EXPENSE_RECORDS_SUCCESS, 
    FETCH_EXPENSE_TYPES_FAILURE, 
    FETCH_EXPENSE_TYPES_REQUEST, 
    FETCH_EXPENSE_TYPES_SUCCESS, 
    UPDATE_EXPENSE_TYPE_FAILURE, 
    UPDATE_EXPENSE_TYPE_REQUEST, 
    UPDATE_EXPENSE_TYPE_SUCCESS} from "../actions/Types";

const initialState = {
    expenseTypes: [],
    expenseRecords: [],
    loading: false,
    error: null,
  };
  
    const ExpensesReducer = (state = initialState, action) => {

    switch (action.type) {
      case FETCH_EXPENSE_TYPES_REQUEST:
      case CREATE_EXPENSE_TYPE_REQUEST:
      case UPDATE_EXPENSE_TYPE_REQUEST:
      case DELETE_EXPENSE_TYPE_REQUEST:
        return { ...state, loading: true };
  
      case FETCH_EXPENSE_TYPES_SUCCESS:
        return { ...state, loading: false, expenseTypes: action.payload };
  
      case CREATE_EXPENSE_TYPE_SUCCESS:
      case UPDATE_EXPENSE_TYPE_SUCCESS:
      case DELETE_EXPENSE_TYPE_SUCCESS:
        return { ...state, loading: false };
  
      case FETCH_EXPENSE_TYPES_FAILURE:
      case CREATE_EXPENSE_TYPE_FAILURE:
      case UPDATE_EXPENSE_TYPE_FAILURE:
      case DELETE_EXPENSE_TYPE_FAILURE:
        return { ...state, loading: false, error: action.payload };



        case FETCH_EXPENSE_RECORDS_REQUEST:
          return {
            ...state,
            loading: true,
            error: null,
          };
    
        case FETCH_EXPENSE_RECORDS_SUCCESS:
          return {
            ...state,
            loading: false,
            expenseRecords: action.payload,
            error: null,
          };
    
        case FETCH_EXPENSE_RECORDS_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    
        case CREATE_EXPENSE_RECORD_REQUEST:
          return {
            ...state,
            loading: true,
            error: null,
          };
    
        case CREATE_EXPENSE_RECORD_SUCCESS:
          return {
            ...state,
            loading: false,
            // data: [action.payload, ...state.data], // Add new record to the top of the list
            error: null,
          };
    
        case CREATE_EXPENSE_RECORD_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
    
        case DELETE_EXPENSE_RECORD_REQUEST:
          return {
            ...state,
            loading: true,
          };
    
        case DELETE_EXPENSE_RECORD_SUCCESS:
          return {
            ...state,
            loading: false,
            // data: state.data.map((record) =>
            //   record.id === action.payload ? { ...record, active: false } : record
            // ),
          };
    
        case DELETE_EXPENSE_RECORD_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };
  
      default:
        return state;
    }
  }

  export default ExpensesReducer;
