import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { addDoc, collection, doc, getFirestore, onSnapshot, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { logoutUser } from './AuthActions';
import { FETCH_USER_FAILURE, FETCH_USER_REQUEST, FETCH_USER_SUCCESS, TOGGLE_THEME, UPDATE_USER_FAILURE, UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS } from './Types';
import { message } from 'antd';

// Initialize Firebase services
const auth = getAuth();


export const toggleTheme = () => ({
  type: TOGGLE_THEME,
});


export const registerUser = (userData) => async (dispatch) => {
  try {
    dispatch({ type: 'REGISTER_USER_REQUEST' });

    // Step 1: Create a new user with email and password (without logging the admin out)
    const { email, password, role, name } = userData;
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);

    if (userCredential) {
      // Step 2: Add user details to Firestore (excluding the password)
      const userID = userCredential.user.uid;

      // Step 2: Add user details to Firestore under "users/userID"
      await setDoc(doc(db, 'users', userID), {
        active: true,
        email: email,
        role: role,
        names: name,
        uid: userID,
      });
      message.success(`User registered successful!`);
      dispatch({ type: 'REGISTER_USER_SUCCESS', payload: { email, role, name } });

      dispatch(logoutUser());

    }
  } catch (error) {
    message.error(`Failed to register user!`);

    dispatch({
      type: 'REGISTER_USER_FAILURE',
      payload: error,
    });
  }
};




// Fetch User Action
export const fetchUser = (userId) => (dispatch) => {
  dispatch({ type: FETCH_USER_REQUEST });

  try {
    const userDocRef = doc(db, "users", userId);
    const unsubscribe = onSnapshot(userDocRef, (snapshot) => {
      if (snapshot.exists()) {
        dispatch({ type: FETCH_USER_SUCCESS, payload: snapshot.data() });
      } else {
        dispatch({
          type: FETCH_USER_FAILURE,
          payload: "User not found",
        });
      }
    });

    // Unsubscribe when the component using this action is unmounted
    return unsubscribe;
  } catch (error) {
    dispatch({
      type: FETCH_USER_FAILURE,
      payload: error.message || "Failed to fetch user",
    });
  }
};

// Update User Action
export const updateUser = (userId, updatedData) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_REQUEST });

  try {
    const userDocRef = doc(db, "users", userId);
    await updateDoc(userDocRef, updatedData);

    message.success(`User profile updated successful!`);

    dispatch({ type: UPDATE_USER_SUCCESS });
    dispatch(fetchUser(userId)); // Refresh user data after update
  } catch (error) {
    message.error(`Failed to update user profile!`);

    dispatch({
      type: UPDATE_USER_FAILURE,
      payload: error.message || "Failed to update user",
    });
  }
};




export const fetchUsers = () => async (dispatch) => {
  try {
    dispatch({ type: 'FETCH_USERS_REQUEST' });

    onSnapshot(collection(db, 'users'), (snapshot) => {
      const users = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));


      dispatch({ type: 'FETCH_USERS_SUCCESS', payload: users });
    });
  } catch (error) {
    dispatch({
      type: 'FETCH_USERS_FAILURE',
      payload: error.message,
    });
  }
};

export const updateUserRole = (userId, newRole) => async (dispatch) => {
  dispatch({ type: 'UPDATE_USER_ROLE_REQUEST' });

  try {
    // Step 1: Get a reference to the user's document in Firestore
    const docRef = doc(db, 'users', userId);

    // Step 2: Update the user's role
    await updateDoc(docRef, { role: newRole });
    message.success(`User role updated successful!`);

    dispatch({ type: 'UPDATE_USER_ROLE_SUCCESS', payload: { userId, newRole } });

  } catch (error) {
    message.error(`Failed to update user role!`);

    dispatch({
      type: 'UPDATE_USER_ROLE_FAILURE',
      payload: error.message || 'Failed to update user role',
    });
  }
};


export const toggleUserStatus = (userId, isActive) => async (dispatch) => {
  try {
    dispatch({ type: 'TOGGLE_USER_STATUS_REQUEST' });

    await updateDoc(doc(db, 'users', userId), { active: isActive });
    message.success(`User ${isActive?'activated':'deactivated'} successful!`);


    dispatch({ type: 'TOGGLE_USER_STATUS_SUCCESS', payload: { userId, isActive } });
  } catch (error) {
    message.error(`Failed to ${isActive?'activate':'deactivate'} user!`);

    dispatch({
      type: 'TOGGLE_USER_STATUS_FAILURE',
      payload: error.message,
    });
  }
};

