// Layout.js
import React, { useState, Suspense, useEffect } from 'react';
import { Layout, Menu, Typography, Dropdown, Spin, Image, Divider, Button, ConfigProvider } from 'antd';
import {
  DashboardOutlined,
  DollarCircleOutlined,
  HistoryOutlined,
  SettingOutlined,
  ProfileOutlined,
  DownOutlined,
  UserOutlined,
  EditOutlined,
  LockOutlined,
  LogoutOutlined,
  CalendarOutlined,
  TeamOutlined,
  BookOutlined,
  PlusCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  MoonFilled,
  SunFilled,
  BarChartOutlined,
  CreditCardOutlined,
  WalletOutlined,
  FolderOpenOutlined,
  AuditOutlined,
  FileOutlined,
  BankOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { logoutUser } from '../../actions/AuthActions';
import logo from '../../assets/swillaLogo.png';
import { useDispatch, useSelector } from 'react-redux';
import { toggleTheme } from '../../actions/UserActions';

const { Header, Sider, Content } = Layout;
const { Title } = Typography;

const oldTheme = {
  token: {
    // Primary
    colorPrimary: "var(--ant-primary-color)",
    colorPrimaryHover: "var(--ant-primary-hover-color)",

    colorSecondary: "var(--secondary)",

    colorBgElevated: "var(--primary-background)",

    // Button Colors
    colorBtnPrimaryBg: "var(--primary)", // Primary Button
    colorBtnPrimaryHoverBg: "var(--btn-hover-bg)", // Button hover
    colorBtnText: "var(--primary-text)", // Button text

    // Border and Divider Colors
    colorBorder: "var(--border-color)", // Border colors
    colorSplit: "var(--border-color)", // Splitter/divider color


    // Text
    colorText: "var(--primary-text)",
    colorTextSecondary: "var(--secondary-text)",
    colorTextDisabled: "#999",
    colorTextPlaceholder: "var(--secondary-text)", // Placeholder text color

    // Background
    colorBgLayout: "var(--primary-background)",
    colorBgContainer: "var(--secondary-background)",
    colorBackground: "var(--primary-background)", // Main background
    colorBackgroundSecondary: "var(--secondary-background)", // Secondary background
    colorTextBase: "var(--primary-text)", // Default text color


    // Borders
    //  colorBorder: "#ddd",
    colorBorderSecondary: "#bbb",

    // Success, Warning, Error
    colorSuccess: "#52c41a",
    colorWarning: "#faad14",
    colorError: "#ff4d4f",
    

    // Links
    // colorLink: "#65256f",
    // colorLinkHover: "#8e5f96",

    // Table Header
    // colorTableHeaderBg: "var(--primary)", // Black header background
    // colorTableHeaderText: "var(--primary-background)", // White text

    // Table Row Hover
    // colorTableRowHoverBg: "var(--secondary)", // Hover color

    // // Table Colors (Including Hover, Header, and Border Styles)
    // colorTableHeaderBg: "var(--primary-background)", // Table header background
    // colorTableRowHoverBg: "var(--dropdown-hover-bg)", // Row hover background
    // colorTableBorder: "var(--border-color)", // Table borders
    // colorTableText: "var(--primary-text)", // Table text color
    // colorTableHeaderText: "var(--primary-text)", // Table header text color
    // colorTableSelectedRowBg: "var(--primary)", // Selected row background
  },
  components: {
    Button: {
      colorPrimaryBorderHover: 'red',
      colorPrimaryHover: "var(--btn-hover-bg)",
      colorPrimary: "var(--primary)",
      colorPrimaryActive: 'lightgray',
      colorPrimaryTextHover: 'lightgray',
      colorPrimaryText: "var(--primary-text)",
    }
  }
};

const newTheme = {
  token: {
    // Primary Colors
    colorPrimary: "var(--primary)", // Buttons, Links, Active Elements
    colorSecondary: "var(--secondary)",
    

    // Background Colors
    colorBackground: "var(--primary-background)", // Main background
    colorBackgroundSecondary: "var(--secondary-background)", // Secondary background
    colorTextBase: "var(--primary-text)", // Default text color
    colorTextSecondary: "var(--secondary-text)",

    // Border and Divider Colors
    colorBorder: "var(--border-color)", // Border colors
    colorSplit: "var(--border-color)", // Splitter/divider color

    // Button Colors
    colorBtnPrimaryBg: "var(--primary)", // Primary Button
    colorBtnPrimaryHoverBg: "var(--btn-hover-bg)", // Button hover
    colorBtnText: "var(--primary-text)", // Button text

    // Input and Form Colors
    colorInputBorder: "var(--input-border-color)", // Input border
    colorInputBg: "var(--primary-background)", // Input background
    colorInputText: "var(--primary-text)", // Input text
    colorInputPlaceholder: "var(--secondary-text)", // Placeholder text

    // Dropdown Colors
    colorDropdownBg: "var(--dropdown-bg)", // Dropdown background
    colorDropdownHoverBg: "var(--dropdown-hover-bg)", // Dropdown hover background

    // Modal Colors
    colorModalHeaderBg: "var(--primary)", // Modal header
    colorModalFooterBg: "var(--secondary-background)", // Modal footer
    colorModalBodyBg: "var(--primary-background)", // Modal body
    colorModalText: "var(--primary-text)", // Modal text

    // Table Colors (Including Hover, Header, and Border Styles)
    colorTableHeaderBg: "var(--primary-background)", // Table header background
    colorTableRowHoverBg: "var(--dropdown-hover-bg)", // Row hover background
    colorTableBorder: "var(--border-color)", // Table borders
    colorTableText: "var(--primary-text)", // Table text color
    colorTableHeaderText: "var(--primary-text)", // Table header text color
    colorTableSelectedRowBg: "var(--primary)", // Selected row background
  },
};


const AppLayout = ({ children }) => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);

  const user = useSelector(state => state.user.user);

  const location = useLocation();
  const navigate = useNavigate();

  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);

  // const [isLightTheme, setLightTheme] = useState(true);

  // const currentTheme = isLightTheme ? lightTheme : darkTheme;

  const isLightTheme = useSelector((state) => state.user.theme.isLightTheme);

  const handleToggle = () => {
    dispatch(toggleTheme()); // Dispatch toggle action
  };


  // Routes-to-keys mapping
  const routesToKeys = {
    "/": { openKey: "3", selectedKey: "1" },
    "/analytics": { openKey: "3", selectedKey: "2" },
    "/collect-payments": { openKey: "3", selectedKey: "3-1" },
    "/collect-past-payments": { openKey: "3", selectedKey: "3-2" },
    "/payments-history": { openKey: "3", selectedKey: "3-3" },
    "/record-expenses": { openKey: "4", selectedKey: "4-1" },
    "/expenses-history": { openKey: "4", selectedKey: "4-2" },
    "/students": { openKey: "5", selectedKey: "5-1" },
    "/update-student-classes": { openKey: "5", selectedKey: "5-2" },
    "/promote-student-classes": { openKey: "5", selectedKey: "5-3" },
    "/allocation": { openKey: "6", selectedKey: "6-1" },
    "/fee-groups": { openKey: "6", selectedKey: "6-2" },
    "/fee-types": { openKey: "6", selectedKey: "6-3" },
    "/payment-systems": { openKey: "6", selectedKey: "6-4" },
    "/expense-types": { openKey: "7", selectedKey: "7-1" },
    "/classes-and-streams": { openKey: "8", selectedKey: "8-1" },
    "/study-years": { openKey: "8", selectedKey: "8-2" },
    "/schools": { openKey: "8", selectedKey: "8-3" },
    "/register-user": { openKey: "9", selectedKey: "9-1" },
    "/manage-users": { openKey: "9", selectedKey: "9-2" },
  };

  useEffect(() => {
    const route = routesToKeys[location.pathname];
    if (route) {
      setSelectedKeys([route.selectedKey]);
      setOpenKeys(route.openKey ? [route.openKey] : []);
    }
  }, [location.pathname]);


  useEffect(() => {
    const newTheme = isLightTheme ? "light" : "dark";

    document.documentElement.setAttribute("data-theme", newTheme);
  }, [isLightTheme]);

  // Initialize theme based on system preference or default to light
  // useEffect(() => {
  //   const storedTheme = localStorage.getItem("theme");
  //   document.documentElement.setAttribute("data-theme", storedTheme);
  //   setLightTheme(storedTheme === "light");
  // }, []);

  // // Persist theme preference to localStorage
  // useEffect(() => {
  //   localStorage.setItem("theme", isLightTheme ? "light" : "dark");
  // }, [isLightTheme]);


  const handleMenuClick = ({ key }) => {
    const matchingRoute = Object.entries(routesToKeys).find(
      ([, keys]) => keys.selectedKey === key
    );
    if (matchingRoute) {
      navigate(matchingRoute[0]);
    }
  };

  // Function to toggle theme
  // const toggleTheme = () => {
  //   const newTheme = isLightTheme ? "dark" : "light";
  //   document.documentElement.setAttribute("data-theme", newTheme);
  //   setLightTheme(!isLightTheme);
  // };

  const handleCollapse = () => setCollapsed(!collapsed);

  const handleLogout = () => {
    dispatch(logoutUser());
  }

  const profileMenu = (
    <Menu>
      <Menu.Item key="1" style={{ backgroundColor: "var(--primary-backround)", cursor: 'default' }}>
        {/* <Link to="/profile">My Profile</Link> */}
        <span style={{ textTransform: "capitalize" }}>{user?.role}</span> : {user?.names}
      </Menu.Item>
      {/* <Menu.Item key="2" icon={<ProfileOutlined />}> */}
      <Divider style={{ marginTop: '3px', marginBottom: '3px' }} />
      {/* </Menu.Item> */}
      <Menu.Item key="2" icon={<EditOutlined />}>
        <Link to="/profile">My Profile</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<LockOutlined />}>
        <Link to="/change-password">Change Password</Link>
      </Menu.Item>
      <Menu.Item style={{ color: 'red' }} key="4" onClick={handleLogout} icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <ConfigProvider theme={oldTheme}>
      <LayoutStyled>
        <HeaderStyled
        //  style={{maxHeight:'50px'}}
        >
          <Logo>
            <ImageStyled style={{ width: '50px', height: '50px' }} src={logo} alt="Logo" preview={false} />
          </Logo>

          <TitleStyled style={{ margin: 0, color: 'var(--primary-text)' }} level={4}>SWILLA SCHOOLS FEE SYSTEM</TitleStyled>

          <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
            <button onClick={handleToggle} style={{ border: "none", background: "transparent", cursor: "pointer" }}>
              {isLightTheme ? <MoonFilled style={{ fontSize: "20px", color: "var(--primary-text)" }} /> : <SunFilled style={{ fontSize: "20px", color: "var(--primary-text)" }} />}
            </button>
            <Dropdown overlay={profileMenu} placement="bottomRight" arrow>
              <ProfileMenuStyled>
                <UserOutlined /> Profile <DownOutlined style={{fontSize:13,color:"var(--tertiary-text)"}}/>
                              </ProfileMenuStyled>
            </Dropdown>
          </div>
        </HeaderStyled>
        <Layout hasSider style={{ position: 'relative' }}>
          <SiderStyled
            style={{
              overflow: 'auto',
              // height: '100vh',
              position: 'fixed',
              insetInlineStart: 0,
              top: 0,
              bottom: 0,
              scrollbarWidth: 'thin',
              // scrollbarGutter: 'stable',
            }}
            // trigger={null}
            trigger={
              <div style={{ backgroundColor: 'var(--secondary)', color: 'var(--primary-text)', textAlign: 'center', padding: '1px', fontSize: 20, fontWeight: 'bold' }}>
                {collapsed ? '>' : '<'}
              </div>
            }
            // triggerStyle={{ backgroundColor: 'var(--primary)', color: 'var(--primary-text)' }}
            collapsible
            collapsed={collapsed}
            onCollapse={handleCollapse}
          >
            <MenuStyled
              // mode="inline"
              defaultSelectedKeys={['1']}
              defaultOpenKeys={['2']}

              mode="inline"
              //  style={{ width: 256 }}
              selectedKeys={selectedKeys}
              openKeys={openKeys}
              onOpenChange={(keys) => setOpenKeys(keys)}
              onClick={handleMenuClick}
            >
              <Menu.Item key="1" icon={<DashboardOutlined />}>
                <Link to="/">Dashboard</Link>
              </Menu.Item>
              <Menu.Item key="2" icon={<BarChartOutlined />}>
                <Link to="/analytics">Analytics</Link>
              </Menu.Item>

              <Menu.SubMenu key="3" icon={<CreditCardOutlined />} title="Payments">
                <Menu.Item key="3-1" icon={<WalletOutlined />}>
                  <Link to="/collect-payments">Collect</Link>
                </Menu.Item>
                <Menu.Item key="3-2" icon={<FolderOpenOutlined />}>
                  <Link to="/collect-past-payments">Collect Past</Link>
                </Menu.Item>
                <Menu.Item key="3-3" icon={<HistoryOutlined />}>
                  <Link to="/payments-history">History</Link>
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.SubMenu key="4" icon={<AuditOutlined />} title="Expenses">
                <Menu.Item key="4-1" icon={<DollarCircleOutlined />}>
                  <Link to="/record-expenses">Record</Link>
                </Menu.Item>
                <Menu.Item key="4-2" icon={<HistoryOutlined />}>
                  <Link to="/expenses-history">History</Link>
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.SubMenu key="5" icon={<UserOutlined />} title="Students">
                <Menu.Item key="5-1" icon={<UserOutlined />}>
                  <Link to="/students">Admission</Link>
                </Menu.Item>
                <Menu.Item key="5-2" icon={<AuditOutlined />}>
                  <Link to="/update-student-classes">Change Classes</Link>
                </Menu.Item>
                <Menu.Item key="5-3" icon={<FileOutlined />}>
                  <Link to="/promote-student-classes">Promote Classes</Link>
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.SubMenu key="6" icon={<SettingOutlined />} title="Fee Settings">
                <Menu.Item key="6-1" icon={<CalendarOutlined />}>
                  <Link to="/allocation">Allocation</Link>
                </Menu.Item>
                <Menu.Item key="6-2" icon={<BookOutlined />}>
                  <Link to="/fee-groups">Fee Groups</Link>
                </Menu.Item>
                <Menu.Item key="6-3" icon={<FileOutlined />}>
                  <Link to="/fee-types">Fee Types</Link>
                </Menu.Item>
                <Menu.Item key="6-4" icon={<WalletOutlined />}>
                  <Link to="/payment-systems">Payment Systems</Link>
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.SubMenu key="7" icon={<SettingOutlined />} title="Expense Settings">
                <Menu.Item key="7-1" icon={<CalendarOutlined />}>
                  <Link to="/expense-types">Expense Types</Link>
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.SubMenu key="8" icon={<SettingOutlined />} title="School Settings">
                <Menu.Item key="8-1" icon={<CalendarOutlined />}>
                  <Link to="/classes-and-streams">Classes and Streams</Link>
                </Menu.Item>
                <Menu.Item key="8-2" icon={<CalendarOutlined />}>
                  <Link to="/study-years">Study Years</Link>
                </Menu.Item>
                <Menu.Item key="8-3" icon={<BankOutlined />}>
                  <Link to="/schools">Schools</Link>
                </Menu.Item>
              </Menu.SubMenu>

              <Menu.SubMenu key="9" icon={<UserOutlined />} title="Admin Settings">
                <Menu.Item key="9-1" icon={<PlusCircleOutlined />}>
                  <Link to="/register-user">Register User</Link>
                </Menu.Item>
                <Menu.Item key="9-2" icon={<TeamOutlined />}>
                  <Link to="/manage-users">Manage Users</Link>
                </Menu.Item>
              </Menu.SubMenu>
            </MenuStyled>
          </SiderStyled>
          <Layout
            style={{
              marginInlineStart: collapsed ? 80 : 200
            }}
          >
            <ContentStyled>
              {/* <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined style={{fontSize:40}}/> : <MenuFoldOutlined style={{fontSize:40}}/>}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              // fontSize: '25px',
              width: 50,
              height: 50,
            }}
          /> */}

              {/* <Outlet />  */}
              {/* Rendered routes go here */}
              {children}
            </ContentStyled>
          </Layout>
        </Layout>
      </LayoutStyled>
    </ConfigProvider>
  );
};

export default AppLayout;

// Styled Components
const LayoutStyled = styled(Layout)`
  // height: 100vh;
  font-family: 'Roboto', sans-serif;
`;

const HeaderStyled = styled(Header)`
  background-color: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  position: fixed;
  width: 100%;
  z-index: 1000;
`;

const SiderStyled = styled(Sider)`
  background-color: var(--secondary-background);
  color: var(--primary-text);
  // margin-top: 64px; // Offset for fixed header height
  //  overflow: auto,
  // height: 100vh,
  // position: fixed,
  // insetInlineStart: 0,
  // top: 0,
  // bottom: 0,
  // scrollbar-width: thin,
  // scrollbar-gutter: stable,
`;

const MenuStyled = styled(Menu)`
  // font-size: 0.875rem; // Smaller font for system dashboard style
  // .ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title,
  // .ant-menu-light>.ant-menu .ant-menu-submenu-selected >.ant-menu-submenu-title,
  // .ant-menu-item, .ant-menu-title-content .ant-menu-submenu-selected {
  //   color: red;
  //   }
  .ant-menu-submenu-selected > .ant-menu-submenu-title {
  background-color: var(--table-header-bg) !important; /* Highlight background */
  color: var(--primary-text) !important; /* Highlight text color */
  font-weight: bold; /* Optional: Make text bold */
}

.ant-menu-submenu-selected > .ant-menu-submenu-title:hover {
  background-color: var(--table-selected-row-bg) !important; /* Background color on hover */
  color: var(--hover-text) !important;
}
    .ant-menu-item-selected {
      background-color: var(--primary);
      color: #fff;
  };
    margin-top: 64px; // Offset for fixed header height
      padding-bottom:50px;
`;

const Logo = styled.div`
  font-size: 1.25rem;
  color: var(--primary-text);
`;

const TitleStyled = styled(Title)`
  color: var(--primary-text);
  font-size: 1rem;
  text-align: center;
  // align-self: flex-end;
  flex: 1;
  margin: 0;
`;

const ProfileMenuStyled = styled.div`
  color: var(--primary-text);
  cursor: pointer;
    font-size: 1.1rem;
font-weight: 500;
height:auto;
`;

const ContentStyled = styled(Content)`
  margin-top: 64px; // Adjust for header height
  padding: 24px;
  background-color: var(--primary-background);
  color: var(--primary-text);
  // min-height: 100vh;
`;

const ImageStyled = styled(Image)`
// &.ant-image .ant-image-img{
//     width: 40px;
//     height: 40px;
//     }
`;
