import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <Result      
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button  type="primary" style={{background:"var(--primary)"}} onClick={() => navigate("/")}>
            Back to Dashboard
          </Button>
        }
      />
    </div>
  );
};

export default NotFound;
