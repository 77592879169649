import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Tag, Typography, Space, Button, Modal, Tooltip, Breadcrumb } from 'antd';
import { deletePaymentRecord, fetchActivePaymentRecords, fetchPaymentSystems } from '../../actions/PaymentActions';
import { auth } from '../../config/firebase';
import { fetchActiveFeeTypes } from '../../actions/FeesActions';
import { fetchStudents } from '../../actions/SchoolActions';

import logo from '../../assets/swillaLogo.png';

import { jsPDF } from 'jspdf'; // Make sure to install jsPDF for PDF generation
import "jspdf-autotable";
import { fetchUsers } from '../../actions/UserActions';
import { removeEmptyArraysDeep } from '../../utils';
import { Link } from 'react-router-dom';
import { DashboardOutlined } from '@ant-design/icons';

const PaymentHistory = () => {
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [overdraft, setOverdraft] = useState([]);
  const students = useSelector(state => state.school.students);
  const activeFeeTypes = useSelector(state => state.fees.activeFeeTypes);
  const paymentSystems = useSelector(state => state.payments.paymentSystems);
  const { user, users } = useSelector((state) => state.user);

  // Replace with the appropriate selector to fetch payment records from the reducer
  const { paymentRecords, loading } = useSelector((state) => state.payments);

  const { Text } = Typography;

  useEffect(() => {
    dispatch(fetchActivePaymentRecords());
    // dispatch(fetchActiveFeeTypes());
    // dispatch(fetchPaymentSystems());

    // dispatch(fetchUsers());

    // dispatch(fetchStudents());
  }, [dispatch]);



  // Calculate total amount
  const calculateTotalAmount = (feesPaid, overdraft) => {
    const totalFees = feesPaid.reduce((sum, fee) => sum + (fee.amountPaid || 0), 0);
    return totalFees + overdraft;
  };

  // Handle modal visibility
  const handleShowModal = (feesPaid, overdraft) => {
    setModalData(feesPaid);
    setOverdraft(overdraft);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setModalData([]);
    setOverdraft([]);
  };

  const handleDelete = (recordId) => {
    dispatch(deletePaymentRecord(recordId));
  };


  const generatePDF = (record) => {

    const student = students.find((st) => st.id === record.studentID);

    const doc = new jsPDF({
      orientation: "landscape", // For half A4 width
      unit: "mm",              // Use mm for accurate dimensions
      format: [210, 148.5],    // Set width and height explicitly
    });

    // console.log(receiptData)
    // Page dimensions
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // Add the Logo
    doc.addImage(logo, 'PNG', 10, 5, 35, 35); // Adjust position and size

    // Add Title and Address
    doc.setFontSize(12);
    doc.text(`CHRISTIAN IN EDUCATION AND DEVELOPMENT PROMOTION(CEDP)`, (pageWidth / 2) + 22.5, 10, { align: "center" }); // Title
    doc.text(`SWILLA SCHOOLS`, (pageWidth / 2) + 22.5, 15, { align: "center" }); // Title
    doc.setFontSize(10);
    doc.text(`P.O.BOX 1787, MBEYA - TANZANIA`, (pageWidth / 2) + 22.5, 20, { align: "center" });
    doc.text(`Tel: +255 252 560108, +255 758 6064 572`, (pageWidth / 2) + 22.5, 25, { align: "center" });
    doc.text(`Email: info@swillaschools.ac.tz`, (pageWidth / 2) + 22.5, 30, { align: "center" });
    doc.text(`Website: www.swillaschools.ac.tz`, (pageWidth / 2) + 22.5, 35, { align: "center" });

    // Add Date and Payment System
    doc.setFontSize(10);
    // doc.text(`Date: ${new Date().toLocaleDateString()}`, 10, 60);
    // doc.text(`Payment System: `, 10, 70); // Replace with actual payment system

    const margin = 10; // Margin from the edges

    // Draw left-aligned text
    doc.text(`Receipt No. 4232`, margin, 50);

    doc.text(`RECEIPT`, (pageWidth / 2), 50, { align: "center", });

    // Measure the width of the right-aligned text
    const rightTextWidth = doc.getTextWidth(`Date: ${new Date().toLocaleDateString()}`);

    // Draw right-aligned text
    doc.text(`Date: ${new Date(record?.time).toLocaleDateString()}`, pageWidth - margin - rightTextWidth, 50);




    // Measure the text width for accurate underlining
    const textWidth = doc.getTextWidth(`RECEIPT`);

    // Draw an underline (slightly below the text)
    doc.setDrawColor(0); // Black color for the line
    doc.setLineWidth(0.5); // Line thickness
    doc.line((pageWidth / 2) - (textWidth / 2), 50 + 1, (pageWidth / 2) + (textWidth / 2), 50 + 1);


    doc.setFontSize(12);
    doc.setLineWidth(0.3); // Line thickness
    doc.setLineDash([.5, 1]); // Dash length and gap
    doc.text(`RECEIVED FROM: ${student.name} (${student.regNumber})`, 10, 58); // Title
    doc.line(doc.getTextWidth(`RECEIVED FROM: `) + margin, 58 + 1, (pageWidth) - margin, 58 + 1);

    doc.text(`DATE OF PAYMENT: ${new Date(record?.dateOfPayment).toLocaleDateString()}`, 10, 65); // Title
    doc.line(doc.getTextWidth(`DATE OF PAYMENT: `) + margin, 65 + 1, (pageWidth) - margin, 65 + 1);

    doc.text(`PAYMENT SYSTEM: ${paymentSystems?.find((ps) => ps.id === record?.paymentSystemID)?.systemName||record?.paymentSystemID}`, 10, 72); // Title
    doc.line(doc.getTextWidth(`PAYMENT SYSTEM: `) + margin, 72 + 1, (pageWidth) - margin, 72 + 1);

    doc.setLineDash([]); // Resettttting


    // // Add Payment Details Title
    // doc.setFontSize(14);
    // doc.text('Payment Details:', 10, 100);

    const columns = ["NO", "RECEIPT DETAILS", "AMOUNT IN TSHS"];

    var initial = record?.feesPaid?.map((item, index) => {

      const fee = activeFeeTypes?.find((fee) => fee.id === item?.feeTypeID);

      // Map other fields into standard columns
      return [index + 1, fee.feeName || "", item.amountPaid.toLocaleString() || ""];
    }) || [];

    var last = [];

    if (record?.overdraft && parseInt(record?.overdraft) > 0) {
      last = ["", "OVERDRAFT", `${record.overdraft.toLocaleString()}`];// Ensure the "amount" entry occupies the last column
    }

    let totalAmount = 0;

    // Calculate total from feesPaid
    record?.feesPaid?.forEach((item) => {
      totalAmount += parseInt(item?.amountPaid || 0); // Ensure `amountPaid` is a number
    });

    // Add overdraft if it exists
    if (record?.overdraft) {
      totalAmount += parseInt(record.overdraft || 0); // Add overdraft amount if present
    }

    const total = ["", "TOTAL", `${totalAmount.toLocaleString()}`];// Ensure the "amount" entry occupies the last column

    const rows = [...initial, last, total]

    // console.log(receiptData)
    // console.log(rows)

    doc.autoTable({
      head: [columns],
      body: removeEmptyArraysDeep(rows),
      startY: 75,
      theme: "grid", // Themes: 'striped', 'grid', or 'plain'
      styles: {
        fontSize: 10,         // Font size for table content
        halign: "center",     // Horizontal alignment: 'left', 'center', 'right'
        valign: "middle",     // Vertical alignment
        fillColor: [220, 220, 220], // Background color for cells
        textColor: "#000000",       // Font color
      },
      headStyles: {
        fillColor: [41, 128, 185], // Header background color
        textColor: "#ffffff",      // Header text color
        fontStyle: "bold",         // Header text style
      },
      alternateRowStyles: {
        fillColor: [245, 245, 245], // Alternating row color
      },
      margin: { top: 20 },        // Table position from the top
      columnStyles: {
        3: { halign: "right" }, // Align "Amount" to the right
      },
      didDrawCell: (data) => {
        const rowIndex = data.row.index;
        const colIndex = data.column.index;

        // Check if it's the "Total" row
        if (rowIndex === data.table.body.length - 1) {
          // Merge all cells except the last one
          if (colIndex < columns.length - 1) {
            data.cell.styles.fillColor = [220, 220, 220]; // Light gray for merged cells
            data.cell.colSpan = columns.length - 1; // Merge all left cells
            if (colIndex !== 2) {
              data.cell.text = ""; // Clear text for non-"Total:" cells
            }
          }
        }
      },
    });



    // // Add the Table for Payment Details
    // const startY = 110;
    // const rowHeight = 10;
    // const columnWidths = [80, 60, 40]; // Adjust width for each column

    // // Table Header
    // doc.setFontSize(12);
    // doc.setFont("helvetica", "bold");
    // doc.text('Fee Type', 10, startY);
    // // doc.text('Group', 90, startY);
    // doc.text('Amount Paid', 150, startY);

    // Table Rows
    // doc.setFontSize(10);
    // doc.setFont("helvetica", "normal");
    // receiptData.forEach((item, index) => {
    //   doc.text(item.feeName, 10, startY + (index + 1) * rowHeight);
    //   // doc.text(item.groupName, 90, startY + (index + 1) * rowHeight);
    //   doc.text(`Ths ${item.amountPaid?.toLocaleString()}`, 150, startY + (index + 1) * rowHeight);
    // });

    // Draw a horizontal line under the table
    doc.setLineWidth(0.3); // Line thickness
    doc.line(10, 133, 200, 134);

    doc.setLineWidth(0.3); // Line thickness
    doc.setLineDash([.5, 1]); // Dash length and gap
    // // Overdraft message
    // if (overdraft) {
    //   doc.setFontSize(12);
    //   doc.setTextColor(255, 0, 0); // Red color
    //   doc.text('Overdraft', 10, startY + (receiptData.length + 2) * rowHeight);
    //   doc.setTextColor(0, 0, 0); // Reset to black
    // }

    // // Total Amount
    // doc.setFontSize(12);
    // doc.setFont("helvetica", "bold");
    // doc.text(`Total: Ths ${totalAmount.toLocaleString()}`, 10, startY + (receiptData.length + 3) * rowHeight);

    // // Amount in Words
    // doc.setFontSize(10);
    // doc.text(`Amount in words: ${totalAmountInWords}`, 10, startY + (receiptData.length + 4) * rowHeight);
    // Space for Name
    doc.text(`Received by: ${users?.find((usr) => usr?.uid === record?.collectorID).names}`, 10, 138);
    doc.line(doc.getTextWidth(`Received by: `) + margin, 138 + 1, (pageWidth / 2) - margin, 138 + 1);
    // Space for signature
    doc.text('Signature:', 10, 143);
    doc.line(doc.getTextWidth(`Signature: `) + margin, 143 + 1, (pageWidth / 2) - margin, 143 + 1);

    doc.setLineDash([]); // Dash length and gap

    // Space for signature
    // doc.text('Date:', 10, startY + (receiptData.length + 10) * rowHeight);
    // doc.text('_____________________________', 10, startY + (receiptData.length + 11) * rowHeight);

    // // Footer - Add a horizontal line and Download Text
    // doc.setLineWidth(0.5);
    // doc.line(10, startY + (receiptData.length + 12) * rowHeight, 200, startY + (receiptData.length + 12) * rowHeight);
    doc.setFontSize(8);
    doc.text('Thank you for your payment!', (pageWidth / 2), 147.5, { align: "center" });

    // Download the PDF
    doc.save(`${student.name}_${student.regNumber}.pdf`);
  };

  // Define table columns
  const columns = [
    // {
    //   title: 'Payment ID',
    //   dataIndex: 'id',
    //   key: 'id',
    // },
    {
      title: 'S/No',
      dataIndex: 'id',
      key: 'id',
      render: (_, __, index) => index + 1, // Serial number
    },
    {
      title: 'Date Received',
      dataIndex: 'time',
      key: 'time',
      render: (time) => new Date(time).toLocaleString(),
    },

    {
      title: 'Student ID',
      dataIndex: 'studentID',
      key: 'studentID',
      render: (studentID) => students?.find((stud) => stud.id === studentID)?.name,
    },
    {
      title: 'Date of Payment',
      dataIndex: 'dateOfPayment',
      key: 'dateOfPayment',
      render: (dateOfPayment) => new Date(dateOfPayment).toLocaleDateString(),
    },
    {
      title: 'Total Amount',
      key: 'totalAmount',
      render: (record) => {
        const total = calculateTotalAmount(record.feesPaid, record.overdraft);
        return (
          <Button type="link" onClick={() => handleShowModal(record.feesPaid, record.overdraft)}>
            <Tooltip title={record?.description}>
              Tsh {total.toLocaleString()}
            </Tooltip>
          </Button>
        );
      },
    },
    {
      title: 'Overdraft',
      dataIndex: 'overdraft',
      key: 'overdraft',
      render: (overdraft) =>
        overdraft > 0 ? (
          <Tag color="red">Tsh {overdraft.toLocaleString()}</Tag>
        ) : (
          <Tag color="green">No Overdraft</Tag>
        ),
    },
    // {
    //   title: 'Active',
    //   dataIndex: 'active',
    //   key: 'active',
    //   render: (active) => (
    //     <Tag color={active ? 'green' : 'volcano'}>
    //       {active ? 'Active' : 'Inactive'}
    //     </Tag>
    //   ),
    // },
    {
      title: 'Actions',
      key: 'actions',
      render: (record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => generatePDF(record)}>
            Print Receipt
          </Button>
          {
            (((Date.now() - record?.time) <= 24 * 60 * 60 * 1000) || (user?.role === 'admin')) &&
            <Button type="danger" onClick={() => handleDelete(record.id)}>
              Delete
            </Button>
          }
        </Space>
      ),
    },
  ];

  // Modal table columns
  const modalColumns = [
    {
      title: 'Fee Name',
      dataIndex: 'feeTypeID',
      key: 'feeTypeID',
      render: (feeTypeID) => activeFeeTypes?.find((fee) => fee.id === feeTypeID)?.feeName,
    },
    {
      title: 'Amount Paid',
      dataIndex: 'amountPaid',
      key: 'amountPaid',
      render: (amountPaid) => `Tsh ${amountPaid.toLocaleString()}`,
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      {/* <Typography.Title level={3}>Payment History</Typography.Title> */}
      <Breadcrumb
        style={{ marginBottom: 10 }}
        items={[
          {
            title: <Link to='/'><DashboardOutlined /></Link>,
          },
          {
            title: "Payments",
          },
          {
            title: 'Payment History'
          },

        ]}
      />
      <Table
        dataSource={
          user?.role === 'admin' ?
            paymentRecords?.sort((a, b) => b.time - a.time) :
            paymentRecords?.sort((a, b) => b.time - a.time)?.filter(
              (record) => record.collectorID === auth.currentUser.uid
            )
        }
        columns={columns}
        loading={loading}
        pagination={{ pageSize: 10 }}
        rowKey="id"
        bordered
        // title={() => 'Filtered Payment Records'}
        footer={() => `${paymentRecords?.filter(
          (record) => record.collectorID === auth.currentUser.uid
        )?.length || 0} record(s) found`}
      />
      <Modal
        title="Fee Details"
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={[
          <Button key="close" onClick={handleCloseModal}>
            Close
          </Button>,
        ]}
      >
        <Table
          dataSource={modalData}
          columns={modalColumns}
          pagination={false}
          rowKey={(record) => record.feeTypeID}
          bordered
        />
        {
          overdraft &&
          parseInt(overdraft) > 0 &&
          <Text style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            Overdraft:
            <span style={{ marginLeft: '5px', fontWeight: 'bold' }}>
              Tsh {parseInt(overdraft).toLocaleString()}
            </span>
          </Text>
        }
      </Modal>
    </div>
  );
};

export default PaymentHistory;
