import React, { useEffect, useState } from 'react';
import { Table, Select, Button, Input, Modal, Affix, message, Breadcrumb, Checkbox } from 'antd';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchActiveStudyYears, fetchAllStudyYears } from '../../actions/StudyYearActions';
import { fetchClasses, fetchStudents } from '../../actions/SchoolActions';
import { stream } from 'xlsx';
import { clearPromotionResults, promoteStudentClasses, updateStudentClasses } from '../../actions/StudentsActions';
import { Link } from 'react-router-dom';
import { DashboardOutlined } from '@ant-design/icons';

const { Search } = Input;
const { Option } = Select;

const Wrapper = styled.div`
  padding: 20px;
  // background-color: #fff;
`;


const FiltersContainer = styled.div`
  display: flex;
flex-wrap: wrap;
  gap: 16px;
    margin-bottom: 20px;
  justify-content:center;
`;

const PromoteClass = () => {
  const dispatch = useDispatch();

  const activeYears = useSelector(state => state.studyYear.activeYears);
  const studyYears = useSelector(state => state.studyYear.studyYears).filter((yr) => yr.active !== true);
  const classes = useSelector(state => state.school.classes);
  const students = useSelector(state => state.school.students);
  const schoolLoading = useSelector(state => state.school.loading);

  const { loading, promoted, error } = useSelector((state) => state.students);


  const [filterOptions, setFilterOptions] = useState({});
  const [searchText, setSearchText] = useState('');
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newYear, setNewYear] = useState(null);
  const [newClass, setNewClass] = useState(null);
  const [newStream, setNewStream] = useState(null);

  const [isPromotionModalVisible, setPromotionModalVisible] = useState(false);

  const [allowSchoolChange, setAllowSchoolChange] = useState(false);

  // Filtered activeYears based on the checkbox state
  const filteredYears = allowSchoolChange
    ? activeYears // Show all years if school change is allowed
    : activeYears.filter(
      (year) => year.school === studyYears.find((y) => y.id === filterOptions.year)?.school
    );
  // console.log(filterOptions.year)
  // console.log(filteredYears)


  const handleOpenPromotionModal = () => setPromotionModalVisible(true);
  const handleClosePromotionModal = () => {
    // Dispatch the action to clear promotion results
    dispatch(clearPromotionResults());

    // Close the modal
    setPromotionModalVisible(false);
  };
  // useEffect(() => {
  //   // dispatch(fetchActiveStudyYears());
  //   // dispatch(fetchAllStudyYears());
  //   // dispatch(fetchStudents());
  //   // dispatch(fetchClasses());
  // }, [dispatch]);

  // useEffect(() => {
  //   setFilteredStudents(students);
  // }, [students]);

  useEffect(() => {
    setNewClass(null);
  }, [newYear]);

  useEffect(() => {
    setNewStream(null);
  }, [newClass]);

  // useEffect(() => {
  //   setFilterOptions((prev) => ({ ...prev, year: studyYears[0]?.id }));
  // }, [studyYears]);

  useEffect(() => {
    if (!filterOptions.year && studyYears.length > 0) {
      setFilterOptions((prev) => ({ ...prev, year: studyYears[0]?.id }));
    }
  }, [studyYears, filterOptions.year]);


  useEffect(() => {
    if (filterOptions.year) {
      const yearFilter = filterOptions.year;

      const filteredStudents = students.filter((student) => {
        const matchesYear = yearFilter
          ? student.classes.some(classItem => classItem.year === yearFilter)
          : true;

        return matchesYear;
      });

      setSelectedStudents([]);
      setFilterOptions((prev) => ({ ...prev, class: null, stream: null, gender: null }));
      setSearchText('');
      setNewClass(null);

      setFilteredStudents(filteredStudents);
    }
  }, [students, filterOptions.year]);


  // Automatically open the modal if there are results or an error
  useEffect(() => {
    if ((promoted.successStudents.length > 0 || promoted.failedStudents.length > 0 || error)) {
      handleOpenPromotionModal();
    }
  }, [promoted, error]);

  // Handlers for search and filter
  const handleSearchText = (e) => setSearchText(e.target.value);

  const handleSearch = () => {
    const yearFilter = filterOptions.year;

    const searchedStudents =
      students.filter(
        (student) =>
          student.name.toLowerCase().includes(searchText.toLowerCase()) ||
          student.regNumber.toLowerCase().includes(searchText.toLowerCase())
      );

    const filteredStudents = searchedStudents.filter((student) => {
      const matchesYear = yearFilter
        ? student.classes.some(classItem => classItem.year === yearFilter)
        : true;

      return matchesYear;
    });

    setFilteredStudents(filteredStudents);

    setSelectedStudents([]);

  };

  const handleFilter = () => {
    const { class: classFilter, stream: streamFilter, gender: genderFilter, year: yearFilter } = filterOptions;

    const filteredStudents = students.filter((student) => {
      const selectedClass = classes.find(clas => clas.id === classFilter); // Get the class matching the filter

      const matchesSearch = (searchText && searchText !== '')
        ? student?.name?.toLowerCase().includes(searchText?.toLowerCase()) || student?.regNumber.toLowerCase().includes(searchText?.toLowerCase())
        : true;

      // Check if the student's class, stream, and gender match the selected filters
      // const matchesClass = classFilter ? student.class === selectedClass?.className : true;
      const matchesClass = classFilter
        ? student.classes.some(classItem => classItem.class === selectedClass?.id)
        : true;

      // const matchesStream = streamFilter ? student.stream === streamFilter : true;
      const matchesStream = streamFilter
        ? student.classes.some(classItem => classItem.stream === streamFilter)
        : true;

      const matchesGender = genderFilter ? student.gender === genderFilter : true;

      const matchesYear = yearFilter
        ? student.classes.some(classItem => classItem.year === yearFilter)
        : true;

      return matchesSearch && matchesClass && matchesStream && matchesGender && matchesYear;
    });

    setFilteredStudents(filteredStudents);
    setSelectedStudents([]);

  };



  const getSelectedStudentsWithActiveYears = (selectedStudents) => {
    // Extract active year IDs
    const activeYearIds = activeYears.map((year) => year.id);

    // Filter selected students
    return selectedStudents.filter((studentId) => {
      // Find the corresponding student object
      const student = students.find((s) => s.id === studentId);

      if (student) {
        // Check if any class year matches an active year ID
        return student.classes.some((cls) => activeYearIds.includes(cls.year));
      }

      return false; // Return false if the student is not found
    });
  };




  // Columns for the Table
  const columns = [
    {
      title: (
        <input
          type="checkbox"
          onChange={(e) =>
            setSelectedStudents(e.target.checked ? filteredStudents.map((s) => s.id) : [])
          }
        />
      ),
      dataIndex: 'id',
      key: 'select',
      render: (id) => (
        <input
          type="checkbox"
          checked={selectedStudents.includes(id)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedStudents((prev) => [...prev, id]);
            } else {
              setSelectedStudents((prev) => prev.filter((studentID) => studentID !== id));
            }
          }}
        />
      ),
    },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Reg Number', dataIndex: 'regNumber', key: 'regNumber' },
    {
      title: 'Class',
      dataIndex: 'classes',
      render: (renderClass) => {
        const activeYearIDs = studyYears
          ?.map(year => year?.id);
        const matchingClassInStudent = renderClass?.find(classItem =>
          activeYearIDs?.includes(classItem.year)
        );
        const matchingClass = classes?.find(clas => clas.id === matchingClassInStudent?.class); // Get the class matching the filter
        return matchingClass?.className || 'N/A'; // assuming `className` holds the class name
      },
    },
    {
      title: 'Stream',
      dataIndex: 'classes',
      render: (renderClass) => {
        const activeYearIDs = studyYears
          ?.map(year => year?.id);
        const matchingClassInStudent = renderClass?.find(classItem =>
          activeYearIDs?.includes(classItem.year)
        );
        return matchingClassInStudent?.stream || 'N/A'; // assuming `stream` holds the stream name
      },
    },
    { title: 'Gender', dataIndex: 'gender', key: 'gender' },
  ];

  // Modal Handlers
  const showNewModal = () => {
    setIsModalVisible(true);
  }

  const handleModalOk = () => {

    const notToUpdate = getSelectedStudentsWithActiveYears(
      selectedStudents
    );

    const toUpdate = selectedStudents.filter(student => !notToUpdate.includes(student));


    if (newYear && newClass && newStream) {
      // console.log(newYear, selectedStudents, newClass, newStream);
      dispatch(promoteStudentClasses(newYear, notToUpdate, toUpdate, newClass, newStream));
      setSelectedStudents([]);
      setNewClass(null);
      setNewStream(null);
      setIsModalVisible(false);
    } else {
      message.error('Select Year, Class and Stream to continue!');
    }
  };
  const handleModalCancel = () => setIsModalVisible(false);


  const mapStudentDetails = (ids) =>
    ids.map((id) => {
      const student = students.find((student) => student.id === id);
      return {
        id,
        name: student?.name || "Unknown",
        regNumber: student?.regNumber || "N/A",
      };
    });

  const successDetails = mapStudentDetails(promoted.successStudents);
  const failedDetails = mapStudentDetails(promoted.failedStudents);

  return (
    <Wrapper>
      <Breadcrumb
        style={{ marginBottom: 10 }}
        items={[
          {
            title: <Link to='/'><DashboardOutlined /></Link>,
          },
          {
            title: "Students",
          },
          {
            title: 'Promote Class or School'
          },

        ]}
      />

      {/* <Select
          
          placeholder="Select Study Year"
          onChange={(value) => setFilterOptions((prev) => ({ ...prev, year: value }))}
          options={activeYears.map((year) => ({ label: year.name, value: year.id }))}
          style={{ width: 200 }}
        /> */}
      <Select
        placeholder="Select Study Year"
        value={filterOptions.year}
        onChange={(value) => setFilterOptions((prev) => ({ ...prev, year: value }))}
        options={studyYears.map((year) => ({ label: year.name, value: year.id }))}
        style={{ width: 200, marginBottom: 20 }}
        required
      />

      <Search
        placeholder="Search by Name or Registration Number"
        onSearch={handleSearch}
        value={searchText || undefined} // Bind to state and reset to undefined when cleared
        onChange={handleSearchText}
        enterButton
        style={{ width: '80%', marginBottom: 20, justifySelf: 'center', display: 'flex' }}
      />

      <FiltersContainer>


        <Select
          allowClear
          placeholder="Select Class"
          style={{ width: 150 }}
          onChange={(value) => setFilterOptions((prev) => ({ ...prev, class: value, stream: null }))}
          value={filterOptions.class || undefined} // Bind to state and reset to undefined when cleared
        >
          {filterOptions.year
            ? classes
              ?.filter((clas) => clas.yearID === filterOptions.year)
              ?.map((classItem) => (
                <Option key={classItem.id} value={classItem.id}>
                  {classItem.className}
                </Option>
              ))
            : classes?.map((classItem) => (
              <Option key={classItem.id} value={classItem.id}>
                {classItem.className}
              </Option>
            ))}
        </Select>

        <Select
          allowClear
          placeholder="Select Stream"
          style={{ width: 150 }}
          disabled={!filterOptions.class}
          onChange={(value) => setFilterOptions((prev) => ({ ...prev, stream: value }))}
          value={filterOptions.stream || undefined} // Bind to state and reset to undefined when cleared
        >
          {filterOptions.class
            ? classes.find((classItem) => classItem.id === filterOptions.class)?.streams
              ?.map((stream) => (
                <Option key={stream} value={stream}>
                  {stream}
                </Option>
              ))
            : classes
              ?.flatMap((classItem) => classItem.streams)
              ?.map((stream) => (
                <Option key={stream} value={stream}>
                  {stream}
                </Option>
              ))}
        </Select>

        <Select
          allowClear
          placeholder="Select Gender"
          style={{ width: 150 }}
          onChange={(value) => setFilterOptions((prev) => ({ ...prev, gender: value }))}
          value={filterOptions.gender || undefined} // Bind to state and reset to undefined when cleared
        >
          <Option value="Male">Male</Option>
          <Option value="Female">Female</Option>
        </Select>

        <Button type="primary" onClick={handleFilter}>
          Filter
        </Button>
      </FiltersContainer>

      <Affix
        offsetTop={100}
        style={{ marginTop: 15, marginBottom: 15 }}
      // style={{ position: 'absolute', top: 100, right: 20 }}
      >
        <Button
          type="primary"
          disabled={selectedStudents?.length === 0}
          onClick={showNewModal}
        >
          Promote
        </Button>
      </Affix>

      <Table
        dataSource={filteredStudents.sort((a, b) => a.name.localeCompare(b.name))}
        columns={columns}
        pagination={{ pageSize: 10 }}
        rowKey="id"
        bordered
      />

      <Modal
        title="Promote to:"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Checkbox
          checked={allowSchoolChange}
          onChange={(e) => setAllowSchoolChange(e.target.checked)}
          style={{ marginBottom: 16 }}
        >
          Allow School Change
        </Checkbox>

        <Select
          allowClear
          placeholder="Select Study Year"
          value={newYear || undefined}
          onChange={setNewYear}
          options={filteredYears.map((year) => ({ label: year.name, value: year.id }))}
          style={{ width: '100%', marginBottom: 16 }}
          required
        />

        <Select
          allowClear
          placeholder="Select Class"
          style={{ width: '100%', marginBottom: 16 }}
          onChange={setNewClass}
          value={newClass || undefined}
        >
          {classes
            ?.filter((clas) => clas.yearID === newYear)
            .map((classItem) => (
              <Option key={classItem.id} value={classItem.id}>
                {classItem.className}
              </Option>
            ))}
        </Select>

        <Select
          allowClear
          placeholder="Select Stream"
          style={{ width: '100%' }}
          value={newStream || undefined}
          onChange={setNewStream}
        >
          {classes.find((classItem) => classItem.id === newClass)?.streams?.map((stream) => (
            <Option key={stream} value={stream}>
              {stream}
            </Option>
          ))}
        </Select>
      </Modal>

      <Modal
        title="Promotion Results"
        open={isPromotionModalVisible}
        onCancel={handleClosePromotionModal}
        footer={<Button onClick={handleClosePromotionModal}>Close</Button>}
      >
        {successDetails.length > 0 && (
          <div style={{ marginBottom: 20 }}>
            <h4><span style={{ color: 'green' }}>Successfully</span> Promoted Students</h4>
            <ul>
              {successDetails.map(({ id, name, regNumber }) => (
                <li key={id}>
                  {name} ({regNumber})
                </li>
              ))}
            </ul>
          </div>
        )}

        {failedDetails.length > 0 && (
          <div>
            <h4><span style={{ color: 'red' }}>Failed</span> to Promote Students (You may want to change class instead)</h4>
            <h5> - They are already enrolled in that year or in one of active years</h5>
            <ul>
              {failedDetails.map(({ id, name, regNumber }) => (
                <li key={id}>
                  {name} ({regNumber})
                </li>
              ))}
            </ul>
          </div>
        )}

        {error && <div style={{ color: 'red', marginTop: 20 }}>Error: {error}</div>}
      </Modal>
    </Wrapper>
  );
};

export default PromoteClass;
