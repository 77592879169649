import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Table, Select, DatePicker } from "antd";
import {
  BarChart,
  Bar,
  PieChart,
  Pie,
  LineChart,
  Line,
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import { DownloadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import "./Analytics.css";

const { Option } = Select;
const { RangePicker } = DatePicker;

// Utility Functions for Data Transformation
const generateMockData = (studyYear) => {
  // Generate mock data based on the selected study year
  return {
    payments: [
      { student: "John Doe", feeType: "Tuition", amount: 1200, class: "Grade 10", stream: "A", dueDate: "2023-06-30", paymentMethod: "Bank Transfer", date: "2023-01-01", studyYear },
      { student: "Jane Smith", feeType: "Books", amount: 200, class: "Grade 10", stream: "B", dueDate: "2023-05-15", paymentMethod: "Mobile Payment", date: "2023-02-01", studyYear },
      { student: "Alice Johnson", feeType: "Transport", amount: 300, class: "Grade 9", stream: "C", dueDate: "2023-04-20", paymentMethod: "Bank Transfer", date: "2023-03-01", studyYear },
      { student: "Mark Lee", feeType: "Tuition", amount: 1500, class: "Grade 12", stream: "A", dueDate: "2023-06-30", paymentMethod: "Mobile Payment", date: "2023-04-01", studyYear },
      { student: "Sara Lynn", feeType: "Tuition", amount: 1000, class: "Grade 10", stream: "B", dueDate: "2023-07-10", paymentMethod: "Cheque", date: "2023-06-01", studyYear },
      { student: "Tom Harris", feeType: "Books", amount: 300, class: "Grade 12", stream: "A", dueDate: "2023-05-25", paymentMethod: "Bank Transfer", date: "2023-05-10", studyYear },
      { student: "Emily Carter", feeType: "Transport", amount: 250, class: "Grade 11", stream: "C", dueDate: "2023-04-15", paymentMethod: "Mobile Payment", date: "2023-04-01", studyYear },
    ],
    expenses: [
      { category: "Salaries", amount: 50000, studyYear },
      { category: "Infrastructure", amount: 30000, studyYear },
      { category: "Utilities", amount: 10000, studyYear },
      { category: "Miscellaneous", amount: 5000, studyYear },
      { category: "Maintenance", amount: 7000, studyYear },
    ],
  };
};

const Analytics = () => {
  const [studyYear, setStudyYear] = useState("2023");
  const [mockData, setMockData] = useState(generateMockData(studyYear));
  const [reportType, setReportType] = useState("summary");
  const [dateRange, setDateRange] = useState(null);

  // Generate data dynamically based on selected study year
  useEffect(() => {
    setMockData(generateMockData(studyYear));
  }, [studyYear]);

  // Utility to group data by a specific field
  const groupData = (key, data) =>
    data.reduce((acc, item) => {
      acc[item[key]] = (acc[item[key]] || 0) + item.amount;
      return acc;
    }, {});

  // Transformed Data
  const paymentsByFeeType = Object.entries(groupData("feeType", mockData.payments)).map(([name, value]) => ({ name, value }));
  const expensesByCategory = Object.entries(groupData("category", mockData.expenses)).map(([name, value]) => ({ name, value }));
  const paymentsByMethod = Object.entries(groupData("paymentMethod", mockData.payments)).map(([name, value]) => ({ name, value }));
  const paymentsByClass = Object.entries(groupData("class", mockData.payments)).map(([name, value]) => ({ name, value }));
  const paymentsByStream = Object.entries(groupData("stream", mockData.payments)).map(([name, value]) => ({ name, value }));

  // Overdue Payments Table Data
  const overduePayments = mockData.payments.filter((payment) => new Date(payment.dueDate) < new Date());

  // Download Report
  const downloadReport = () => {
    const worksheet = XLSX.utils.json_to_sheet(mockData.payments);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Payments Report");
    XLSX.writeFile(workbook, `${reportType}_Report.xlsx`);
  };

  return (
    <div className="analytics-container">
      {/* <h1 className="analytics-title">School Fees Analytics Dashboard</h1> */}

      {/* Study Year Selector */}
      <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
        <Col span={8}>
          <Select value={studyYear} onChange={setStudyYear} style={{ width: "100%" }}>
            <Option value="2023">2023</Option>
            <Option value="2024">2024</Option>
          </Select>
        </Col>
      </Row>

      {/* Report Selector */}
      <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
        <Col span={8}>
          <Select value={reportType} onChange={setReportType} style={{ width: "100%" }}>
            <Option value="summary">Summary Report</Option>
            <Option value="overdue">Overdue Payments</Option>
            <Option value="upcoming">Upcoming Due Dates</Option>
            <Option value="payments-vs-expenses">Payments vs. Expenses</Option>
            <Option value="payments-methods">Payments by Payment Method</Option>
          </Select>
        </Col>
        <Col span={8}>
          {["overdue", "payments-vs-expenses"].includes(reportType) && (
            <RangePicker onChange={setDateRange} style={{ width: "100%" }} />
          )}
        </Col>
        <Col span={8}>
          <Button type="primary" icon={<DownloadOutlined />} style={{ width: "100%" }} onClick={downloadReport}>
            Download {reportType} Report
          </Button>
        </Col>
      </Row>

      {/* Graphs and Charts */}
      <Row gutter={[16, 16]}>
        {/* Payments by Fee Type */}
        <Col span={12}>
          <Card>
            <h3>Payments by Fee Type</h3>
            <BarChart width={400} height={300} data={paymentsByFeeType}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
          </Card>
        </Col>

        {/* Expenses by Category */}
        <Col span={12}>
          <Card>
            <h3>Expenses by Category</h3>
            <PieChart width={400} height={300}>
              <Pie
                data={expensesByCategory}
                dataKey="value"
                cx="50%"
                cy="50%"
                outerRadius={100}
                fill="#82ca9d"
                label
              />
              <Tooltip />
            </PieChart>
          </Card>
        </Col>

        {/* Fee Collection by Payment Method */}
        <Col span={12}>
          <Card>
            <h3>Fee Collection by Payment Method</h3>
            <PieChart width={400} height={300}>
              <Pie
                data={paymentsByMethod}
                dataKey="value"
                cx="50%"
                cy="50%"
                outerRadius={100}
                fill="#ffc658"
                label
              />
              <Tooltip />
            </PieChart>
          </Card>
        </Col>

        {/* Overdue Payments Table */}
        <Col span={12}>
          <Card>
            <h3>Overdue Payments</h3>
            <Table
              dataSource={overduePayments}
              columns={[
                { title: "Student", dataIndex: "student" },
                { title: "Fee Type", dataIndex: "feeType" },
                { title: "Amount", dataIndex: "amount" },
                { title: "Due Date", dataIndex: "dueDate" },
              ]}
            />
          </Card>
        </Col>

        {/* Payments by Class */}
        <Col span={12}>
          <Card>
            <h3>Payments by Class</h3>
            <BarChart width={400} height={300} data={paymentsByClass}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#82ca9d" />
            </BarChart>
          </Card>
        </Col>

        {/* Payments by Stream */}
        <Col span={12}>
          <Card>
            <h3>Payments by Stream</h3>
            <BarChart width={400} height={300} data={paymentsByStream}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#ffc658" />
            </BarChart>
          </Card>
        </Col>

        {/* Upcoming Due Dates */}
        <Col span={12}>
          <Card>
            <h3>Upcoming Due Dates</h3>
            <BarChart width={400} height={300} data={paymentsByStream}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
          </Card>
        </Col>

        {/* Payments vs Expenses */}
        <Col span={12}>
          <Card>
            <h3>Payments vs Expenses</h3>
            <LineChart width={400} height={300} data={paymentsByStream}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="value" stroke="#8884d8" />
            </LineChart>
          </Card>
        </Col>

        {/* Payments Collected Over Time */}
        <Col span={12}>
          <Card>
            <h3>Payments Collected Over Time</h3>
            <AreaChart width={400} height={300} data={paymentsByStream}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
            </AreaChart>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Analytics;
