// reducers/classReducer.js

import { CREATE_SCHOOL_FAILURE, CREATE_SCHOOL_REQUEST, CREATE_SCHOOL_SUCCESS, CREATE_STUDENT_FAILURE, CREATE_STUDENT_REQUEST, CREATE_STUDENT_SUCCESS, EDIT_CLASS_SUCCESS, EDIT_STUDENT_FAILURE, EDIT_STUDENT_REQUEST, EDIT_STUDENT_SUCCESS, FETCH_CLASSES_FAILURE, FETCH_CLASSES_REQUEST, FETCH_CLASSES_SUCCESS, FETCH_SCHOOLS_FAILURE, FETCH_SCHOOLS_REQUEST, FETCH_SCHOOLS_SUCCESS, FETCH_STUDENTS_FAILURE, FETCH_STUDENTS_REQUEST, FETCH_STUDENTS_SUCCESS, UPDATE_SCHOOL_FAILURE, UPDATE_SCHOOL_REQUEST, UPDATE_SCHOOL_SUCCESS } from "../actions/Types";

const initialState = {
    activeClasses: [],
    classes: [],
    students: [],
    schoolList: [],
    loading: false,
    error: null,
};

const SchoolReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CREATE_CLASS_SUCCESS':
            return {
                ...state,
                activeClasses: [...state.activeClasses, action.payload],
            };

        case EDIT_CLASS_SUCCESS:
            return {
                ...state,
                activeClasses: state.activeClasses?.map(cls =>
                    cls.id === action.payload.id ? action.payload : cls
                ),
            };

        case FETCH_CLASSES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_CLASSES_SUCCESS:
            return {
                ...state,
                loading: false,
                classes: action.payload,
            };
        case FETCH_CLASSES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };


               // Fetch Students Actions
    case FETCH_STUDENTS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case FETCH_STUDENTS_SUCCESS:
        return {
          ...state,
          loading: false,
          students: action.payload,
        };
  
      case FETCH_STUDENTS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      // Create Student Actions
      case CREATE_STUDENT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case CREATE_STUDENT_SUCCESS:
        return {
          ...state,
          loading: false,
          students: [...state.students], // Add the new student to the list
        //   students: [...state.students, action.payload], // Add the new student to the list
        };
  
      case CREATE_STUDENT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      // Edit Student Actions
      case EDIT_STUDENT_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case EDIT_STUDENT_SUCCESS:
        return {
          ...state,
          loading: false,
        //   students: state.students?.map(student =>
        //     student.id === action.payload.id ? action.payload : student
        //   ),
        };
  
      case EDIT_STUDENT_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };


        case FETCH_SCHOOLS_REQUEST:
          case CREATE_SCHOOL_REQUEST:
          case UPDATE_SCHOOL_REQUEST:
            return {
              ...state,
              loading: true,
              error: null,
            };
      
          case FETCH_SCHOOLS_SUCCESS:
            return {
              ...state,
              schoolList: action.payload,
              loading: false,
            };
      
          case CREATE_SCHOOL_SUCCESS:
            return {
              ...state,
              // schoolList: [action.payload, ...state.schoolList],
              loading: false,
            };
      
          case UPDATE_SCHOOL_SUCCESS:
            return {
              ...state,
              // schoolList: state.schoolList.map((school) =>
              //   school.id === action.payload.id ? { ...school, ...action.payload } : school
              // ),
              loading: false,
            };
      
          case FETCH_SCHOOLS_FAILURE:
          case CREATE_SCHOOL_FAILURE:
          case UPDATE_SCHOOL_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.payload,
            };

        default:
            return state;
    }
};

export default SchoolReducer;

