import {combineReducers} from "redux";


import ActivitiesReducer from "./ActivitiesReducer";
import AuthReducer from "./AuthReducer";
import studyYearReducer from "./StudyYearsReducer";
import SchoolReducer from "./SchoolReducer";
import FeesReducer from "./FeesReducer";
import AllocationsReducer from "./AllocationsReducer";
import PaymentsReducer from "./PaymentsReducer";
import UserReducer from "./UserReducer";
import ExpensesReducer from "./ExpensesReducer";
import StudentsReducer from "./StudentsReducer";


export default combineReducers({
    activities: ActivitiesReducer,
    auth: AuthReducer,
    studyYear: studyYearReducer,
    school: SchoolReducer,
    fees: FeesReducer,
    expenses: ExpensesReducer,
    allocations: AllocationsReducer,
    payments: PaymentsReducer,
    students: StudentsReducer,
    user: UserReducer,
});