import React, { useEffect, useState } from 'react';
import { Table, Button, Form, Input, Select, DatePicker, Modal, Typography, Upload, message, Breadcrumb } from 'antd';
import { DashboardOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';

import { createStudent, editStudent, fetchClasses, fetchStudents } from '../../actions/SchoolActions';
import { fetchActiveStudyYears } from '../../actions/StudyYearActions';
import { Link } from 'react-router-dom';

const { Title } = Typography;

const StudentsContainer = styled.div`
  padding: 20px;
`;

const StyledButton = styled(Button)`
  margin-bottom: 16px;
`;

const Students = () => {
  const dispatch = useDispatch();
  const activeYears = useSelector(state => state.studyYear.activeYears);
  const classes = useSelector(state => state.school.classes);
  const students = useSelector(state => state.school.students);
  const loading = useSelector(state => state.school.loading);

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedStream, setSelectedStream] = useState(null);
  const [editingStudent, setEditingStudent] = useState(null);
  const [contacts, setContacts] = useState([{ name: '', relation: '', contactNumber: '' }]);
  const [entryType, setEntryType] = useState('manual'); // Track entry type

  // useEffect(() => {
  //   // dispatch(fetchActiveStudyYears());
  //   // dispatch(fetchClasses());
  //   // dispatch(fetchStudents());
  // }, [dispatch]);

  // useEffect(() => {
  //   const unsubscribe = dispatch(fetchClasses());

  //   // Clean up listener on component unmount
  //   return () => unsubscribe && unsubscribe();
  // }, [dispatch]);

  useEffect(() => {
    setSelectedClass(null)
  }, [selectedYear]);

  useEffect(() => {
    setSelectedStream(null)
  }, [selectedClass]);



  const handleCreateOrEditStudent = (values) => {

    // {editingStudent ? 'Update Student' : 'Register Student'}

    if (!editingStudent) {
      if (!selectedYear || !selectedClass || !selectedStream) {
        message.error('Please select Study Year, Class, and Stream.');
        return;
      }
    }

    const regData = {
      classes: [{
        year: selectedYear,
        class: selectedClass,
        stream: selectedStream
      }],
      dateRegistered: dayjs().valueOf(),
      time: dayjs().valueOf(),
    }

    const studentData = {
      ...values,
      dob: values.dob ? values.dob.valueOf() : null,
      dateOfAdmission: values.dateOfAdmission ? values.dateOfAdmission.valueOf() : null,
      contacts: contacts.filter(contact => contact.name && contact.contactNumber), // Filter valid contacts
    };

    if (editingStudent) {
      dispatch(editStudent({ id: editingStudent.id, ...studentData }));
    } else {
      dispatch(createStudent({ ...studentData, ...regData }));
    }

    form.resetFields();
    setVisible(false);
    setEditingStudent(null);
    setContacts([{ name: '', relation: '', contactNumber: '' }]);
  };

  const handleExcelUpload = (file) => {
    if (!selectedYear || !selectedClass || !selectedStream) {
      message.error('Please select Study Year, Class, and Stream before uploading.');
      return false;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);

      const regData = {
        classes: [{
          year: selectedYear,
          class: selectedClass,
          stream: selectedStream
        }],
        dateRegistered: dayjs().valueOf(),
      }

      parsedData.forEach((row) => {
        const studentData = {
          // year: selectedYear,
          // classStream: selectedStream,
          name: row.Name,
          regNumber: row.RegNumber,
          gender: row.Gender,
          dob: row.DOB ? dayjs(row.DOB, 'YYYY-MM-DD').valueOf() : null,
          dateOfAdmission: row.DateOfAdmission ? dayjs(row.DateOfAdmission, 'YYYY-MM-DD').valueOf() : null,
          contacts: [
            { name: row.ContactName1, relation: row.Relation1, contactNumber: row.ContactNumber1 },
            { name: row.ContactName2, relation: row.Relation2, contactNumber: row.ContactNumber2 },
          ].filter(contact => contact.name && contact.contactNumber),
        };
        dispatch(createStudent({ ...studentData, ...regData }));
      });

      message.success('Students added successfully from Excel.');
    };
    reader.readAsArrayBuffer(file);
    return false; // Prevent default upload behavior
  };

  const handleAddContact = () => {
    if (contacts.length < 2) {
      setContacts([...contacts, { name: '', relation: '', contactNumber: '' }]);
    }
  };

  const handleRemoveContact = (index) => {
    setContacts(contacts.filter((_, i) => i !== index));
  };

  const handleContactChange = (index, field, value) => {
    const newContacts = [...contacts];
    newContacts[index][field] = value;
    setContacts(newContacts);
  };

  const handleEditStudent = (student) => {
    setEditingStudent(student);
    form.setFieldsValue({
      name: student.name || '',
      regNumber: student.regNumber || '',
      gender: student.gender || null,
      dob: student.dob ? dayjs(student.dob) : null,
      dateOfAdmission: student.dateOfAdmission ? dayjs(student.dateOfAdmission) : null,
    });
    setContacts(student.contacts || [{ name: '', relation: '', contactNumber: '' }]);
    setVisible(true);
  };

  const handleCloseModal = () => {
    setVisible(false);
    setEditingStudent(null);
    form.resetFields();
    setContacts([{ name: '', relation: '', contactNumber: '' }]);
  };

  const columns = [
    {
      title: 'S/No',
      dataIndex: 'serial',
      key: 'serial',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Reg Number',
      dataIndex: 'regNumber',
      key: 'regNumber',
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
    },
    {
      title: 'DOB',
      dataIndex: 'dob',
      key: 'dob',
      render: (dob) => dob ? dayjs(dob).format('YYYY-MM-DD') : 'N/A',
    },
    // {
    //   title: 'Date Registered',
    //   dataIndex: 'dateRegistered',
    //   key: 'dateRegistered',
    //   render: (date) => dayjs(date).format('YYYY-MM-DD'),
    // },
    {
      title: 'Date Joined', dataIndex: 'dateOfAdmission', key: 'dateOfAdmission',
      render: (dateOfAdmission) => dayjs(dateOfAdmission).format('YYYY-MM-DD') || 'N/A',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button type="link" onClick={() => handleEditStudent(record)}>
          Edit
        </Button>
      ),
    },
  ];

  return (
    <StudentsContainer>
      {/* <Title level={3}>Student Registration</Title> */}

      <Breadcrumb
                style={{ marginBottom: 10 }}
                items={[
                    {
                        title: <Link to='/'><DashboardOutlined /></Link>,
                    },
                    {
                        title: "Students",
                    },
                    {
                        title: 'Student Registration'
                    },

                ]}
            />

      <Select
        placeholder="Select Study Year"
        value={selectedYear}
        onChange={setSelectedYear}
        options={activeYears.map((year) => ({ label: year.name, value: year.id }))}
        style={{ width: 200, marginBottom: 16, marginLeft: 4, marginRight: 4 }}
      />

      <Select
        placeholder="Select Class"
        allowClear
        value={selectedClass}
        onChange={setSelectedClass}
        disabled={!selectedYear}
        options={
          classes
            .filter((classItem) => (classItem.yearID === selectedYear))
            .map((classItem) => ({ label: classItem.className, value: classItem.id }))
        }
        style={{ width: 200, marginBottom: 16, marginLeft: 4, marginRight: 4 }}
      />

      <Select
        placeholder="Select Stream"
        allowClear
        value={selectedStream}
        onChange={setSelectedStream}
        options={classes.find((classItem) => classItem.id === selectedClass)?.streams.map((stream) => ({
          label: stream,
          value: stream,
        })) || []}
        style={{ width: 200, marginBottom: 16, marginLeft: 4, marginRight: 4 }}
      />

      <Select
        value={entryType}
        onChange={(value) => setEntryType(value)}
        style={{ width: 200, marginBottom: 16, marginLeft: 4, marginRight: 4 }}
        options={[
          { label: 'Manual Entry', value: 'manual' },
          { label: 'Bulk Upload (Excel)', value: 'excel' },
        ]}
      />

      {entryType === 'manual' ? (
        <StyledButton
          style={{ marginBottom: 16, marginLeft: 4, marginRight: 4 }}
          type="primary"
          onClick={() => setVisible(true)}
          loading={loading}
          disabled={loading}
        >
          Register Student
        </StyledButton>
      ) : (
        <Upload
          accept=".xlsx, .xls"
          beforeUpload={handleExcelUpload}
          showUploadList={false}

        >
          <Button
            style={{ marginBottom: 16, marginLeft: 4, marginRight: 4 }}
            icon={<UploadOutlined />}
          >
            Upload Excel File
          </Button>
        </Upload>
      )}


{/* <Title level={3}>Students</Title> */}

      <Table
        dataSource={students.sort((a,b)=>b.dateRegistered - a.dateRegistered).map((student, index) => ({ ...student, key: index }))}
        columns={columns}
        pagination={{pageSize:10}}
        bordered
      />

      {/* Modal for Manual Entry */}
      <Modal
        title={editingStudent ? 'Edit Student' : 'Register Student'}
        visible={visible}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Form
          form={form}
          onFinish={handleCreateOrEditStudent}
          layout="vertical"
        >
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter the student\'s name' }]}>
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item name="regNumber" label="Registration Number" rules={[{ required: true, message: 'Please enter the registration number' }]}>
            <Input placeholder="Registration Number" />
          </Form.Item>
          <Form.Item name="gender" label="Gender" rules={[{ required: true, message: 'Please select gender' }]}>
            <Select placeholder="Select Gender">
              <Select.Option value="Male">Male</Select.Option>
              <Select.Option value="Female">Female</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="dob" label="Date of Birth">
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="dateOfAdmission" label="Date of Admission">
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>

          {/* Contacts Section */}
          <Form.Item label="Contacts">
            {contacts.map((contact, index) => (
              <div key={index} style={{ marginBottom: 16 }}>
                <Input placeholder="Contact Name" value={contact.name} onChange={(e) => handleContactChange(index, 'name', e.target.value)} style={{ marginBottom: 8 }} />
                <Input placeholder="Relation" value={contact.relation} onChange={(e) => handleContactChange(index, 'relation', e.target.value)} style={{ marginBottom: 8 }} />
                <Input placeholder="Contact Number" value={contact.contactNumber} onChange={(e) => handleContactChange(index, 'contactNumber', e.target.value)} style={{ marginBottom: 8 }} />
                <Button onClick={() => handleRemoveContact(index)} disabled={contacts.length <= 1}>Remove Contact</Button>
              </div>
            ))}
            <Button onClick={handleAddContact} disabled={contacts.length >= 2}>Add Another Contact</Button>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              {editingStudent ? 'Update Student' : 'Register Student'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </StudentsContainer>
  );
};

export default Students;
