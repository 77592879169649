// allocationReducer.js

import {
    FETCH_ACTIVE_ALLOCATIONS_START,
    FETCH_ACTIVE_ALLOCATIONS_SUCCESS,
    FETCH_ACTIVE_ALLOCATIONS_ERROR,
    SAVE_ALLOCATION_START,
    SAVE_ALLOCATION_SUCCESS,
    SAVE_ALLOCATION_ERROR,
    DEACTIVATE_ALLOCATION_START,
    DEACTIVATE_ALLOCATION_SUCCESS,
    DEACTIVATE_ALLOCATION_ERROR,
  } from '../actions/Types';
  
  const initialState = {
    activeAllocations: [],
    loading: false,
    error: null,
  };
  
   const AllocationsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ACTIVE_ALLOCATIONS_START:
      case SAVE_ALLOCATION_START:
      case DEACTIVATE_ALLOCATION_START:
        return { ...state, loading: true, error: null };
  
      case FETCH_ACTIVE_ALLOCATIONS_SUCCESS:
        return { ...state, activeAllocations: action.payload, loading: false };
  
      case SAVE_ALLOCATION_SUCCESS:
        return {
          ...state,
          activeAllocations: state.activeAllocations.some(allocation => allocation.id === action.payload.groupID)
            ? state.activeAllocations.map(allocation =>
                allocation.id === action.payload.groupID ? action.payload : allocation)
            : [...state.activeAllocations, action.payload],
          loading: false,
        };
  
      case DEACTIVATE_ALLOCATION_SUCCESS:
        return {
          ...state,
          activeAllocations: state.activeAllocations.filter(allocation => allocation.id !== action.payload),
          loading: false,
        };
  
      case FETCH_ACTIVE_ALLOCATIONS_ERROR:
      case SAVE_ALLOCATION_ERROR:
      case DEACTIVATE_ALLOCATION_ERROR:
        return { ...state, loading: false, error: action.error };
  
      default:
        return state;
    }
  };

  export default AllocationsReducer;
  