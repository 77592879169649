import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, Typography, Breadcrumb } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// import { fetchPaymentSystems, createPaymentSystem, updatePaymentSystem } from '../../actions/PaymentSystemsActions'; // Adjust imports as necessary
import styled from 'styled-components';
import { createPaymentSystem, fetchPaymentSystems, updatePaymentSystem } from '../../actions/PaymentActions';

import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { DashboardOutlined } from '@ant-design/icons';

const { Title } = Typography;

const PaymentSystemsContainer = styled.div`
  padding: 20px;
`;

const StyledButton = styled(Button)`
  margin-bottom: 16px;
`;

const PaymentSystems = () => {
  const dispatch = useDispatch();
  const paymentSystems = useSelector(state => state.payments.paymentSystems); // Assume your Redux state has paymentSystems
  const loading = useSelector(state => state.payments.loading); // Assume your Redux state has loading status
  const error = useSelector(state => state.payments.error); // Assume your Redux state has errors
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [editingPaymentSystem, setEditingPaymentSystem] = useState(null);
  const [dataSource, setDataSource] = useState([]);

  // useEffect(() => {
  //   const unsubscribe = dispatch(fetchPaymentSystems());

  //   // Clean up listener on component unmount
  //   return () => unsubscribe && unsubscribe();
  // }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const source = paymentSystems
      ?.sort((a, b) => b.dateCreated - a.dateCreated)
      // ?.filter(paymentSystem => paymentSystem.active !== false) // Only display active payment systems
      ?.map((paymentSystem, index) => ({
        ...paymentSystem,
        key: paymentSystem.id, // Assuming paymentSystem has a unique id
      })) || [];

    setDataSource(source);
  }, [paymentSystems]);

  const handleOpenModal = () => {
    setVisible(true);
    form.resetFields();
    setEditingPaymentSystem(null);
  };

  const handleCloseModal = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleCreateOrUpdatePaymentSystem = async (values) => {
    if (editingPaymentSystem) {
      // Update existing payment system
      await dispatch(updatePaymentSystem({ ...values, id: editingPaymentSystem.id }));
    } else {
      // Create new payment system
      await dispatch(createPaymentSystem({
        ...values,
        dateCreated: dayjs().valueOf(),
        time: dayjs().valueOf(),
        active: true
      }));
    }
    handleCloseModal();
  };

  const handleEditPaymentSystem = (paymentSystem) => {
    setEditingPaymentSystem(paymentSystem);
    form.setFieldsValue({ systemName: paymentSystem.systemName, description: paymentSystem.description });
    setVisible(true);
  };

  const handleDeletePaymentSystem = async (id) => {
    // Mark payment system as inactive (soft delete)
    await dispatch(updatePaymentSystem({ id, active: false }));
  };

  const handleActivatePaymentSystem = async (id) => {
    // Mark payment system as inactive (soft delete)
    await dispatch(updatePaymentSystem({ id, active: true }));
  };

  const columns = [
    {
      title: 'S/No',
      dataIndex: 'key',
      key: 'key',
      render: (_, __, index) => index + 1, // Serial number
    },
    {
      title: 'Payment System Name',
      dataIndex: 'systemName',
      key: 'systemName',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      render: (active) => (active ? 'Active' : 'Disabled'),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <>
          <Button type="link" onClick={() => handleEditPaymentSystem(record)}>Edit</Button>
          {
            record?.active &&
            <Button type="link" danger onClick={() => handleDeletePaymentSystem(record.id)}>Disable</Button>
          }
          {
            !record?.active &&
            <Button type="link" onClick={() => handleActivatePaymentSystem(record.id)}>Enable</Button>
          }
        </>
      ),
    },
  ];

  return (
    <PaymentSystemsContainer>
       <Breadcrumb
                style={{ marginBottom: 10 }}
                items={[
                    {
                        title: <Link to='/'><DashboardOutlined /></Link>,
                    },
                    {
                        title: "Fee Settings",
                    },
                    {
                        title: 'Payment Systems'
                    },

                ]}
            />
      <StyledButton type="primary" onClick={handleOpenModal} loading={loading} disabled={loading}>
        Add Payment System
      </StyledButton>

      <Table
        dataSource={dataSource} // Pass filtered dataSource
        columns={columns}
        // pagination={false}
        pagination={{ pageSize: 10 }}
        rowKey="id"
        bordered
      />

      <Modal
        title={editingPaymentSystem ? 'Edit Payment System' : 'Add Payment System'}
        visible={visible}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Form
          form={form}
          onFinish={handleCreateOrUpdatePaymentSystem}
          layout="vertical"
        >
          <Form.Item
            name="systemName"
            label="Payment System Name"
            rules={[{ required: true, message: 'Please enter the payment system name' }]}
          >
            <Input placeholder="Enter payment system name" />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: 'Please enter a description' }]}
          >
            <Input.TextArea rows={4} placeholder="Enter description" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingPaymentSystem ? 'Update Payment System' : 'Create Payment System'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </PaymentSystemsContainer>
  );
};

export default PaymentSystems;
