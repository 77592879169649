import { FETCH_USER_FAILURE, FETCH_USER_REQUEST, FETCH_USER_SUCCESS, LOGOUT, TOGGLE_THEME, UPDATE_USER_FAILURE, UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS } from "../actions/Types";

const initialState = {
  user: null,
  users: [],
  userLoading: true,
  loading: false,
  error: null,
  userError: null,
  success: false,

  theme: localStorage.getItem("theme")
    ? JSON.parse(localStorage.getItem("theme"))
    : { isLightTheme: true }, // Default theme
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {

    case TOGGLE_THEME:
      const updatedTheme = { ...state.theme, isLightTheme: !state.theme.isLightTheme };
      localStorage.setItem("theme", JSON.stringify(updatedTheme)); // Persist the theme
      return { ...state, theme: updatedTheme };


    case LOGOUT:
      return {
        ...state,
        user: null,
        userError: null,
      };

    case 'REGISTER_USER_REQUEST':
      return { ...state, loading: true, error: null, success: false };
    case 'REGISTER_USER_SUCCESS':
      return { ...state, loading: false, success: true };
    case 'REGISTER_USER_FAILURE':
      return { ...state, loading: false, error: action.payload };

    case FETCH_USER_REQUEST:
      return {
        ...state,
        // userLoading: true,
        // user: null,
        userError: null,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        userLoading: false,
        user: action.payload,
      };
    case FETCH_USER_FAILURE:
      return {
        ...state,
        userLoading: false,
        userError: action.payload,
      };

    case UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };


    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    case 'FETCH_USERS_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'FETCH_USERS_SUCCESS':
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    case 'FETCH_USERS_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case 'UPDATE_USER_ROLE_REQUEST':
    case 'TOGGLE_USER_STATUS_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'UPDATE_USER_ROLE_SUCCESS':
      return {
        ...state,
        loading: false,
        // users: state.users.map(user =>
        //   user.uid === action.payload.userId
        //     ? { ...user, role: action.payload.newRole }
        //     : user
        // ),
      };
    case 'TOGGLE_USER_STATUS_SUCCESS':
      return {
        ...state,
        loading: false,
        // users: state.users.map(user =>
        //   user.uid === action.payload.userId
        //     ? { ...user, active: action.payload.isActive }
        //     : user
        // ),
      };
    case 'UPDATE_USER_ROLE_FAILURE':
    case 'TOGGLE_USER_STATUS_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default UserReducer;