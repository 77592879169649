import React, { useState, useEffect, lazy, Suspense } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

import logo from './assets/swillaLogo.png';
import { auth } from './config/firebase';
import Login from './components/auth/Login';
import Dashboard from './components/dashboard/Dashboard';
import AppLayout from './components/layout/AppLayout';
import StudyYears from './components/school/StudyYears';
import ClassesAndStreams from './components/school/ClassesAndStreams';
import FeeAllocations from './components/fees/FeeAllocations';
import FeeTypes from './components/fees/FeeTypes';
import FeeGroups from './components/fees/FeeGroups';
import CollectPayments from './components/payments/CollectPayments';
import Analytics from './components/analytics/Analytics';
import RegisterUser from './components/admin/RegisterUser';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, fetchUsers } from './actions/UserActions';
import ManageUsers from './components/admin/ManageUsers';
import User from './components/profile/User';
import ChangePassword from './components/profile/ChangePassword';
import History from './components/payments/History';
import ExpensesHistory from './components/expenses/ExpensesHistory';
import RecordExpenses from './components/expenses/RecordExpenses';
import ExpenseTypes from './components/expenses/ExpenseTypes';
import Students from './components/students/Students';
import ChangeClass from './components/students/ChangeClass';
import PromoteClass from './components/students/PromoteClass';
import CollectPastPayments from './components/payments/CollectPastPayments';
import { fetchClasses, fetchSchools, fetchStudents } from './actions/SchoolActions';
import { fetchAllStudyYears } from './actions/StudyYearActions';
import { fetchActiveFeeGroups, fetchActiveFeeTypes } from './actions/FeesActions';
import { fetchPaymentSystems } from './actions/PaymentActions';
import PaymentSystems from './components/fees/PaymentSystems';
import { fetchAllActiveAllocations } from './actions/AllocationActions';
import ProtectedRoutes from './components/protectedRoutes/ProtectedRoutes';
import Unauthorized from './components/unauthorized/Unauthorized';
import NotFound from './components/notFound/NotFound';
import { FETCH_USER_FAILURE } from './actions/Types';
import Schools from './components/school/Schools';

// const Home = lazy(() => import('./components/home/Home'));

const App = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);
  const loading = useSelector((state) => state.user.userLoading);


  const isLightTheme = useSelector((state) => state.user.theme.isLightTheme);

   
    useEffect(() => {
        const newTheme = isLightTheme ? "light" : "dark";
    
        document.documentElement.setAttribute("data-theme", newTheme);
      }, [isLightTheme]);


  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, (currentUser) => {

  //     if (currentUser) {
  //       dispatch(fetchUser(currentUser.uid));

  //       //fetching necessary data and according to roles here
  //       dispatch(fetchStudents());
  //       dispatch(fetchAllStudyYears());
  //       dispatch(fetchUsers());
  //       dispatch(fetchActiveFeeTypes());
  //       dispatch(fetchClasses());
  //       dispatch(fetchPaymentSystems());
  //       dispatch(fetchActiveFeeGroups());
  //       dispatch(fetchAllActiveAllocations());

  //       setUser(currentUser);
  //       setIsCheckingAuth(false);
  //       return;
  //     }
  //     setUser(null);
  //     setIsCheckingAuth(false);
  //   });

  //   return () => unsubscribe();
  // }, []);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        // Fetch user details from Firestore or your database
        dispatch(fetchUser(authUser?.uid));

        // console.log(authUser.uid)
        //fetching necessary data and according to roles here
        dispatch(fetchStudents());
        dispatch(fetchAllStudyYears());
        dispatch(fetchUsers());
        dispatch(fetchActiveFeeTypes());
        dispatch(fetchClasses());
        dispatch(fetchPaymentSystems());
        dispatch(fetchActiveFeeGroups());
        dispatch(fetchAllActiveAllocations());
        dispatch(fetchSchools());

      } else {
        dispatch({
          type: FETCH_USER_FAILURE,
          payload: "No user authenticated",
        });
      }
    });

    return () => unsubscribe(); // Cleanup on unmount
  }, [dispatch]);

  // console.log(user)


  // if (
  //   isCheckingAuth
  // ) {
  //   <div className='center-placeholder'>
  //     <img src={logo} alt='logo' />
  //   </div>
  // }

  const DashboardComponent = () => (
    <AppLayout>
      <Dashboard />
    </AppLayout>
  );
  const ChangePasswordComponent = () => (
    <AppLayout>
      <ChangePassword />
    </AppLayout>
  );
  const UserComponent = () => (
    <AppLayout>
      <User />
    </AppLayout>
  );
  const UnauthorizedComponent = () => (
    <AppLayout>
      <Unauthorized />
    </AppLayout>
  );
  const CollectPaymentsComponent = () => (
    <AppLayout>
      <CollectPayments />
    </AppLayout>
  );
  const CollectPastPaymentsComponent = () => (
    <AppLayout>
      <CollectPastPayments />
    </AppLayout>
  );
  const HistoryComponent = () => (
    <AppLayout>
      <History />
    </AppLayout>
  );
  const AnalyticsComponent = () => (
    <AppLayout>
      <Analytics />
    </AppLayout>
  );
  const StudentsComponent = () => (
    <AppLayout>
      <Students />
    </AppLayout>
  );
  const ExpensesHistoryComponent = () => (
    <AppLayout>
      <ExpensesHistory />
    </AppLayout>
  );
  const RecordExpensesComponent = () => (
    <AppLayout>
      <RecordExpenses />
    </AppLayout>
  );
  const ChangeClassComponent = () => (
    <AppLayout>
      <ChangeClass />
    </AppLayout>
  );
  const PromoteClassComponent = () => (
    <AppLayout>
      <PromoteClass />
    </AppLayout>
  );
  const ManageUsersComponent = () => (
    <AppLayout>
      <ManageUsers />
    </AppLayout>
  );
  const ExpenseTypesComponent = () => (
    <AppLayout>
      <ExpenseTypes />
    </AppLayout>
  );
  const FeeTypesComponent = () => (
    <AppLayout>
      <FeeTypes />
    </AppLayout>
  );
  const FeeGroupsComponent = () => (
    <AppLayout>
      <FeeGroups />
    </AppLayout>
  );
  const PaymentSystemsComponent = () => (
    <AppLayout>
      <PaymentSystems />
    </AppLayout>
  );
  const FeeAllocationsComponent = () => (
    <AppLayout>
      <FeeAllocations />
    </AppLayout>
  );
  const ClassesAndStreamsComponent = () => (
    <AppLayout>
      <ClassesAndStreams />
    </AppLayout>
  );
  const StudyYearsComponent = () => (
    <AppLayout>
      <StudyYears />
    </AppLayout>
  );
  const SchoolsComponent = () => (
    <AppLayout>
      <Schools />
    </AppLayout>
  );
  const RegisterUserComponent = () => (
    <AppLayout>
      <RegisterUser />
    </AppLayout>
  );

  if (loading) {
    // Simulate a nice loading spinner
    return (
      <div className='center-placeholder'>
        <img src={logo} alt='logo' />
      </div>
    );
  }


  // const renderLogo = () => {
  //   return (
  //     <div className='center-placeholder'>
  //       <img src={logo} alt='logo' />
  //     </div>
  //   );
  // };
  // const renderLogin = () => {
  //   return (
  //     <div className="mainContainer">
  //       {/* <Suspense fallback={
  //         <div className='center-placeholder'>
  //           <img src={logo} alt='logo' />
  //         </div>
  //       }> */}
  //       <Routes>
  //         <Route path="*" element={<Login />} />
  //       </Routes>
  //       {/* </Suspense> */}
  //     </div>
  //   );
  // };

  // const renderAccess = () => {

  return (
    <BrowserRouter>
      {/* <AppLayout> */}
      <Suspense fallback={
        <div className='center-placeholder'>
          <img src={logo} alt='logo' />
        </div>
      }>
        <Routes>
          {/* Public Routes */}
          {/* {
            !user && */}
          <Route path="/login" element={<Login />} />
          {/*  } */}
          {/* Protected Routes */}
          <Route element={<ProtectedRoutes allowedRoles={["cashier", "accountant", "board", "admin"]} />}>
            <Route path='/' element={<DashboardComponent />} />
            <Route path='/change-password' element={<ChangePasswordComponent />} />
            <Route path='/profile' element={<UserComponent />} />
            <Route path="/unauthorized" element={<UnauthorizedComponent />} />
          </Route>

          <Route element={<ProtectedRoutes allowedRoles={["cashier", "accountant", "admin"]} />}>
            <Route path='/collect-payments' element={<CollectPaymentsComponent />} />
            <Route path='/collect-past-payments' element={<CollectPastPaymentsComponent />} />
            <Route path='/payments-history' element={<HistoryComponent />} />
          </Route>

          <Route element={<ProtectedRoutes allowedRoles={["accountant", "board", "admin"]} />}>
            <Route path='/analytics' element={<AnalyticsComponent />} />
          </Route>

          <Route element={<ProtectedRoutes allowedRoles={["accountant", "admin"]} />}>
            <Route path='/students' element={<StudentsComponent />} />
            <Route path='/expenses-history' element={<ExpensesHistoryComponent />} />
            <Route path='/record-expenses' element={<RecordExpensesComponent />} />
          </Route>

          <Route element={<ProtectedRoutes allowedRoles={["board", "admin"]} />}>
          </Route>

          <Route element={<ProtectedRoutes allowedRoles={["admin"]} />}>
            <Route path='/update-student-classes' element={<ChangeClassComponent />} />
            <Route path='/promote-student-classes' element={<PromoteClassComponent />} />
            <Route path='/manage-users' element={<ManageUsersComponent />} />
            <Route path='/expense-types' element={<ExpenseTypesComponent />} />
            <Route path='/fee-types' element={<FeeTypesComponent />} />
            <Route path='/fee-groups' element={<FeeGroupsComponent />} />
            <Route path='/payment-systems' element={<PaymentSystemsComponent />} />
            <Route path='/allocation' element={<FeeAllocationsComponent />} />
            <Route path='/classes-and-streams' element={<ClassesAndStreamsComponent />} />
            <Route path='/study-years' element={<StudyYearsComponent />} />
            <Route path='/schools' element={<SchoolsComponent />} />
            <Route path='/register-user' element={<RegisterUserComponent />} />
          </Route>

          <Route element={<ProtectedRoutes allowedRoles={["cashier", "accountant", "board", "admin"]} />}>
            {/* Catch-All Route */}
            <Route path="*" element={<NotFound />} />
          </Route>

        </Routes>
      </Suspense>
      {/* </AppLayout> */}
    </BrowserRouter>

  );
  // };






  // return (
  //   <BrowserRouter>
  //     {
  //       user ?
  //         // renderAccess()
  //         <AppLayout>
  //           {renderAccess()} {/* Pass the rendered access here */}
  //         </AppLayout>
  //         :
  //         renderLogin()
  //     }
  //   </BrowserRouter>
  // );


};

export default App;
