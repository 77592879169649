import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Button, Form, Input, Modal, Space, Typography, message, Breadcrumb } from 'antd';
import { createExpenseType, deleteExpenseType, fetchExpenseTypes, updateExpenseType } from '../../actions/ExpensesActions';
// import { fetchExpenseTypes, createExpenseType, updateExpenseType, deleteExpenseType } from '../../actions/expenseActions'; // Replace with actual paths

import dayjs from 'dayjs';
import { DashboardOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { TextArea } = Input;

const ExpenseTypes = () => {
  const dispatch = useDispatch();
  const { expenseTypes, loading } = useSelector((state) => state.expenses); // Replace with actual reducer

  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingExpense, setEditingExpense] = useState(null);

  useEffect(() => {
    dispatch(fetchExpenseTypes());
  }, [dispatch]);

  // Handle modal open/close
  const handleOpenModal = (expense = null) => {
    setEditingExpense(expense);
    setIsModalVisible(true);
    if (expense) {
      form.setFieldsValue(expense);
    } else {
      form.resetFields();
    }
  };

  const handleCloseModal = () => {
    setEditingExpense(null);
    setIsModalVisible(false);
    form.resetFields();
  };

  // Handle form submission for creating/updating
  const handleSubmit = (values) => {
    if (editingExpense) {
      dispatch(updateExpenseType({ ...editingExpense, ...values }))
        .then(() => {
          message.success('Expense type updated successfully!');
          handleCloseModal();
        })
        .catch(() => message.error('Failed to update expense type.'));
    } else {
      dispatch(createExpenseType({
        ...values,
        time: dayjs().valueOf()
      }))
        .then(() => {
          message.success('Expense type created successfully!');
          handleCloseModal();
        })
        .catch(() => message.error('Failed to create expense type.'));
    }
  };

  // Handle delete action
  const handleDelete = (id) => {
    dispatch(deleteExpenseType(id))
      .then(() => message.success('Expense type deleted successfully!'))
      .catch(() => message.error('Failed to delete expense type.'));
  };

  // Define table columns
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record) => (
        <Space size="middle">
          <Button type="link" onClick={() => handleOpenModal(record)}>
            Edit
          </Button>
          <Button type="link" danger onClick={() => handleDelete(record.id)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const activeExpenseTypes = expenseTypes.filter((expense) => expense.active);

  return (
    <div style={{ padding: 20 }}>
         <Breadcrumb
                style={{ marginBottom: 10 }}
                items={[
                    {
                        title: <Link to='/'> <DashboardOutlined /></Link>,
                    },
                    {
                        title: 'Expense Settings'
                    },
                    {
                        title: 'Expense Types',
                    },
                ]}
            />
      {/* <Typography.Title level={3}>Expense Types</Typography.Title> */}

      {/* Form to add/edit expense types */}
      <Button type="primary" onClick={() => handleOpenModal()} style={{ marginBottom: 16 }}>
        Add New Expense Type
      </Button>

      <Table
        dataSource={activeExpenseTypes}
        columns={columns}
        loading={loading}
        rowKey="id"
        bordered
        pagination={{ pageSize: 10 }}
      />

      {/* Modal for add/edit form */}
      <Modal
        title={editingExpense ? 'Edit Expense Type' : 'Add New Expense Type'}
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please enter the expense type name' }]}
          >
            <Input placeholder="Enter expense type name" />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: 'Please enter a description' }]}
          >
            <TextArea rows={4} placeholder="Enter description" />
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                {editingExpense ? 'Update' : 'Create'}
              </Button>
              <Button onClick={handleCloseModal}>Cancel</Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ExpenseTypes;
