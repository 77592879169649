import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, message, Tag, Tooltip, Breadcrumb } from 'antd';
import { deleteExpenseRecord, fetchExpenseRecords, fetchExpenseTypes } from '../../actions/ExpensesActions';
// import { fetchExpenseRecords, deleteExpenseRecord } from '../../actions/expenseActions'; // Replace with actual paths
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { DashboardOutlined } from '@ant-design/icons';
const ExpensesHistory = () => {
  const dispatch = useDispatch();
  const { expenseRecords, expenseTypes, loading } = useSelector((state) => state.expenses);

  useEffect(() => {
    dispatch(fetchExpenseRecords());
    dispatch(fetchExpenseTypes());
  }, [dispatch]);

  const handleDelete = async (id) => {
    try {
      await dispatch(deleteExpenseRecord(id));
      message.success('Expense record deleted successfully.');
    } catch (error) {
      message.error('Failed to delete expense record.');
    }
  };

  const columns = [
    {
      title: 'Expense Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => {
        if (name) return name;
        const expenseType = expenseTypes.find((type) => type.id === record.expenseTypeID);
        if (expenseType) return(
          <Tooltip title={expenseType.description}>{expenseType.name}</Tooltip>
        ) 
         return 'Unknown';
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => <Tag color="green">Tsh {amount.toLocaleString()}</Tag>,
    },
    {
      title: 'Date of Expense',
      dataIndex: 'dateOfExpense',
      key: 'dateOfExpense',
      render: (date) => dayjs(date).format('YYYY-MM-DD') || 'N/A',
    },
    {
      title: 'Date Recorded',
      dataIndex: 'time',
      key: 'time',
      render: (date) => dayjs(date).format('YYYY-MM-DD') || 'N/A',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button danger onClick={() => handleDelete(record.id)}>
          Delete
        </Button>
      ),
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      {/* <h2>Expenses History</h2> */}
      <Breadcrumb
                style={{ marginBottom: 10 }}
                items={[
                    {
                        title: <Link to='/'><DashboardOutlined /></Link>,
                    },
                    {
                        title: "Expenses",
                    },
                    {
                        title: 'Expenses History'
                    },

                ]}
            />
      <Table
        columns={columns}
        dataSource={expenseRecords?.filter((record) => record.active).sort((a,b)=>b.time-a.time) || []}
        rowKey="id"
        loading={loading}
        pagination={{ pageSize: 10 }}
        bordered
      />
    </div>
  );
};

export default ExpensesHistory;
