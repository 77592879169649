import {
  FETCH_PAYMENT_SYSTEMS_REQUEST,
  FETCH_PAYMENT_SYSTEMS_SUCCESS,
  FETCH_PAYMENT_SYSTEMS_FAILURE,
  CREATE_PAYMENT_SYSTEM_REQUEST,
  CREATE_PAYMENT_SYSTEM_SUCCESS,
  CREATE_PAYMENT_SYSTEM_FAILURE,
  UPDATE_PAYMENT_SYSTEM_REQUEST,
  UPDATE_PAYMENT_SYSTEM_SUCCESS,
  UPDATE_PAYMENT_SYSTEM_FAILURE,
  FETCH_ACTIVE_PAYMENT_SYSTEMS_REQUEST,
  FETCH_ACTIVE_PAYMENT_SYSTEMS_SUCCESS,
  FETCH_ACTIVE_PAYMENT_SYSTEMS_FAILURE,
  FETCH_ACTIVE_PAYMENT_RECORDS_REQUEST,
  FETCH_ACTIVE_PAYMENT_RECORDS_SUCCESS,
  FETCH_ACTIVE_PAYMENT_RECORDS_FAILURE,
  DELETE_PAYMENT_RECORD_REQUEST,
  DELETE_PAYMENT_RECORD_SUCCESS,
  DELETE_PAYMENT_RECORD_FAILURE,
  CREATE_PAYMENT_RECORD_REQUEST,
  CREATE_PAYMENT_RECORD_SUCCESS,
  CREATE_PAYMENT_RECORD_FAILURE,
} from '../actions/Types';

const initialState = {
  paymentRecords: [],
  paymentSystems: [],
  activePaymentSystems: [],
  isCreatingPaymentRecord: false,
  createdSuccess: false,
  fetchedRecords: false,
  loading: false,
  error: null,
};

const PaymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAYMENT_SYSTEMS_REQUEST:
    case CREATE_PAYMENT_SYSTEM_REQUEST:
    case UPDATE_PAYMENT_SYSTEM_REQUEST:
    case FETCH_ACTIVE_PAYMENT_SYSTEMS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_ACTIVE_PAYMENT_RECORDS_REQUEST:
      return {
        ...state,
        fetchedRecords: false,
        loading: true,
      };

    case FETCH_PAYMENT_SYSTEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentSystems: action.payload.paymentSystems,
        activePaymentSystems: action.payload.activePaymentSystems,
      };

    case FETCH_ACTIVE_PAYMENT_SYSTEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        activePaymentSystems: action.payload,
      };
    case FETCH_ACTIVE_PAYMENT_RECORDS_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchedRecords: true,
        paymentRecords: action.payload,
      };

    case CREATE_PAYMENT_SYSTEM_SUCCESS:
      return {
        ...state,
        loading: false,
        // paymentSystems: [...state.paymentSystems, action.payload],
      };

    case UPDATE_PAYMENT_SYSTEM_SUCCESS:
      return {
        ...state,
        loading: false,
        // paymentSystems: state.paymentSystems.map(system =>
        //   system.id === action.payload.id ? action.payload : system
        // ),
      };

    case FETCH_PAYMENT_SYSTEMS_FAILURE:
    case CREATE_PAYMENT_SYSTEM_FAILURE:
    case UPDATE_PAYMENT_SYSTEM_FAILURE:
    case FETCH_ACTIVE_PAYMENT_SYSTEMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_ACTIVE_PAYMENT_RECORDS_FAILURE:
      return {
        ...state,
        loading: false,
        fetchedRecords: false,
        error: action.payload,
      };

    case DELETE_PAYMENT_RECORD_REQUEST:
      return { ...state, loading: true };
    case DELETE_PAYMENT_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
        // paymentRecords: state.paymentRecords.filter(
        //   (record) => record.id !== action.payload
        // ), // Optionally remove the record from the state
      };
    case DELETE_PAYMENT_RECORD_FAILURE:
      return { ...state, loading: false, error: action.payload };


    case CREATE_PAYMENT_RECORD_REQUEST:
      return {
        ...state,
        isCreatingPaymentRecord: true,
        createdSuccess: false,
        error: action.payload,
      };
    case CREATE_PAYMENT_RECORD_SUCCESS:
      return {
        ...state,
        isCreatingPaymentRecord: false,
        createdSuccess: true,
        error: action.payload,
      };
    case CREATE_PAYMENT_RECORD_FAILURE:
      return {
        ...state,
        isCreatingPaymentRecord: false,
        createdSuccess: false,
        error: action.payload,
      };


    default:
      return state;
  }
};

export default PaymentsReducer;
