import React, { useState, useEffect } from 'react';
import { Layout, Form, Input, Button, Typography, Select, Spin, Alert, Row, Col, Breadcrumb } from 'antd';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { registerUser } from '../../actions/UserActions';
// import { registerUser } from '../actions/userActions';  // Import the registerUser action

import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { DashboardOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { Option } = Select;
const { Content } = Layout;

// Custom colors for styling
const colors = {
  primary: '#4A90E2',
  error: '#f5222d',
  success: '#52c41a',
};

const StyledContent = styled(Content)`
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: var(--primary-background);
  border: 1px solid #bbb;
      border-radius: 8px;
`;

const RegisterWrapper = styled(Content)`
width: 100%;
  max-width: 800px;
  margin: auto;
  padding: 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

const RegisterUser = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  // Redux state selectors
  const loading = useSelector(state => state.user.loading);
  const error = useSelector(state => state.user.error);
  const success = useSelector(state => state.user.success);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Clear success message after registration
  useEffect(() => {
    if (success) {
      form.resetFields();
    }
  }, [success, form]);

  const onFinish = (values) => {
    dispatch(registerUser({ ...values, time: dayjs().valueOf() }));  // Dispatch the registration action
  };

  const mapFirebaseAuthError = (errorCode) => {
    switch (errorCode) {
      case 'auth/invalid-email':
        return 'The email address is not valid.';
      case 'auth/user-disabled':
        return 'The user account has been disabled.';
      case 'auth/user-not-found':
        return 'No user found with this email address.';
      case 'auth/wrong-password':
        return 'Incorrect password. Please try again.';
      case 'auth/email-already-in-use':
        return 'The email address is already in use by another account.';
      case 'auth/operation-not-allowed':
        return 'Password sign-in is disabled for this project.';
      case 'auth/weak-password':
        return 'The password is too weak. Please choose a stronger password.';
      case 'auth/too-many-requests':
        return 'We have blocked all requests from this device due to unusual activity. Please try again later.';
      case 'auth/requires-recent-login':
        return 'Please log in again to perform this action.';
      case 'auth/network-request-failed':
        return 'A network error occurred. Please check your connection.';
      case 'auth/popup-closed-by-user':
        return 'The popup was closed before completing the sign in. Please try again.';
      case 'auth/account-exists-with-different-credential':
        return 'An account already exists with the same email address but different sign-in credentials.';
      case 'auth/invalid-credential':
        return 'The credential provided is malformed or has expired.';
      case 'auth/invalid-verification-code':
        return 'The verification code is not valid.';
      case 'auth/invalid-verification-id':
        return 'The verification ID is not valid.';
      case 'auth/missing-verification-code':
        return 'The verification code is missing.';
      case 'auth/missing-verification-id':
        return 'The verification ID is missing.';
      case 'auth/credential-already-in-use':
        return 'This credential is already associated with a different user account.';
      case 'auth/invalid-phone-number':
        return 'The phone number provided is not valid.';
      case 'auth/missing-phone-number':
        return 'Please provide a valid phone number.';
      case 'auth/invalid-password':
        return 'The password is invalid. Please ensure your password is correct.';
      case 'auth/quota-exceeded':
        return 'The quota for sending SMS messages has been exceeded. Please try again later.';
      case 'auth/missing-email':
        return 'An email address must be provided.';
      case 'auth/unverified-email':
        return 'Your email address is not verified. Please verify your email first.';
      case 'auth/app-not-authorized':
        return 'This app is not authorized to use Firebase Authentication.';
      case 'auth/expired-action-code':
        return 'The action code has expired. Please try again.';
      case 'auth/invalid-action-code':
        return 'The action code is invalid. Please try again.';
      case 'auth/user-token-expired':
        return 'Your session has expired. Please log in again.';
      case 'auth/user-mismatch':
        return 'The supplied credentials do not match the previously signed-in user.';
      case 'auth/invalid-api-key':
        return 'The API key provided is invalid.';
      case 'auth/app-deleted':
        return 'The Firebase app has been deleted. Please contact support.';
      case 'auth/cors-unsupported':
        return 'CORS is not supported by your browser.';
      default:
        return 'An unexpected error occurred. Please try again.';
    }
  };


  return (
    <div style={{ padding: 20 }}>
       <Breadcrumb
                style={{ marginBottom: 10 }}
                items={[
                    {
                        title: <Link to='/'> <DashboardOutlined /></Link>,
                    },
                    {
                        title: 'Admin Settings'
                    },
                    {
                        title: 'Register User',
                    },
                ]}
            />
    <StyledContent>
      {/* <RegisterWrapper> */}
        {/* <Title level={3} style={{ textAlign: 'center', color: colors.primary }}>Register New User</Title> */}
        <Spin spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
          >
            {/* Display error message if any */}
            {error && error?.code && (
              <Alert
                message="Error"
                description={mapFirebaseAuthError(error?.code)}
                type="error"
                showIcon
                style={{ marginBottom: 20 }}
              />
            )}
            {/* Display success message if registration is successful */}
            {success && (
              <Alert
                message="Success"
                description="User registered successfully!"
                type="success"
                showIcon
                style={{ marginBottom: 20 }}
              />
            )}

            {/* Name Input */}
            <Form.Item
              label="Full Name"
              name="name"
              rules={[{ required: true, message: 'Please enter the full name' }]}
            >
              <Input placeholder="Enter full name" />
            </Form.Item>

            {/* Email Input */}
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: 'Please enter the email' },
                { type: 'email', message: 'Please enter a valid email' }
              ]}
            >
              <Input placeholder="Enter email address" />
            </Form.Item>

            {/* Password Input */}
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please enter a password' }]}
            >
              <Input.Password placeholder="Enter password" />
            </Form.Item>

            {/* Role Selection */}
            <Form.Item
              label="User Role"
              name="role"
              rules={[{ required: true, message: 'Please select a role' }]}
            >
              <Select placeholder="Select user role">
                <Option value="accountant">Accountant</Option>
                <Option value="cashier">Cashier</Option>
                <Option value="admin">Admin</Option>
                <Option value="board">Board</Option>
              </Select>
            </Form.Item>

            {/* Submit Button */}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                // style={{ width: '100%', backgroundColor: colors.primary }}
              >
                Register User
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      {/* </RegisterWrapper> */}
    </StyledContent>
            </div>
  );
};

export default RegisterUser;
