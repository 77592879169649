import { auth, db } from '../config/firebase';
import {
    FETCH_EXPENSE_TYPES_REQUEST,
    FETCH_EXPENSE_TYPES_SUCCESS,
    FETCH_EXPENSE_TYPES_FAILURE,
    CREATE_EXPENSE_TYPE_REQUEST,
    CREATE_EXPENSE_TYPE_SUCCESS,
    CREATE_EXPENSE_TYPE_FAILURE,
    UPDATE_EXPENSE_TYPE_REQUEST,
    UPDATE_EXPENSE_TYPE_SUCCESS,
    UPDATE_EXPENSE_TYPE_FAILURE,
    DELETE_EXPENSE_TYPE_REQUEST,
    DELETE_EXPENSE_TYPE_SUCCESS,
    DELETE_EXPENSE_TYPE_FAILURE,
    CREATE_EXPENSE_RECORD_REQUEST,
    CREATE_EXPENSE_RECORD_SUCCESS,
    CREATE_EXPENSE_RECORD_FAILURE,
    FETCH_EXPENSE_RECORDS_REQUEST,
    FETCH_EXPENSE_RECORDS_SUCCESS,
    FETCH_EXPENSE_RECORDS_FAILURE,
    DELETE_EXPENSE_RECORD_REQUEST,
    DELETE_EXPENSE_RECORD_SUCCESS,
    DELETE_EXPENSE_RECORD_FAILURE,
} from './Types'; // Replace with your actual path
import { collection, addDoc, updateDoc, deleteDoc, doc, onSnapshot } from 'firebase/firestore';

// Fetch Expense Types
export const fetchExpenseTypes = () => (dispatch) => {
    dispatch({ type: FETCH_EXPENSE_TYPES_REQUEST });

    try {
        const expenseRef = collection(db, 'expenseTypes');
        onSnapshot(
            expenseRef,
            (snapshot) => {
                const expenseTypes = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                dispatch({ type: FETCH_EXPENSE_TYPES_SUCCESS, payload: expenseTypes });
            },
            (error) => {
                dispatch({ type: FETCH_EXPENSE_TYPES_FAILURE, payload: error.message });
            }
        );
    } catch (error) {
        dispatch({ type: FETCH_EXPENSE_TYPES_FAILURE, payload: error.message });
    }
};

// Create Expense Type
export const createExpenseType = (data) => async (dispatch) => {
    dispatch({ type: CREATE_EXPENSE_TYPE_REQUEST });

    try {
        const expenseRef = collection(db, 'expenseTypes');
        await addDoc(expenseRef, { ...data, active: true });
        dispatch({ type: CREATE_EXPENSE_TYPE_SUCCESS });
    } catch (error) {
        dispatch({ type: CREATE_EXPENSE_TYPE_FAILURE, payload: error.message });
    }
};

// Update Expense Type
export const updateExpenseType = (data) => async (dispatch) => {
    dispatch({ type: UPDATE_EXPENSE_TYPE_REQUEST });

    try {
        const expenseDocRef = doc(db, 'expenseTypes', data.id);
        await updateDoc(expenseDocRef, {
            name: data.name,
            description: data.description,
        });
        dispatch({ type: UPDATE_EXPENSE_TYPE_SUCCESS });
    } catch (error) {
        dispatch({ type: UPDATE_EXPENSE_TYPE_FAILURE, payload: error.message });
    }
};

// Mark Expense Type as Inactive
export const deleteExpenseType = (id) => async (dispatch) => {
    dispatch({ type: DELETE_EXPENSE_TYPE_REQUEST });

    try {
        const expenseDocRef = doc(db, 'expenseTypes', id);
        await updateDoc(expenseDocRef, {
            active: false, // Mark the document as inactive
        });
        dispatch({ type: DELETE_EXPENSE_TYPE_SUCCESS });
    } catch (error) {
        dispatch({ type: DELETE_EXPENSE_TYPE_FAILURE, payload: error.message });
    }
};




export const createExpenseRecord = (record) => async (dispatch, getState) => {
    dispatch({ type: CREATE_EXPENSE_RECORD_REQUEST });
  
    try {
      const recorderID = auth.currentUser.uid;
  
      const payload = {
        ...record,
        recorderID,
      };
  
      await addDoc(collection(db, 'expenseRecords'), payload);
      dispatch({ type: CREATE_EXPENSE_RECORD_SUCCESS, payload });
    } catch (error) {
      dispatch({ type: CREATE_EXPENSE_RECORD_FAILURE, payload: error.message });
    }
  };



export const fetchExpenseRecords = () => async (dispatch) => {
    dispatch({ type: FETCH_EXPENSE_RECORDS_REQUEST });
  
    try {
    //   const q = query(collection(db, 'expenseRecords'), where('active', '==', true));
      const expenseRecordsRef = collection(db, 'expenseRecords');
  
      onSnapshot(expenseRecordsRef, (snapshot) => {
        const records = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        dispatch({ type: FETCH_EXPENSE_RECORDS_SUCCESS, payload: records });
      });
    } catch (error) {
      dispatch({ type: FETCH_EXPENSE_RECORDS_FAILURE, payload: error.message });
    }
  };


export const deleteExpenseRecord = (id) => async (dispatch) => {
    dispatch({ type: DELETE_EXPENSE_RECORD_REQUEST });
  
    try {
      const expenseDocRef = doc(db, 'expenseRecords', id);
      await updateDoc(expenseDocRef, { active: false });
      dispatch({ type: DELETE_EXPENSE_RECORD_SUCCESS, payload: id });
    } catch (error) {
      dispatch({ type: DELETE_EXPENSE_RECORD_FAILURE, payload: error.message });
    }
  };