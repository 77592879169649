// StudyYearReducer.js
import {
    FETCH_ALL_STUDY_YEARS_REQUEST,
    FETCH_ALL_STUDY_YEARS_SUCCESS,
    FETCH_ALL_STUDY_YEARS_FAILURE,
    CREATE_STUDY_YEAR_REQUEST,
    CREATE_STUDY_YEAR_SUCCESS,
    CREATE_STUDY_YEAR_FAILURE,
    DEACTIVATE_STUDY_YEAR_REQUEST,
    DEACTIVATE_STUDY_YEAR_SUCCESS,
    DEACTIVATE_STUDY_YEAR_FAILURE,
    ACTIVATE_STUDY_YEAR_REQUEST,
    ACTIVATE_STUDY_YEAR_SUCCESS,
    ACTIVATE_STUDY_YEAR_FAILURE,
} from '../actions/Types';

const initialState = {
    activeYears: [],
    studyYears: [],
    loading: false,
    error: null,
    success: null
};

const studyYearReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_STUDY_YEARS_REQUEST:
        case CREATE_STUDY_YEAR_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_ALL_STUDY_YEARS_SUCCESS:
            return {
                ...state,
                studyYears: action.payload?.studyYears,
                activeYears: action.payload?.activeYears,
                loading: false,
            };
        case CREATE_STUDY_YEAR_SUCCESS:
            return {
                ...state,
                // studyYears: [...state.studyYears, action.payload],
                loading: false,
            };
        case FETCH_ALL_STUDY_YEARS_FAILURE:
        case CREATE_STUDY_YEAR_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case DEACTIVATE_STUDY_YEAR_REQUEST:
        case ACTIVATE_STUDY_YEAR_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                success: null,
            };

        case DEACTIVATE_STUDY_YEAR_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'Study year deactivated successfully.',
                error: null,
            };

        case ACTIVATE_STUDY_YEAR_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'Study year activated successfully.',
                error: null,
            };

        case DEACTIVATE_STUDY_YEAR_FAILURE:
        case ACTIVATE_STUDY_YEAR_FAILURE:
            return {
                ...state,
                loading: false,
                success: null,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default studyYearReducer;
