import { addDoc, collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import {
  CREATE_PAYMENT_RECORD_FAILURE,
  CREATE_PAYMENT_RECORD_REQUEST,
    CREATE_PAYMENT_RECORD_SUCCESS,
    CREATE_PAYMENT_SYSTEM_FAILURE,
    CREATE_PAYMENT_SYSTEM_REQUEST,
    CREATE_PAYMENT_SYSTEM_SUCCESS,
    DELETE_PAYMENT_RECORD_FAILURE,
    DELETE_PAYMENT_RECORD_REQUEST,
    DELETE_PAYMENT_RECORD_SUCCESS,
    FETCH_ACTIVE_PAYMENT_RECORDS_FAILURE,
    FETCH_ACTIVE_PAYMENT_RECORDS_REQUEST,
    FETCH_ACTIVE_PAYMENT_RECORDS_SUCCESS,
    FETCH_ACTIVE_PAYMENT_SYSTEMS_FAILURE,
    FETCH_ACTIVE_PAYMENT_SYSTEMS_REQUEST,
    FETCH_ACTIVE_PAYMENT_SYSTEMS_SUCCESS,
    FETCH_PAYMENT_SYSTEMS_FAILURE,
     FETCH_PAYMENT_SYSTEMS_REQUEST,
     FETCH_PAYMENT_SYSTEMS_SUCCESS,
     UPDATE_PAYMENT_SYSTEM_FAILURE,
     UPDATE_PAYMENT_SYSTEM_REQUEST,
     UPDATE_PAYMENT_SYSTEM_SUCCESS
 } from "./Types";
import { auth, db } from "../config/firebase";

// Fetch Payment Systems
export const fetchPaymentSystems = () => dispatch => {
    dispatch({ type: FETCH_PAYMENT_SYSTEMS_REQUEST });
  
    const unsubscribe = onSnapshot(collection(db, 'paymentSystems'), (snapshot) => {
      const paymentSystems = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      const activePaymentSystems = snapshot.docs
        ?.filter(doc => doc.data()?.active !== false) // Only fetch active payment systems
        ?.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
  
      dispatch({
        type: FETCH_PAYMENT_SYSTEMS_SUCCESS,
        payload: {paymentSystems, activePaymentSystems},
      });
    }, (error) => {
      dispatch({
        type: FETCH_PAYMENT_SYSTEMS_FAILURE,
        payload: error.message,
      });
    });
  
    return unsubscribe; // Optionally return the unsubscribe function
  };
  
  // Create Payment System
  export const createPaymentSystem = (paymentSystem) => async dispatch => {
    dispatch({ type: CREATE_PAYMENT_SYSTEM_REQUEST });
  
    try {
      const docRef = await addDoc(collection(db, 'paymentSystems'), paymentSystem);
      const newPaymentSystem = { id: docRef.id, ...paymentSystem };
  
      dispatch({
        type: CREATE_PAYMENT_SYSTEM_SUCCESS,
        payload: newPaymentSystem,
      });
    } catch (error) {
      dispatch({
        type: CREATE_PAYMENT_SYSTEM_FAILURE,
        payload: error.message,
      });
    }
  };
  
  // Update Payment System
  export const updatePaymentSystem = (paymentSystem) => async dispatch => {
    dispatch({ type: UPDATE_PAYMENT_SYSTEM_REQUEST });
  
    try {
      const paymentDoc = doc(db, 'paymentSystems', paymentSystem.id);
      await updateDoc(paymentDoc, paymentSystem);
  
      dispatch({
        type: UPDATE_PAYMENT_SYSTEM_SUCCESS,
        payload: paymentSystem,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_PAYMENT_SYSTEM_FAILURE,
        payload: error.message,
      });
    }
  };
  
  // Fetch Active Payment Systems
  // export const fetchActivePaymentSystems = () => dispatch => {
  //   dispatch({ type: FETCH_ACTIVE_PAYMENT_SYSTEMS_REQUEST });
  
  //   const unsubscribe = onSnapshot(collection(db, 'paymentSystems'), snapshot => {
  //     const paymentSystems = snapshot.docs
  //       ?.filter(doc => doc.data()?.active !== false) // Only fetch active payment systems
  //       ?.map(doc => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
  
  //     dispatch({ type: FETCH_ACTIVE_PAYMENT_SYSTEMS_SUCCESS, payload: paymentSystems });
  //   }, error => {
  //     console.error("Error fetching active payment systems: ", error);
  //     dispatch({ type: FETCH_ACTIVE_PAYMENT_SYSTEMS_FAILURE, payload: error.message });
  //   });
  
  //   return unsubscribe; // Return the unsubscribe function to clean up the listener when necessary
  // };



   // Create Payment Record
   export const createPaymentRecord = (paymentRecord) => async dispatch => {
    dispatch({ type: CREATE_PAYMENT_RECORD_REQUEST });
  
    try {
      const collectorID= auth.currentUser.uid;
      const paymentRecordWithCollector = {...paymentRecord, collectorID};
      const docRef = await addDoc(collection(db, 'paymentRecords'), paymentRecordWithCollector);
      const newPaymentRecord = { id: docRef.id, ...paymentRecord };
  
      dispatch({
        type: CREATE_PAYMENT_RECORD_SUCCESS,
        payload: newPaymentRecord,
      });
    } catch (error) {
      dispatch({
        type: CREATE_PAYMENT_RECORD_FAILURE,
        payload: error.message,
      });
    }
  };


   // Fetch Active Payment Records
   export const fetchActivePaymentRecords = () => dispatch => {
    dispatch({ type: FETCH_ACTIVE_PAYMENT_RECORDS_REQUEST });
  
    const unsubscribe = onSnapshot(collection(db, 'paymentRecords'), snapshot => {
      const paymentRecords = snapshot.docs
        ?.filter(doc => doc.data()?.active !== false) // Only fetch active payment records
        ?.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
  
      dispatch({ type: FETCH_ACTIVE_PAYMENT_RECORDS_SUCCESS, payload: paymentRecords });
    }, error => {
      console.error("Error fetching active payment records: ", error);
      dispatch({ type: FETCH_ACTIVE_PAYMENT_RECORDS_FAILURE, payload: error.message });
    });
  
    return unsubscribe; // Return the unsubscribe function to clean up the listener when necessary
  };
  
  


// Delete Payment Record Action
export const deletePaymentRecord = (recordId) => async (dispatch) => {
  dispatch({ type: DELETE_PAYMENT_RECORD_REQUEST });

  try {
    // Reference the document by its ID
    const recordRef = doc(db, "paymentRecords", recordId);

    // Update the 'active' field to false
    await updateDoc(recordRef, { active: false });

    // Dispatch success
    dispatch({ type: DELETE_PAYMENT_RECORD_SUCCESS, payload: recordId });
  } catch (error) {
    console.error("Error deleting payment record: ", error);
    // Dispatch failure
    dispatch({ type: DELETE_PAYMENT_RECORD_FAILURE, payload: error.message });
  }
};
