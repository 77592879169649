import { collection, doc, setDoc, updateDoc, onSnapshot, query, where, getDoc } from 'firebase/firestore';
import { message } from 'antd';
import { DEACTIVATE_ALLOCATION_ERROR, DEACTIVATE_ALLOCATION_START, DEACTIVATE_ALLOCATION_SUCCESS, FETCH_ACTIVE_ALLOCATIONS_ERROR, FETCH_ACTIVE_ALLOCATIONS_START, FETCH_ACTIVE_ALLOCATIONS_SUCCESS, SAVE_ALLOCATION_ERROR, SAVE_ALLOCATION_START, SAVE_ALLOCATION_SUCCESS } from './Types';
import { db } from '../config/firebase';



// Firestore Collection Reference
const allocationsCollection = collection(db, 'feeAllocations');

// // Action to save or update fee allocations
// export const saveFeeAllocation = (allocations) => async (dispatch) => {
//   dispatch({ type: SAVE_ALLOCATION_START });
//   try {
//     const { groupID, studentIDs } = allocations;
//     const docRef = doc(allocationsCollection, groupID);

//     await setDoc(docRef, { groupID,studentIDs, active:true }, { merge: true });
//     dispatch({ type: SAVE_ALLOCATION_SUCCESS, payload: { groupID, studentIDs, active:true } });
//     message.success('Allocation saved successfully!');
//   } catch (error) {
//     dispatch({ type: SAVE_ALLOCATION_ERROR, error });
//     message.error('Failed to save allocation.');
//   }
// };

// Action to save or update fee allocations
export const saveFeeAllocation = (allocations) => async (dispatch) => {
  dispatch({ type: SAVE_ALLOCATION_START });
  try {
    const { groupID, addedStudents, removedStudents } = allocations;
    const docRef = doc(allocationsCollection, groupID);

    // Fetch the current allocation from Firestore
    const docSnap = await getDoc(docRef);

    let currentStudentIDs = [];
    if (docSnap.exists()) {
      currentStudentIDs = docSnap.data().studentIDs || [];
    }

    // Update the student IDs: Add new, remove excluded
    const updatedStudentIDs = [
      ...new Set([...currentStudentIDs, ...addedStudents]), // Ensure unique IDs after adding
    ].filter((id) => !removedStudents.includes(id)); // Remove excluded IDs

    // Save the updated allocation back to Firestore
    await setDoc(docRef, { 
      groupID, 
      studentIDs: updatedStudentIDs, 
      active: true 
    }, { merge: true });

    dispatch({
      type: SAVE_ALLOCATION_SUCCESS,
      payload: { groupID, studentIDs: updatedStudentIDs, active: true },
    });

    message.success('Allocation saved successfully!');
  } catch (error) {
    dispatch({ type: SAVE_ALLOCATION_ERROR, error });
    message.error('Failed to save allocation.');
  }
};



// Action to deactivate an allocation
export const deactivateFeeAllocation = (id) => async (dispatch) => {
  dispatch({ type: DEACTIVATE_ALLOCATION_START });
  try {
    const docRef = doc(allocationsCollection, id);

    await updateDoc(docRef, { active: false });
    dispatch({ type: DEACTIVATE_ALLOCATION_SUCCESS, payload: id });
    message.success('Allocation deactivated successfully!');
  } catch (error) {
    dispatch({ type: DEACTIVATE_ALLOCATION_ERROR, error });
    message.error('Failed to deactivate allocation.');
  }
};

// Action to fetch all active allocations
export const fetchAllActiveAllocations = () => (dispatch) => {
  dispatch({ type: FETCH_ACTIVE_ALLOCATIONS_START });
  
  const activeAllocationsQuery = query(allocationsCollection, where('active', '==', true));

  onSnapshot(activeAllocationsQuery, (snapshot) => {
    const allocations = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    dispatch({ type: FETCH_ACTIVE_ALLOCATIONS_SUCCESS, payload: allocations });
  }, (error) => {
    dispatch({ type: FETCH_ACTIVE_ALLOCATIONS_ERROR, error });
    message.error('Failed to fetch allocations.');
  });
};
