// StudyYear.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Form, Input, Button, DatePicker, Typography, Table, Select, message, Tooltip, Modal, Breadcrumb } from 'antd';
import { activateStudyYear, createStudyYear, deactivateStudyYear, fetchAllStudyYears } from '../../actions/StudyYearActions';
// import { createStudyYear, fetchStudyYears } from '../redux/actions'; // Update the import path accordingly

import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { DashboardOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { Option } = Select;

// Styled components
const Container = styled.div`
  padding: 20px;
  // background: #fff;
  // border-radius: 8px;
  // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const StyledButton = styled(Button)`
  margin-bottom: 16px;
`;

const FormStyled = styled(Form)`
  // margin-bottom: 24px;
`;

const StudyYears = () => {
  const dispatch = useDispatch();
  const { studyYears, loading } = useSelector(state => state.studyYear); // Update based on your state structure
  const schoolList = useSelector(state => state.school.schoolList); // Update based on your state structure
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  // useEffect(() => {
  //   // Dispatch action and get the unsubscribe function
  //   const unsubscribe = dispatch(fetchAllStudyYears());

  //   // Unsubscribe on component unmount
  //   return () => {
  //     if (unsubscribe) unsubscribe();
  //   };
  // }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleToggleStatus = (yearId, school, isActive) => {
    if (isActive) {
      dispatch(deactivateStudyYear(yearId));
    } else {
      dispatch(activateStudyYear(yearId, school));

    }
  };
  const handleOpenModal = () => {
    // if (classData) {
    //     // setIsEditing(true);
    //     setCurrentClass(classData);
    //     form.setFieldsValue({
    //         year: classData.year,
    //         className: classData.className,
    //         streams: classData.streams.join(', '),
    //     });
    // } else {
    //     // setIsEditing(false);
    //     form.resetFields();
    // }
    setVisible(true);
  };

  const handleCloseModal = () => {
    setVisible(false);
    form.resetFields();
  };


  const handleFinish = (values) => {
    const { name, school, startDate, endDate } = values;

    if (!startDate || !endDate) {
      message.error('Please select both start and end dates.');
      return;
    }


    // Assuming startDate and endDate are moment objects

    // Define maxEndDate as one year after the startDate
    const maxEndDate = startDate.clone().add(1, 'year');

    // Check if the end date is after the start date and within one year from the start date
    if (!endDate.isAfter(startDate)) {
      message.error('End date must be after the start date.');
      return;
    } else if (endDate.isAfter(maxEndDate)) {
      message.error('End date should not exceed one year from the start date.');
      return;
    }


    // Dispatch the action to create a new study year
    // dispatch(createStudyYear({ name, school, startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD'), active: false }));
    // console.log({
    //   name,
    //   school,
    //   startDate: startDate ? startDate.valueOf() : null,
    //   endDate: endDate ? endDate.valueOf() : null,
    //   time: dayjs().valueOf(),
    //   active: false
    // });
    dispatch(createStudyYear({
      name,
      school,
      startDate: startDate ? startDate.valueOf() : null,
      endDate: endDate ? endDate.valueOf() : null,
      time: dayjs().valueOf(),
      active: false
    }));

    // Reset form fields after submission
    // form.resetFields();
    handleCloseModal();
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'School',
      dataIndex: 'school',
      key: 'school',
      render: (school) => {
        const wholeSchool = schoolList?.find((sch) => sch.id === school);
        return (
          <Tooltip title={wholeSchool?.description}>
            {wholeSchool?.schoolName || 'Unknown School'}
          </Tooltip>
        )
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (startDate) => startDate ? dayjs(startDate).format('YYYY-MM-DD') : 'N/A',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (endDate) => endDate ? dayjs(endDate).format('YYYY-MM-DD') : 'N/A',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      render: (active) => (active ? 'Active' : 'Inactive'),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, studyYear) => (
        <>
          <Button
            type="link"
            onClick={() => handleToggleStatus(studyYear.id, studyYear.school, studyYear.active)}
            danger={studyYear?.active}
          >
            {studyYear.active ? 'Deactivate' : 'Activate'} {schoolList?.find((sch) => sch.id === studyYear.school)?.schoolName}
          </Button>
        </>
      ),
    },
  ];

  return (
    <Container>
        <Breadcrumb
        style={{ marginBottom: 10 }}
        items={[
          {
            title: <Link to='/'><DashboardOutlined /></Link>,
          },
          {
            title: "School Settings",
          },
          {
            title: 'Study Years'
          },

        ]}
      />
      {/* <Title level={2}>Register Study Year</Title> */}
      <StyledButton type="primary" onClick={() => handleOpenModal()} loading={loading} disabled={loading}>
        Register Study Year
      </StyledButton>

      {/* <Title level={3}>Existing Study Years</Title> */}
      <Table
        dataSource={studyYears?.sort((a, b) => b.startDate - a.startDate)}
        columns={columns}
        rowKey="id" // Ensure your data has a unique key
        // pagination={false}
        pagination={{ pageSize: 10 }}
        bordered
      />

      <Modal
        title={"Register Study Year"}
        visible={visible}
        onCancel={handleCloseModal}
        footer={null}
      >
        <FormStyled
          form={form}
          layout="vertical"
          onFinish={handleFinish}
        >
          {/* <div> */}

          <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
            <Form.Item
              style={{ width: '50%' }}
              name="name"
              label="Study Year Name"
              rules={[{ required: true, message: 'Please enter the study year name' }]}
            >
              <Input placeholder="Enter study year name" />
            </Form.Item>
            <Form.Item
              style={{ width: '50%' }}
              label="School"
              name="school"
              rules={[{ required: true, message: 'Please select what school' }]}
            >
              <Select placeholder="Select School">
                {/* <Option value="secondary">O - Level</Option>
              <Option value="advance">A - Level</Option> */}
                {schoolList.filter((sch) => sch.active)
                  ?.map((sch) => (
                    <Option key={sch.id} value={sch.id}>
                      <Tooltip title={sch.description}>
                        {sch.schoolName}
                      </Tooltip>
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
            <Form.Item
              style={{ width: '50%' }}
              name="startDate"
              label="Start Date"
              rules={[{ required: true, message: 'Please select the start date' }]}
            >
              <DatePicker style={{ width: '100%' }}
              // format="YYYY-MM-DD"
              />
            </Form.Item>
            <Form.Item
              style={{ width: '50%' }}
              name="endDate"
              label="End Date"
              rules={[{ required: true, message: 'Please select the end date' }]}
            >
              <DatePicker style={{ width: '100%' }}
              //  format="YYYY-MM-DD" 
              />
            </Form.Item>
          </div>
          {/* </div> */}


          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
              Register Study Year
            </Button>
          </Form.Item>
        </FormStyled>
      </Modal>
    </Container>
  );
};

export default StudyYears;
