import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Modal, Typography, Select, Spin, Alert, Breadcrumb } from 'antd';
import styled from 'styled-components';
import { fetchUsers, toggleUserStatus, updateUserRole } from '../../actions/UserActions';
import { DashboardOutlined, HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Title } = Typography;
const { Option } = Select;

const ManageUsersContainer = styled.div`
  padding: 20px;
//   background-color: var(--light-background);
`;

const ManageUsers = () => {
    const dispatch = useDispatch();
    const { users, loading, error } = useSelector((state) => state.user);

    const [roleUpdates, setRoleUpdates] = useState({}); // Track local role updates


    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // useEffect(() => {
    //     dispatch(fetchUsers());
    // }, [dispatch]);

    const handleViewUser = (user) => {
        setSelectedUser(user);
        setIsModalVisible(true);
    };

    const handleChangeRole = (userId, newRole) => {
        setRoleUpdates((prev) => ({ ...prev, [userId]: newRole })); // Optimistically update role

        dispatch(updateUserRole(userId, newRole));
    };

    const handleToggleStatus = (userId, isActive) => {
        dispatch(toggleUserStatus(userId, !isActive));
    };

    const columns = [
        { title: 'Name', dataIndex: 'names', key: 'names' },
        { title: 'Email', dataIndex: 'email', key: 'email' },
        { title: 'Phone Number', dataIndex: 'mobile', key: 'mobile' },
        { title: 'Address', dataIndex: 'address', key: 'address' },
        { title: 'Role', dataIndex: 'role', key: 'role', render: (role) => (<>{role.toUpperCase()}</>) },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, user) => (
                <>
                    <Button type="link" onClick={() => handleViewUser(user)}>View</Button>
                    <Select defaultValue={roleUpdates[user.uid] || user.role} onChange={(role) => handleChangeRole(user.uid, role)}>
                        <Option value="admin">Admin</Option>
                        <Option value="accountant">Accountant</Option>
                        <Option value="cashier">Cashier</Option>
                        <Option value="board">Board</Option>
                    </Select>
                    {/* <Button
                        type="link"
                        onClick={() => handleToggleStatus(user.uid, user.active)}
                    >
                        {user.active ? 'Disable' : 'Enable'}
                    </Button> */}
                    {user?.active ? (
            <Button type="link" danger onClick={() => handleToggleStatus(user.uid, user.active)}>Disable</Button>
          ) : (
            <Button type="link" onClick={() => handleToggleStatus(user.uid, user.active)}>Enable</Button>
          )}
                </>
            ),
        },
    ];

    return (
        <ManageUsersContainer>

            <Breadcrumb
                style={{ marginBottom: 10 }}
                items={[
                    {
                        title: <Link to='/'> <DashboardOutlined /></Link>,
                    },
                    {
                        title: 'Admin Settings'
                    },
                    {
                        title: 'Manage Users',
                    },
                ]}
            />

            {/* <Title level={2}>Manage Users</Title> */}

            {loading ? <Spin /> : error ? <Alert type="error" message={error} /> : (
                <Table dataSource={users} columns={columns} rowKey="uid" pagination={{ pageSize: 10 }} bordered />
            )}

            <Modal
                title="User Details"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                {selectedUser && (
                    <div>
                        <p><strong>Name:</strong> {selectedUser.names}</p>
                        <p><strong>Email:</strong> {selectedUser.email}</p>
                        <p><strong>Phone Number:</strong> {selectedUser.mobile}</p>
                        <p><strong>Address:</strong> {selectedUser.address}</p>
                        <p><strong>Role:</strong> {selectedUser.role.toUpperCase()}</p>
                        <p><strong>Status:</strong> {selectedUser.active ? 'Active' : 'Disabled'}</p>
                    </div>
                )}
            </Modal>
        </ManageUsersContainer>
    );
};

export default ManageUsers;
