
import { collection, doc, getDocs, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import {  addDoc } from 'firebase/firestore';
import { CREATE_SCHOOL_FAILURE, CREATE_SCHOOL_REQUEST, CREATE_SCHOOL_SUCCESS, CREATE_STUDENT_FAILURE, CREATE_STUDENT_REQUEST, CREATE_STUDENT_SUCCESS, EDIT_CLASS_FAILURE, EDIT_CLASS_REQUEST, EDIT_CLASS_SUCCESS, EDIT_STUDENT_FAILURE, EDIT_STUDENT_REQUEST, EDIT_STUDENT_SUCCESS, FETCH_CLASSES_FAILURE, FETCH_CLASSES_REQUEST, FETCH_CLASSES_SUCCESS, FETCH_SCHOOLS_FAILURE, FETCH_SCHOOLS_REQUEST, FETCH_SCHOOLS_SUCCESS, FETCH_STUDENTS_FAILURE, FETCH_STUDENTS_REQUEST, FETCH_STUDENTS_SUCCESS, UPDATE_SCHOOL_FAILURE, UPDATE_SCHOOL_REQUEST, UPDATE_SCHOOL_SUCCESS } from './Types';
import { message } from 'antd';


export const createClassWithStreams = (classData) => {
  return async (dispatch) => {
    try {
      const classesRef = collection(db, 'classes'); // Adjust collection name
      const docRef = await addDoc(classesRef, classData);
      
      dispatch({
        type: 'CREATE_CLASS_SUCCESS',
        payload: { id: docRef.id, ...classData },
      });

      message.success(`Created class successfully!`);

    } catch (error) {
      message.error(`Failed to create class!`);

      dispatch({
        type: 'CREATE_CLASS_FAILURE',
        payload: error.message,
      });
    }
  };
};


// Action to edit a class
export const editClass = (updatedClass) => {
    return async dispatch => {
        dispatch({ type: EDIT_CLASS_REQUEST });

        try {
            const classRef = doc(db, 'classes', updatedClass.id); // Adjust the path as necessary
            await updateDoc(classRef, updatedClass);

            dispatch({
                type: EDIT_CLASS_SUCCESS,
                payload: updatedClass,
            });
            message.success(`Updated class successfully!`);

        } catch (error) {
          message.error(`Failed to update class!`);

            dispatch({
                type: EDIT_CLASS_FAILURE,
                payload: error.message,
            });
        }
    };
};




export const fetchClasses = () => {
  return dispatch => {
    dispatch({ type: FETCH_CLASSES_REQUEST });

    try {
      // Reference to the 'classes' collection in Firestore
      const classesRef = collection(db, 'classes');

      // Set up the real-time listener using onSnapshot
      const unsubscribe = onSnapshot(classesRef, (querySnapshot) => {
        const classes = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        dispatch({
          type: FETCH_CLASSES_SUCCESS,
          payload: classes,
        });
      });

      // Optional: Return unsubscribe function to clean up listener if needed
      return unsubscribe;

    } catch (error) {
      dispatch({
        type: FETCH_CLASSES_FAILURE,
        payload: error.message,
      });
    }
  };
};



// Action to create a new student
export const createStudent = (studentData) => async (dispatch) => {
  dispatch({ type: CREATE_STUDENT_REQUEST });
  
  try {
    const studentsRef = collection(db, 'students');
    const docRef = await addDoc(studentsRef, studentData);
    
    dispatch({
      type: CREATE_STUDENT_SUCCESS,
      payload: { id: docRef.id, ...studentData },
    });
  } catch (error) {
    dispatch({
      type: CREATE_STUDENT_FAILURE,
      payload: error.message,
    });
  }
};

// Action to fetch students
export const fetchStudents = () => (dispatch) => {
    dispatch({ type: FETCH_STUDENTS_REQUEST });
    
    try {
      const studentsRef = collection(db, 'students');
  
      // Using onSnapshot to listen for real-time updates
      onSnapshot(studentsRef, (snapshot) => {
        const students = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        dispatch({
          type: FETCH_STUDENTS_SUCCESS,
          payload: students,
        });
      });
    } catch (error) {
      dispatch({
        type: FETCH_STUDENTS_FAILURE,
        payload: error.message,
      });
    }
  };

// Action to edit an existing student
export const editStudent = (updatedStudent) => async (dispatch) => {
  dispatch({ type: EDIT_STUDENT_REQUEST });
  
  try {
    const studentDocRef = doc(db, 'students', updatedStudent.id);
    await updateDoc(studentDocRef, updatedStudent);
    
    dispatch({
      type: EDIT_STUDENT_SUCCESS,
      payload: updatedStudent,
    });
  } catch (error) {
    dispatch({
      type: EDIT_STUDENT_FAILURE,
      payload: error.message,
    });
  }
};


// Fetch Schools with onSnapshot
export const fetchSchools = () => (dispatch) => {
  dispatch({ type: FETCH_SCHOOLS_REQUEST });

  try {
    const unsubscribe = onSnapshot(
      collection(db, 'schools'),
      (snapshot) => {
        const schools = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        dispatch({ type: FETCH_SCHOOLS_SUCCESS, payload: schools });
      },
      (error) => {
        dispatch({ type: FETCH_SCHOOLS_FAILURE, payload: error.message });
      }
    );

    return unsubscribe; // Return unsubscribe function for cleanup
  } catch (error) {
    dispatch({ type: FETCH_SCHOOLS_FAILURE, payload: error.message });
  }
};

// Create a new school
export const createSchool = (school) => async (dispatch) => {
  dispatch({ type: CREATE_SCHOOL_REQUEST });

  try {
    const docRef = await addDoc(collection(db, 'schools'), school);
    const newSchool = { id: docRef.id, ...school };
    message.success(`School created successful!`);

    dispatch({ type: CREATE_SCHOOL_SUCCESS, payload: newSchool });
  } catch (error) {
    message.error(`Failed to create school!`);

    dispatch({ type: CREATE_SCHOOL_FAILURE, payload: error.message });
  }
};

// Update an existing school
export const updateSchool = (school) => async (dispatch) => {
  dispatch({ type: UPDATE_SCHOOL_REQUEST });

  try {
    const { id, ...updates } = school;
    const schoolDocRef = doc(db, 'schools', id);

    await updateDoc(schoolDocRef, updates);
    message.success(`School ${school?.active?'activated':'deactivated'} or updated successful!`);

    dispatch({ type: UPDATE_SCHOOL_SUCCESS, payload: school });
  } catch (error) {
    message.error(`Failed to ${school?.active?'activate':'deactivate'} or update school!`);

    dispatch({ type: UPDATE_SCHOOL_FAILURE, payload: error.message });
  }
};