import React, { useState, useEffect } from 'react';
import { Input, Button, Table, Modal, Select, Row, Col, Space, Tooltip, Typography, DatePicker, Form, Breadcrumb, Spin, message } from 'antd';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { fetchClasses, fetchStudents } from '../../actions/SchoolActions';
import { fetchActiveFeeTypes, fetchFeeGroups } from '../../actions/FeesActions';
// import { fetchStudents, fetchActiveFeeGroups, createPayment } from '../actions/paymentActions';
import dayjs from 'dayjs';
import { fetchActiveStudyYears } from '../../actions/StudyYearActions';
import { fetchAllActiveAllocations } from '../../actions/AllocationActions';
import { createPaymentRecord, fetchActivePaymentRecords, fetchPaymentSystems } from '../../actions/PaymentActions';
import logo from '../../assets/swillaLogo.png';

import './CollectPayments.css'

import { DashboardOutlined, DownloadOutlined } from '@ant-design/icons';
import { jsPDF } from 'jspdf'; // Make sure to install jsPDF for PDF generation
import "jspdf-autotable";
import { removeEmptyArraysDeep } from '../../utils';
import { Link } from 'react-router-dom';

const { Text } = Typography;
const { Search } = Input;
const { Option } = Select;

const Wrapper = styled.div`
  padding: 20px;
`;

const FiltersContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 20px;
  justify-content:center;
`;





const CollectPayments = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [filterOptions, setFilterOptions] = useState({ class: '', stream: '', gender: '', year: '' });
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);



  const [isFeeModalVisible, setIsFeeModalVisible] = useState(false);
  const [isPaidFeesModalVisible, setIsPaidFeesModalVisible] = useState(false);
  const [selectedStudentFees, setSelectedStudentFees] = useState({ totalFees: 0, feeBreakdown: [] });
  const [selectedStudentPaidFees, setSelectedStudentPaidFees] = useState({ totalPaid: 0, feeBreakdown: [] });

  const user = useSelector(state => state.user.user);

  const students = useSelector(state => state.school.students);
  const classes = useSelector(state => state.school.classes);
  const feeGroups = useSelector(state => state.fees.feeGroups);
  const activeYears = useSelector(state => state.studyYear.activeYears);
  const activeAllocations = useSelector((state) => state.allocations.activeAllocations);
  const activeFeeTypes = useSelector(state => state.fees.activeFeeTypes);
  const paymentRecords = useSelector(state => state.payments.paymentRecords);
  const fetchedRecords = useSelector(state => state.payments.fetchedRecords);
  const isCreatingPaymentRecord = useSelector(state => state.payments.isCreatingPaymentRecord);
  const createdSuccess = useSelector(state => state.payments.createdSuccess);
  const paymentSystems = useSelector(state => state.payments.activePaymentSystems);

  const [isPayModalVisible, setIsPayModalVisible] = useState(false);
  // const [selectedStudent, setSelectedStudent] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState({
    amount: null,
    // description: null,
    // dateOfPayment: null,
    // paymentSystemID: null
  });

  const [receiptDetails, setReceiptDetails] = useState({});
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);


  const [distributionPreview, setDistributionPreview] = useState([]);
  const [feeTypesWithPassedDueDates, setFeeTypesWithPassedDueDates] = useState([]);



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  // useEffect(() => {
  //   const unsubscribe = dispatch(fetchPaymentSystems());

  //   // Clean up listener on component unmount
  //   return () => unsubscribe && unsubscribe();
  // }, [dispatch]);

  // useEffect(() => {
  //   // dispatch(fetchActiveStudyYears());
  //   // dispatch(fetchActiveFeeTypes());

  //   // dispatch(fetchStudents());
  //   // dispatch(fetchFeeGroups());
  //   // dispatch(fetchClasses());
  // }, [dispatch]);

  useEffect(() => {
    setFilteredStudents(students);
  }, [students]);

  // useEffect(() => {
  //   dispatch(fetchAllActiveAllocations());

  // }, [dispatch]);

  useEffect(() => {
    const unsubscribe = dispatch(fetchActivePaymentRecords());

    // Clean up listener on component unmount
    return () => unsubscribe && unsubscribe();
  }, [dispatch]);


  // Distribute amount dynamically as user types
  // useEffect(() => {
  //   if (paymentDetails.amount > 0) {
  //     const previewDistribution = distributeAmountAmongFees(
  //       paymentDetails.amount,
  //       feeGroups,
  //       activeFeeTypes,
  //       paymentRecords,
  //       selectedStudent?.id
  //     );
  //     setDistributionPreview(previewDistribution);
  //   }
  // }, [paymentDetails.amount]);

  useEffect(() => {
    const previewDistribution = distributeAmountAmongFees(
      paymentDetails.amount || 0, // Use current amount or 0
      feeGroups,
      activeFeeTypes,
      paymentRecords,
      selectedStudent?.id
    );
    setDistributionPreview(previewDistribution);
  }, [paymentDetails.amount]);


  // Effect to generate receipt after successful creation
  useEffect(() => {
    if (createdSuccess && isProcessingPayment) {
      const { student, distributedFees, paymentDetails, overdraft } = receiptDetails;
      generateReceipt(student, distributedFees, paymentDetails, overdraft);
      message.success("Receipt generated successfully!");
      setIsProcessingPayment(false);
    }
  }, [createdSuccess, receiptDetails, isProcessingPayment]);



  // Dynamic stream options based on selected class
  const getStreamOptions = () => {
    const selectedClass = classes.find((cls) => cls.id === filterOptions.class);
    return selectedClass ? selectedClass.streams : [];
  };

  const handleSearch = (value) => {
    const results = students.filter(student =>
      student?.name.toLowerCase().includes(value.toLowerCase()) ||
      student?.regNumber.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredStudents(results);
  };

  const handleSearchText = (e) => {
    setSearchText(e.target.value);
  };

  const handleFilter = () => {
    const { class: classFilter, stream: streamFilter, gender: genderFilter, year: yearFilter } = filterOptions;

    const filteredStudents = students.filter((student) => {
      const selectedClass = classes.find(clas => clas.id === classFilter); // Get the class matching the filter

      const matchesSearch = (searchText && searchText !== '')
        ? student?.name?.toLowerCase().includes(searchText?.toLowerCase()) || student?.regNumber.toLowerCase().includes(searchText.toLowerCase())
        : true;

      // Check if the student's class, stream, and gender match the selected filters
      // const matchesClass = classFilter ? student.class === selectedClass?.className : true;
      const matchesClass = classFilter
        ? student.classes.some(classItem => classItem.class === selectedClass?.id)
        : true;

      // const matchesStream = streamFilter ? student.stream === streamFilter : true;
      const matchesStream = streamFilter
        ? student.classes.some(classItem => classItem.stream === streamFilter)
        : true;

      const matchesGender = genderFilter ? student.gender === genderFilter : true;

      const matchesYear = yearFilter
        ? student.classes.some(classItem => classItem.year === yearFilter)
        : true;

      return matchesSearch && matchesClass && matchesStream && matchesGender && matchesYear;
    });

    setFilteredStudents(filteredStudents);
  };



  const handleOpenModal = (student) => {
    setSelectedStudent(student);
    setIsModalVisible(true);
  };


  // const handlePayClick = (student) => {
  //   setSelectedStudent(student);
  //   setIsPayModalVisible(true);
  // };

  const handlePayClick = (student, remainingAmount) => {
    setSelectedStudent(student);

    setPaymentDetails({ amount: null, remainingAmount });  // Reset state here


    // Initialize distribution preview without an amount
    const previewDistribution = distributeAmountAmongFees(
      0, // Start with 0 to show max payable amounts
      feeGroups,
      activeFeeTypes,
      paymentRecords,
      student.id
    );

    const currentDate = new Date();

    // Filter fees that meet the condition and extract feeTypeIDs
    const overdueFeeTypeIDs = previewDistribution
      .filter((fee) => fee.maxPayableAmount > 0 && new Date(fee.dueDate) < currentDate)
      .map((fee) => fee.feeTypeID);

    setDistributionPreview(previewDistribution);
    setFeeTypesWithPassedDueDates(overdueFeeTypeIDs);

    setIsPayModalVisible(true);

  };


  const handleModalOk = (values) => {

    // const { amount, dateOfPayment, paymentSystemID } = paymentDetails;
    const { amount } = paymentDetails;
    const mergedPaymentDetails = { ...paymentDetails, ...values };

    const overdraft =
      (amount > distributionPreview.reduce((total, entry) => total + entry.maxPayableAmount, 0)) ?
        (amount - distributionPreview.reduce((total, entry) => total + entry.maxPayableAmount, 0)) :
        0;

    // if (!amount || isNaN(amount) || amount <= 0) {
    //   showErrorModal("Amount should be valid and greater than zero.");
    //   return;
    // }

    // if (!dateOfPayment) {
    //   showErrorModal("Please select a date of payment.");
    //   return;
    // }

    // if (!paymentSystemID) {
    //   showErrorModal("Please select a payment system.");
    //   return;
    // }

    // console.log(selectedStudent.id, mergedPaymentDetails, overdraft);
    processPayment(selectedStudent.id, mergedPaymentDetails, overdraft);

    handleModalCancel();


  };

  const handleModalCancel = () => {
    setIsPayModalVisible(false);
    setPaymentDetails({ amount: 0, dateOfPayment: null, paymentSystemID: null, description: null });
    setDistributionPreview([]);
  };






  const processPayment = async (studentId, paymentDetails, overdraft) => {
    const { amount, dateOfPayment, paymentSystemID, description } = paymentDetails;

    setReceiptDetails({});
    setIsProcessingPayment(true);

    const distributedFees = distributeAmountAmongFeesForRecord(
      amount,
      feeGroups,
      activeFeeTypes,
      paymentRecords,
      studentId
    );

    const paymentRecord = {
      active: true,
      studentID: studentId,
      dateOfPayment: dayjs(dateOfPayment).valueOf() || dayjs().valueOf(),
      paymentSystemID,
      feesPaid: distributedFees,
      overdraft,
      description: description || '',
      time: dayjs().valueOf()
    };

    dispatch(createPaymentRecord(paymentRecord));

    setReceiptDetails({
      student: students.find(st => st.id === studentId),
      distributedFees,
      paymentDetails,
      overdraft
    });

    // generateReceipt(students.find(st => st.id === studentId), distributedFees, paymentDetails, overdraft);

  };


  const generateReceipt = (student, distributedFees, paymentDetails, overdraft) => {
    const receiptData = distributedFees?.map(({ groupID, feeTypeID, amountPaid }) => {
      // const group = feeGroups.find(g => g.id === groupID);
      const feeType = activeFeeTypes.find(f => f.id === feeTypeID);
      return {
        feeName: feeType ? feeType.feeName : '',
        // groupName: group ? group.groupName : '',
        feeTypeID: feeType ? feeType.id : '',
        // groupID: group ? group.id : '',
        amountPaid
      };
    });

    const totalAmount = receiptData.reduce((sum, record) => sum + record.amountPaid, 0);
    // const overdraft = totalAmount < distributedFees.reduce((sum, fee) => sum + fee.amountPaid, 0) ? 'Overdraft' : '';
    const totalAmountInWords = convertNumberToWords(totalAmount); // Function to convert amount to words

    // Function to generate PDF
    // Function to generate PDF styled like the modal with tables, logo, and relevant styling
    const generatePDF = () => {
      const doc = new jsPDF({
        orientation: "landscape", // For half A4 width
        unit: "mm",              // Use mm for accurate dimensions
        format: [210, 148.5],    // Set width and height explicitly
      });

      // console.log(receiptData)
      // Page dimensions
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();

      // Add the Logo
      doc.addImage(logo, 'PNG', 10, 5, 35, 35); // Adjust position and size

      // Add Title and Address
      doc.setFontSize(12);
      doc.text(`CHRISTIAN IN EDUCATION AND DEVELOPMENT PROMOTION(CEDP)`, (pageWidth / 2) + 22.5, 10, { align: "center" }); // Title
      doc.text(`SWILLA SCHOOLS`, (pageWidth / 2) + 22.5, 15, { align: "center" }); // Title
      doc.setFontSize(10);
      doc.text(`P.O.BOX 1787, MBEYA - TANZANIA`, (pageWidth / 2) + 22.5, 20, { align: "center" });
      doc.text(`Tel: +255 252 560108, +255 758 6064 572`, (pageWidth / 2) + 22.5, 25, { align: "center" });
      doc.text(`Email: info@swillaschools.ac.tz`, (pageWidth / 2) + 22.5, 30, { align: "center" });
      doc.text(`Website: www.swillaschools.ac.tz`, (pageWidth / 2) + 22.5, 35, { align: "center" });

      // Add Date and Payment System
      doc.setFontSize(10);
      // doc.text(`Date: ${new Date().toLocaleDateString()}`, 10, 60);
      // doc.text(`Payment System: `, 10, 70); // Replace with actual payment system

      const margin = 10; // Margin from the edges

      // Draw left-aligned text
      doc.text(`Receipt No. 4232`, margin, 50);

      doc.text(`RECEIPT`, (pageWidth / 2), 50, { align: "center", });

      // Measure the width of the right-aligned text
      const rightTextWidth = doc.getTextWidth(`Date: ${new Date().toLocaleDateString()}`);

      // Draw right-aligned text
      doc.text(`Date: ${new Date().toLocaleDateString()}`, pageWidth - margin - rightTextWidth, 50);




      // Measure the text width for accurate underlining
      const textWidth = doc.getTextWidth(`RECEIPT`);

      // Draw an underline (slightly below the text)
      doc.setDrawColor(0); // Black color for the line
      doc.setLineWidth(0.5); // Line thickness
      doc.line((pageWidth / 2) - (textWidth / 2), 50 + 1, (pageWidth / 2) + (textWidth / 2), 50 + 1);


      doc.setFontSize(12);
      doc.setLineWidth(0.3); // Line thickness
      doc.setLineDash([.5, 1]); // Dash length and gap
      doc.text(`RECEIVED FROM: ${student.name} (${student.regNumber})`, 10, 58); // Title
      doc.line(doc.getTextWidth(`RECEIVED FROM: `) + margin, 58 + 1, (pageWidth) - margin, 58 + 1);

      doc.text(`DATE OF PAYMENT: ${new Date(paymentDetails?.dateOfPayment).toLocaleDateString()}`, 10, 65); // Title
      doc.line(doc.getTextWidth(`DATE OF PAYMENT: `) + margin, 65 + 1, (pageWidth) - margin, 65 + 1);

      doc.text(`PAYMENT SYSTEM: ${paymentSystems?.find((ps) => ps.id === paymentDetails?.paymentSystemID)?.systemName||paymentDetails?.paymentSystemID}`, 10, 72); // Title
      doc.line(doc.getTextWidth(`PAYMENT SYSTEM: `) + margin, 72 + 1, (pageWidth) - margin, 72 + 1);

      doc.setLineDash([]); // Resettttting


      // // Add Payment Details Title
      // doc.setFontSize(14);
      // doc.text('Payment Details:', 10, 100);

      const columns = ["NO", "RECEIPT DETAILS", "AMOUNT IN TSHS"];

      var initial = receiptData.map((item, index) => {

        // Map other fields into standard columns
        return [index + 1, item.feeName || "", item.amountPaid || ""];
      }) || [];

      var last = [];

      if (overdraft && parseInt(overdraft) > 0) {
        last = ["", "OVERDRAFT", `${overdraft.toLocaleString()}`];// Ensure the "amount" entry occupies the last column
      }
      const total = ["", "TOTAL", `${(totalAmount + parseInt(overdraft)).toLocaleString()}`];// Ensure the "amount" entry occupies the last column

      const rows = [...initial, last, total]

      // console.log(receiptData)
      // console.log(rows)

      doc.autoTable({
        head: [columns],
        body: removeEmptyArraysDeep(rows),
        startY: 75,
        theme: "grid", // Themes: 'striped', 'grid', or 'plain'
        styles: {
          fontSize: 10,         // Font size for table content
          halign: "center",     // Horizontal alignment: 'left', 'center', 'right'
          valign: "middle",     // Vertical alignment
          fillColor: [220, 220, 220], // Background color for cells
          textColor: "#000000",       // Font color
        },
        headStyles: {
          fillColor: [41, 128, 185], // Header background color
          textColor: "#ffffff",      // Header text color
          fontStyle: "bold",         // Header text style
        },
        alternateRowStyles: {
          fillColor: [245, 245, 245], // Alternating row color
        },
        margin: { top: 20 },        // Table position from the top
        columnStyles: {
          3: { halign: "right" }, // Align "Amount" to the right
        },
        didDrawCell: (data) => {
          const rowIndex = data.row.index;
          const colIndex = data.column.index;

          // Check if it's the "Total" row
          if (rowIndex === data.table.body.length - 1) {
            // Merge all cells except the last one
            if (colIndex < columns.length - 1) {
              data.cell.styles.fillColor = [220, 220, 220]; // Light gray for merged cells
              data.cell.colSpan = columns.length - 1; // Merge all left cells
              if (colIndex !== 2) {
                data.cell.text = ""; // Clear text for non-"Total:" cells
              }
            }
          }
        },
      });



      // // Add the Table for Payment Details
      // const startY = 110;
      // const rowHeight = 10;
      // const columnWidths = [80, 60, 40]; // Adjust width for each column

      // // Table Header
      // doc.setFontSize(12);
      // doc.setFont("helvetica", "bold");
      // doc.text('Fee Type', 10, startY);
      // // doc.text('Group', 90, startY);
      // doc.text('Amount Paid', 150, startY);

      // Table Rows
      // doc.setFontSize(10);
      // doc.setFont("helvetica", "normal");
      // receiptData.forEach((item, index) => {
      //   doc.text(item.feeName, 10, startY + (index + 1) * rowHeight);
      //   // doc.text(item.groupName, 90, startY + (index + 1) * rowHeight);
      //   doc.text(`Ths ${item.amountPaid?.toLocaleString()}`, 150, startY + (index + 1) * rowHeight);
      // });

      // Draw a horizontal line under the table
      doc.setLineWidth(0.3); // Line thickness
      doc.line(10, 133, 200, 134);

      doc.setLineWidth(0.3); // Line thickness
      doc.setLineDash([.5, 1]); // Dash length and gap
      // // Overdraft message
      // if (overdraft) {
      //   doc.setFontSize(12);
      //   doc.setTextColor(255, 0, 0); // Red color
      //   doc.text('Overdraft', 10, startY + (receiptData.length + 2) * rowHeight);
      //   doc.setTextColor(0, 0, 0); // Reset to black
      // }

      // // Total Amount
      // doc.setFontSize(12);
      // doc.setFont("helvetica", "bold");
      // doc.text(`Total: Ths ${totalAmount.toLocaleString()}`, 10, startY + (receiptData.length + 3) * rowHeight);

      // // Amount in Words
      // doc.setFontSize(10);
      // doc.text(`Amount in words: ${totalAmountInWords}`, 10, startY + (receiptData.length + 4) * rowHeight);
      // Space for Name
      doc.text(`Received by: ${user?.names}`, 10, 138);
      doc.line(doc.getTextWidth(`Received by: `) + margin, 138 + 1, (pageWidth / 2) - margin, 138 + 1);
      // Space for signature
      doc.text('Signature:', 10, 143);
      doc.line(doc.getTextWidth(`Signature: `) + margin, 143 + 1, (pageWidth / 2) - margin, 143 + 1);

      doc.setLineDash([]); // Dash length and gap

      // Space for signature
      // doc.text('Date:', 10, startY + (receiptData.length + 10) * rowHeight);
      // doc.text('_____________________________', 10, startY + (receiptData.length + 11) * rowHeight);

      // // Footer - Add a horizontal line and Download Text
      // doc.setLineWidth(0.5);
      // doc.line(10, startY + (receiptData.length + 12) * rowHeight, 200, startY + (receiptData.length + 12) * rowHeight);
      doc.setFontSize(8);
      doc.text('Thank you for your payment!', (pageWidth / 2), 147.5, { align: "center" });

      // Download the PDF
      doc.save(`${student.name}_${student.regNumber}.pdf`);
    };


    // Modal content
    Modal.success({
      title: (
        <Row justify="space-between" align="middle" style={{ padding: '10px', width: '100%' }}>
          {/* <Col>
            <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
          </Col> */}
          <Col>
            <Typography.Title level={4} style={{ margin: 0, color: 'var(--primary-text)' }}>
              {student.name}
            </Typography.Title>
            <Typography.Title level={5} style={{ margin: 0, color: 'var(--primary-text)' }}>
              {student.regNumber}
            </Typography.Title>
            {/* <Typography.Text style={{ display: 'block' }}>Address, Phone, Email, Website</Typography.Text> */}
          </Col>
        </Row>
      ),
      className: 'custom-success-modal', // Custom modal class

      content: (
        <div style={{ paddingRight: '10px', paddingLeft: '10px', paddingBottom: '10px', width: '100%' }}>
          <Row style={{ marginBottom: '10px', justifyContent: 'space-between' }}>
            <Col style={{ color: 'var(--primary-text)' }}>Date: {new Date().toLocaleDateString()}</Col>
            <Col style={{ color: 'var(--primary-text)' }}>Payment System: {paymentSystems?.find((ps) => ps.id === paymentDetails?.paymentSystemID)?.systemName||paymentDetails?.paymentSystemID} </Col>
          </Row>
          <Table
            dataSource={receiptData}
            columns={[
              { title: 'Fee Type', dataIndex: 'feeName', key: 'feeName' },
              // { title: 'Group', dataIndex: 'groupName', key: 'groupName' },
              { title: 'Amount Paid', dataIndex: 'amountPaid', key: 'amountPaid', render: (amount) => `Ths ${amount?.toLocaleString()}` },
            ]}
            pagination={false}
            rowKey={(record) => `${record.feeTypeID}-${record.groupID}`}
            bordered
            className="custom-modal-table"
            style={{
              marginTop: '10px',

              // border: '.5px solid var(--primary)', // Table border color using custom primary variable
            }}
            
          // rowClassName="receipt-table-row"
          />
          {
            overdraft &&
            parseInt(overdraft) > 0 &&
            (
              <Row style={{ marginTop: '10px' }}>
                <Col span={24} style={{ textAlign: 'end', color: 'red' }}>
                  Overdraft: Tsh {overdraft.toLocaleString()}
                </Col>
              </Row>
            )
          }
          <Row style={{ marginTop: '10px' }}>
            <Col span={24} style={{ textAlign: 'end', fontWeight: 'bold', color: 'var(--primary-text)' }}>
              Total: Tsh {(parseInt(totalAmount) + (parseInt(overdraft) || 0)).toLocaleString()}
            </Col>
          </Row>
          {/* <Row style={{ marginTop: '20px' }}>
            <Col span={24}>Amount in words: {totalAmountInWords}</Col>
          </Row> */}
          {/* <Row style={{ marginTop: '30px' }}>
            <Col span={24}>Received by:</Col>
            <Col span={24} style={{ marginTop: '10px' }}>_____________________________</Col>
          </Row> */}
        </div>
      ),
      onOk() { },
      footer: (
        <Space>
          <Button
            icon={<DownloadOutlined />}
            type="primary"
            onClick={generatePDF}
            style={{background:'var(--primary)'}}
          >
            Download Receipt
          </Button>
          <Button onClick={() => Modal.destroyAll()}>Close</Button>
        </Space>
      ),
    });
  };

  // Styling for the table row (colored lines for each row in the table)
  const receiptTableRow = {
    '& .ant-table-row': {
      borderBottom: '1px solid var(--primary)', // Apply primary color for row borders
    },
    '& .ant-table-thead > tr > th': {
      backgroundColor: 'var(--primary)',
      color: '#fff',
    },
    '& .ant-table-tbody > tr > td': {
      backgroundColor: '#f9f9f9',
      borderBottom: '1px solid var(--primary)',
    },
    '& .ant-table-tbody > tr:hover': {
      backgroundColor: '#f0f0f0', // Hover effect
    },
  };



  // Utility function to convert number to words
  const convertNumberToWords = (number) => {
    // Simple conversion, for example: 123 -> "One hundred twenty-three"
    const words = [
      'Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine',
      'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen',
      'Twenty', 'Twenty-one', 'Twenty-two', 'Twenty-three', 'Twenty-four', 'Twenty-five', 'Twenty-six', 'Twenty-seven', 'Twenty-eight', 'Twenty-nine', 'Thirty'
    ];
    return words[number] || 'Amount too large';
  };


  const handleViewFees = (studentId) => {
    const { totalFees, feeBreakdown } = calculateStudentFees(studentId, activeAllocations, feeGroups, activeFeeTypes);
    setSelectedStudentFees({ totalFees, feeBreakdown });
    setIsFeeModalVisible(true);
  };

  const handleViewPaidFees = (studentId) => {
    const { totalPaid, feeBreakdown } = calculatePaidFees(studentId);
    setSelectedStudentPaidFees({ totalPaid, feeBreakdown });
    setIsPaidFeesModalVisible(true);
  };

  const calculateStudentFees = (studentId) => {
    const allocations = activeAllocations.filter(allocation => allocation.studentIDs.includes(studentId));

    let totalFees = 0;
    const feeBreakdown = [];

    allocations.forEach(allocation => {
      const group = feeGroups.find(g => g.id === allocation.groupID);

      // Check if group.yearID exists in activeYears before executing the condition
      if (group && activeYears.some(year => year.id === group.yearID)) {
        group.feeDetails.forEach(detail => {
          const feeType = activeFeeTypes.find(fee => fee.id === detail.feeTypeID);

          if (feeType) {
            totalFees += parseFloat(detail.amount);
            feeBreakdown.push({
              feeName: feeType.feeName,
              feeID: feeType.id,
              groupID: group.id,
              groupName: group.groupName,
              amount: parseFloat(detail.amount),
              dueDate: detail.dueDate,
              description: feeType.description,
              groupDescription: group.description
            });
          }
        });
      }
    });

    return { totalFees, feeBreakdown };
  };

  const calculatePaidFees = (studentId) => {
    let totalPaid = 0;
    let totalOverdraft = 0;
    const feeBreakdown = [];

    // Filter payment records for the specific student
    const studentPayments = paymentRecords.filter(record => record.studentID === studentId);

    studentPayments.forEach(record => {
      totalOverdraft += record?.overdraft;

      record.feesPaid.forEach(fee => {
        const { groupID, feeTypeID, amountPaid } = fee;
        const group = feeGroups.find(g => g.id === groupID);

        // Check if group.yearID exists in activeYears before executing the condition
        if (group && activeYears.some(year => year.id === group.yearID)) {
          const feeDetail = group.feeDetails.find(detail => detail.feeTypeID === feeTypeID);
          const feeType = activeFeeTypes.find(f => f.id === feeTypeID);

          if (feeDetail && feeType) {
            const paidAmount = parseFloat(amountPaid || 0);
            totalPaid += paidAmount;

            feeBreakdown.push({
              feeName: feeType.feeName,
              feeID: feeType.id,
              groupID: group.id,
              groupName: group.groupName,
              amountPaid: paidAmount,
              dueDate: feeDetail.dueDate,
              dateOfPayment: record.dateOfPayment,
              time: record.time,
              description: feeType.description,
              groupDescription: group.description,
            });
          }
        }
      });
    });

    return {
      totalPaid,
      totalOverdraft,
      //sort according to dateOfPayment
      feeBreakdown: feeBreakdown.sort((a, b) => a.time - b.time)
    };
  };




  const distributeAmountAmongFeesForRecord = (amount, feeGroups, activeFeeTypes, paymentRecords, studentId) => {
    const distributedFees = [];
    const userFeeGroups = [];

    const allocations = activeAllocations.filter(allocation => allocation.studentIDs.includes(studentId));

    allocations.forEach(allocation => {
      const group = feeGroups.find(g => g.id === allocation.groupID);

      // Check if group.yearID exists in activeYears before executing the condition
      if (group && activeYears.some(year => year.id === group.yearID)) {
        userFeeGroups.push(group);
      }

    });

    // Sort feeTypes based on paymentOrder
    const sortedFeeTypes = activeFeeTypes.sort((a, b) => {
      const orderA = a.paymentOrder === 'first' ? 0 : a.paymentOrder === 'last' ? 2 : 1;
      const orderB = b.paymentOrder === 'first' ? 0 : b.paymentOrder === 'last' ? 2 : 1;
      return orderA - orderB;
    });

    sortedFeeTypes.forEach(feeType => {
      userFeeGroups.forEach(group => {
        const feeDetail = group.feeDetails?.find(detail => detail.feeTypeID === feeType.id);
        if (feeDetail) {
          // const records = paymentRecords
          //   ?.filter(record => record.studentID === studentId)
          //   ?.flatMap(record => record.feesPaid);

          // Retrieve all payment records related to this feeType and group
          const records = paymentRecords
            .filter(record => record.studentID === studentId)
            .flatMap(record => record.feesPaid)
            .filter(rec => rec.groupID === group.id && rec.feeTypeID === feeType.id);

          // Calculate total paid amount for this feeType across all matching records
          const totalPaidAmount = records.reduce((sum, rec) => sum + (rec.amountPaid || 0), 0);
          const remainingAmount = feeDetail.amount - totalPaidAmount;

          // const amountPaid = amount > 0 ? Math.min(amount, remainingAmount) : 0;

          if (remainingAmount > 0) {
            const amountToPay = Math.min(amount, remainingAmount);
            if (amountToPay > 0) {
              distributedFees.push({
                groupID: group.id,
                feeTypeID: feeType.id,
                amountPaid: amountToPay
              });
            }
            amount -= amountToPay;
          }
        }
      });
    });

    return distributedFees;
  };

  const distributeAmountAmongFees = (amount, feeGroups, activeFeeTypes, paymentRecords, studentId) => {
    const distributedFees = [];
    const userFeeGroups = [];

    // Filter feeGroups for the specific student based on activeAllocations
    const allocations = activeAllocations.filter(allocation => allocation.studentIDs.includes(studentId));
    allocations.forEach(allocation => {
      const group = feeGroups.find(g => g.id === allocation.groupID);
      // Check if group.yearID exists in activeYears before executing the condition
      if (group && activeYears.some(year => year.id === group.yearID)) {
        userFeeGroups.push(group);
      }
    });

    // Sort feeTypes based on paymentOrder
    const sortedFeeTypes = activeFeeTypes.sort((a, b) => {
      const orderA = a.paymentOrder === 'first' ? 0 : a.paymentOrder === 'last' ? 2 : 1;
      const orderB = b.paymentOrder === 'first' ? 0 : b.paymentOrder === 'last' ? 2 : 1;
      return orderA - orderB;
    });

    // Calculate distribution
    sortedFeeTypes.forEach(feeType => {
      userFeeGroups.forEach(group => {
        const feeDetail = group.feeDetails?.find(detail => detail.feeTypeID === feeType.id);
        if (feeDetail) {
          // Retrieve all payment records related to this feeType and group
          const records = paymentRecords
            .filter(record => record.studentID === studentId)
            .flatMap(record => record.feesPaid)
            .filter(rec => rec.groupID === group.id && rec.feeTypeID === feeType.id);

          // Calculate total paid amount for this feeType across all records
          const totalPaidAmount = records.reduce((sum, rec) => sum + (rec.amountPaid || 0), 0);
          const remainingAmount = feeDetail.amount - totalPaidAmount;
          const amountPaid = amount > 0 ? Math.min(amount, remainingAmount) : 0;

          distributedFees.push({
            groupID: group.id,
            feeTypeID: feeType.id,
            groupName: group.groupName,
            groupDescription: group.description,
            amountPaid,
            dueDate: feeDetail.dueDate,
            maxPayableAmount: remainingAmount
          });

          // Deduct distributed amount from the remaining amount
          amount -= amountPaid;
        }
      });
    });

    return distributedFees;
  };


  const columns = [
    {
      title: 'S/No',
      dataIndex: 'id',
      key: 'id',
      // dataIndex: 'key',
      // key: 'key',
      render: (_, __, index) => index + 1, // Serial number
    },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    {
      title: 'Reg Number',
      dataIndex: 'regNumber',
      key: 'regNumber',
    },
    { title: 'Gender', dataIndex: 'gender', key: 'gender' },
    {
      title: 'Date Joined', dataIndex: 'dateOfAdmission', key: 'dateOfAdmission',
      render: (dateOfAdmission) => dayjs(dateOfAdmission).format('YYYY-MM-DD') || 'N/A',
    },
    {
      title: 'Class',
      dataIndex: 'classes',
      render: (renderClass) => {
        const activeFeeGroupYearIDs = feeGroups
          // ?.filter(group => group?.active) // Adjust `active` to match your active status key
          ?.map(group => group?.yearID);
        const matchingClassInStudent = renderClass?.find(classItem =>
          activeFeeGroupYearIDs?.includes(classItem.year)
        );
        const matchingClass = classes?.find(clas => clas.id === matchingClassInStudent?.class); // Get the class matching the filter
        return matchingClass?.className || 'N/A'; // assuming `className` holds the class name
      },
    },
    {
      title: 'Stream',
      dataIndex: 'classes',
      render: (renderClass) => {
        //   const matchingGroup = feeGroups?.filter((group) => group?.id === feeGroup) || {};
        //   const matchingClassInStudent = renderClass?.find(classItem => classItem.year === matchingGroup[0]?.yearID);
        const activeFeeGroupYearIDs = feeGroups
          // ?.filter(group => group?.active) // Adjust `active` to match your active status key
          ?.map(group => group?.yearID);
        const matchingClassInStudent = renderClass?.find(classItem =>
          activeFeeGroupYearIDs?.includes(classItem.year)
        );
        return matchingClassInStudent?.stream || 'N/A'; // assuming `stream` holds the stream name
      },
    },
    {
      title: 'Total Fee',
      dataIndex: 'amountDue',
      key: 'amountDue',
      render: (_, student) => {
        const { totalFees } = calculateStudentFees(student.id);
        return (
          <Button type="link" onClick={() => handleViewFees(student.id)}>
            Tsh {totalFees?.toLocaleString()}
          </Button>
        );
      },
    },
    {
      title: 'Amount Paid',
      dataIndex: 'amountPaid',
      key: 'amountPaid',
      render: (_, student) => {
        const { totalPaid } = calculatePaidFees(student.id);
        // console.log(calculatePaidFees(student.id))
        return (
          <Button type="link" onClick={() => handleViewPaidFees(student.id)}>
            Tsh {totalPaid?.toLocaleString()}
          </Button>
        );
      },
    },
    {
      title: 'Amount Remaining',
      dataIndex: 'amountRemaining',
      key: 'amountRemaining',
      render: (_, student) => {
        const { totalFees } = calculateStudentFees(student.id);
        const { totalPaid } = calculatePaidFees(student.id);
        // console.log(calculatePaidFees(student.id))
        const remainingAmount = totalFees - totalPaid;
        return (
          <span style={{ color: remainingAmount < 0 ? 'red' : 'inherit' }}>
            Tsh {remainingAmount?.toLocaleString()}
          </span>
        );
      },
    },
    {
      title: 'Total Overdraft',
      dataIndex: 'totalOverdraft',
      key: 'totalOverdraft',
      render: (_, student) => {
        const { totalOverdraft } = calculatePaidFees(student.id);
        return (
          <span style={{ color: totalOverdraft < 0 ? 'red' : 'inherit' }}>
            Tsh {totalOverdraft?.toLocaleString()}
          </span>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, student) => {
        const { totalFees } = calculateStudentFees(student.id);
        const { totalPaid } = calculatePaidFees(student.id);
        // console.log(calculatePaidFees(student.id))
        const remainingAmount = totalFees - totalPaid;
        return (
          <>
            {
              fetchedRecords &&
              <Button type="link" onClick={() => handlePayClick(student, remainingAmount)}>Pay</Button>
            }
          </>
        );
      },
    },
  ];

  return (
    <Wrapper>
      <Breadcrumb
        style={{ marginBottom: 10 }}
        items={[
          {
            title: <Link to='/'><DashboardOutlined /></Link>,
          },
          {
            title: "Payments",
          },
          {
            title: 'Collect Payments'
          },

        ]}
      />
      <Search
        placeholder="Search by Name or Registration Number"
        onSearch={handleSearch}
        onChange={handleSearchText}
        enterButton
        style={{ width: '80%', marginBottom: 20, justifySelf: 'center', display: 'flex' }}
      />

      <FiltersContainer>
        <Select
          allowClear
          placeholder="Select Study Year"
          // value={selectedYear}
          // onChange={setSelectedYear}
          onChange={(value) => setFilterOptions(prev => ({ ...prev, year: value, class: null, stream: null }))}
          options={activeYears.map((year) => ({ label: year.name, value: year.id }))}
          style={{ width: 200, marginBottom: 16, marginLeft: 4, marginRight: 4 }}
        />

        <Select
          allowClear
          placeholder="Select Class"
          style={{ width: 150 }}
          onChange={(value) => setFilterOptions(prev => ({ ...prev, class: value, stream: null }))}
          value={filterOptions.class || undefined} // Bind to state and reset to undefined when cleared
        >
          {
            filterOptions.year ?
              classes
                ?.filter((clas) => clas.yearID === filterOptions.year)
                ?.filter(cla => (activeYears
                  ?.map(year => year?.id)?.includes(cla.yearID)))
                ?.map((classItem) => (
                  <Option key={classItem.id} value={classItem.id}>{classItem.className}</Option>
                )) :
              classes
                ?.filter(cla => (activeYears
                  ?.map(year => year?.id)?.includes(cla.yearID)))
                ?.map((classItem) => (
                  <Option key={classItem.id} value={classItem.id}>{classItem.className}</Option>
                ))
          }
        </Select>

        <Select
          allowClear
          placeholder="Select Stream"
          style={{ width: 150 }}
          disabled={!filterOptions.class}
          onChange={(value) => setFilterOptions(prev => ({ ...prev, stream: value }))}
          value={filterOptions.stream || undefined} // Bind to state and reset to undefined when cleared
        >
          {/* Assuming streams are provided as part of the class data */}
          {
            filterOptions.class ?
              getStreamOptions()?.map(stream => (
                <Option key={stream} value={stream}>{stream}</Option>
              )) :
              classes?.flatMap(classItem => classItem.streams)?.map(stream => (
                <Option key={stream} value={stream}>{stream}</Option>
              ))
          }
        </Select>

        <Select
          allowClear
          placeholder="Select Gender"
          style={{ width: 150 }}
          onChange={(value) => setFilterOptions(prev => ({ ...prev, gender: value }))}
          value={filterOptions.gender || undefined} // Bind to state and reset to undefined when cleared
        >
          <Option value="Male">Male</Option>
          <Option value="Female">Female</Option>
        </Select>

        <Button type="primary" onClick={handleFilter}>Filter</Button>
      </FiltersContainer>

      <Table
        dataSource={
          filteredStudents
            ?.filter(student => student?.classes?.some(classItem => activeYears?.map(year => year.id)?.includes(classItem.year)))
            ?.sort((a, b) => a.name.localeCompare(b.name))
        }
        columns={columns}
        pagination={{ pageSize: 10 }}
        bordered
        rowKey="id"
        rowClassName={(record) => {
          const currentDate = new Date();

          const previewDistribution = distributeAmountAmongFees(
            0, // Start with 0 to show max payable amounts
            feeGroups,
            activeFeeTypes,
            paymentRecords,
            record.id
          );

          const hasOverdueFee = previewDistribution.some(
            (fee) => fee.maxPayableAmount > 0 && new Date(fee.dueDate) < currentDate
          );

          // console.log(hasOverdueFee); // true if any fee meets the condition, otherwise false

          if (hasOverdueFee) {
            return 'danger-row'; // CSS class for danger background
          }
          return ''; // No class applied
        }}
      />

      <PaymentModal
        visible={isPayModalVisible}
        handleModalOk={handleModalOk}
        handleModalCancel={handleModalCancel}
        paymentDetails={paymentDetails}
        setPaymentDetails={setPaymentDetails}
        paymentSystems={paymentSystems}
        distributionPreview={distributionPreview}
        activeFeeTypes={activeFeeTypes}
        feeTypesWithPassedDueDates={feeTypesWithPassedDueDates}
      />

      <FeeModal
        visible={isFeeModalVisible}
        onClose={() => setIsFeeModalVisible(false)}
        feeData={selectedStudentFees}
      />

      <PaidFeesModal
        visible={isPaidFeesModalVisible}
        onClose={() => setIsPaidFeesModalVisible(false)}
        feeData={selectedStudentPaidFees}
      />

      {/* Modal for loading state */}
      <Modal open={isCreatingPaymentRecord} footer={null} closable={false} centered style={{ alignItems: 'center' }}>
        {/* <Spin tip="Processing payment record..." size="large" /> */}
        <Spin
          tip="Processing payment record..."
          size="large"
          style={{
            color: 'var(--primary-color)', // Spin color
            // height:100
          }}
          spinning={true}
        >
          <div
            style={{
              color: 'var(--primary-color)', // Tip text color
              height:100,

              textAlign: 'center', // Align tip with the spinner
            }}
          >
            {/* Processing payment record... */}
          </div>
        </Spin>

      </Modal>


    </Wrapper>
  );
};

const PaymentModal = ({
  visible,
  handleModalOk,
  handleModalCancel,
  paymentDetails,
  setPaymentDetails,
  paymentSystems,
  distributionPreview,
  activeFeeTypes,
  feeTypesWithPassedDueDates
}) => {

  const user = useSelector(state => state.user.user);

  const [form] = Form.useForm();

  const handleOk = () => {
    const { amount } = paymentDetails;

    if (!amount || isNaN(amount) || amount <= 0) {
      showErrorModal("Amount should be valid and greater than zero.");
      return;
    }

    form
      .validateFields()
      .then((values) => {
        // console.log(values)
        handleModalOk(values); // Pass the form values to the parent
        form.resetFields(); // Reset the form after submission
        handleModalCancel(); // Close the modal
      })
      .catch((info) => {
        console.error("Validation failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields(); // Reset form fields on cancel
    handleModalCancel();
  };


  return (
    <Modal
      title="Enter Payment Details"
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <div style={{ marginBottom: 4, marginTop: 20 }}>
        <Text style={{ fontWeight: 'normal' }}><span style={{ color: '#F44', fontSize: 20, verticalAlign: 'middle' }}>* </span>Enter Amount (Tsh {paymentDetails?.remainingAmount?.toLocaleString()} remaining)</Text>
      </div>

      <Input
        style={{ marginBottom: '10px' }}
        placeholder="Enter Amount"
        type="number"
        value={paymentDetails.amount}
        required
        onChange={(e) => setPaymentDetails({ ...paymentDetails, amount: parseFloat(e.target.value) })}
      />
      {/*  <DatePicker
        style={{ width: '50%', paddingRight: '10px', marginBottom: '10px' }}
        placeholder="Select Payment Date"
        required
        onChange={(date) => setPaymentDetails({ ...paymentDetails, dateOfPayment: date })}
      />
      <Select
        style={{ width: '50%', paddingLeft: '10px', marginBottom: '10px' }}
        placeholder="Select Payment System"
        onChange={(value) => setPaymentDetails({ ...paymentDetails, paymentSystemID: value })}
      >

        {paymentSystems.map(system => (
          <Option key={system.id} value={system.id}>{system.systemName}</Option>
        ))}
      </Select>

      <Input.TextArea
        style={{ marginBottom: '10px' }}
        placeholder="Enter Description"
        value={paymentDetails.description}
        // rows={4}  // Set number of visible rows
        // maxLength={500} // Optional: limit the input length
        onChange={(e) => setPaymentDetails({ ...paymentDetails, description: e.target.value })}
      /> */}

      <Form
        form={form}
        layout="vertical"
        initialValues={{
          dateOfPayment: null,
          paymentSystemID: null,
          description: "",
        }}
      >
        {/* <Form.Item
          name="amount"
          label="Enter Amount"
          rules={[{ required: true, message: "Please enter an amount!" }]}
        >
          <Input type="number" placeholder="Enter Amount" />
        </Form.Item> */}
        <div style={{ flexDirection: 'row', display: 'flex', gap: '20px' }}>
          <Form.Item
            name="dateOfPayment"
            label="Select Payment Date"
            style={{ width: '50%', marginBottom: '10px' }}
            rules={[
              { required: true, message: "Please select a payment date!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value) {
                    return Promise.reject("Please select a payment date!");
                  }
                  if (value.isAfter(dayjs())) {
                    return Promise.reject("The payment date cannot be in the future!");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DatePicker style={{ width: "100%" }} placeholder="Select Payment Date" />
          </Form.Item>
          <Form.Item
            name="paymentSystemID"
            label="Select Payment System"
            style={{ width: '50%', marginBottom: '10px' }}
            rules={[{ required: true, message: "Please select a payment system!" }]}
          >
            <Select placeholder="Select Payment System">
              {paymentSystems.map((system) => (
                <Option key={system.id} value={system.id}>
                  {system?.systemName}
                </Option>
              ))}
              {
              user?.role==='admin'&&
               <Option key='forgive' value='forgiven'>
               FORGIVE
             </Option>
             }
            </Select>
          </Form.Item>
        </div>

        <Form.Item
          name="description"
          label="Enter Description"
          rules={[{ required: false }]} // Optional field
        >
          <Input.TextArea placeholder="Enter Description" />
        </Form.Item>
      </Form>

      <div style={{ marginTop: 20 }}>
        <Text strong>Amount Distribution Preview</Text>
        <Table
          dataSource={distributionPreview}
          bordered
          columns={[
            {
              title: 'Fee Type',
              dataIndex: 'feeTypeID',
              key: 'feeTypeID',
              render: (feeTypeID) => (
                <Tooltip title={activeFeeTypes.find(type => type.id === feeTypeID)?.description}>
                  {activeFeeTypes.find(type => type.id === feeTypeID)?.feeName}
                </Tooltip>
              )
            },
            {
              title: 'Group Name',
              dataIndex: 'groupName',
              key: 'groupName',
              render: (text, record) => (
                <Tooltip title={record.groupDescription}>
                  <Text>{text}</Text>
                </Tooltip>
              ),
            },
            {
              title: 'Amount Remaining',
              dataIndex: 'maxPayableAmount',
              key: 'maxPayableAmount',
              render: (amount) => `Ths ${amount?.toLocaleString()}`
            },
            {
              title: 'Amount To Be Paid',
              dataIndex: 'amountPaid',
              key: 'amountToBePaid',
              render: (amount) => `Ths ${amount?.toLocaleString()}`
            }
          ]}
          pagination={false}
          rowKey={(record) => `${record.feeTypeID}-${record.groupID}`}
          rowClassName={(record) => {

            const hasOverdueFee = feeTypesWithPassedDueDates?.includes(record.feeTypeID)

            if (hasOverdueFee && record.maxPayableAmount > 0) {
              return 'danger-row'; // CSS class for danger background
            }
            return ''; // No class applied
          }}
        />
        {
          paymentDetails.amount >
          distributionPreview.reduce((total, entry) => total + entry.maxPayableAmount, 0) &&
          <Text style={{color:'var(--primary-text)', textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
            Overdraft:
            <span style={{ color: 'red', marginLeft: '5px' }}>Tsh
              {
                (paymentDetails.amount -
                  distributionPreview.reduce((total, entry) => total + entry.maxPayableAmount, 0)).toLocaleString()
              }
            </span>
          </Text>
        }

      </div>
    </Modal>
  );
};

const FeeModal = ({ visible, onClose, feeData }) => {
  const { totalFees, feeBreakdown } = feeData;

  return (
    <Modal
      title="Fee Breakdown"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <div style={{ marginBottom: '16px' }}>
        <Text strong>Total Fees Allocated:</Text> <Text style={{ fontSize: 20 }}>Tsh {totalFees?.toLocaleString()}</Text>
      </div>

      <Table
        dataSource={feeBreakdown}
        pagination={false}
        rowKey={(record) => record.feeID + record.groupID}
        bordered
        columns={[
          {
            title: 'Fee Name',
            dataIndex: 'feeName',
            key: 'feeName',
            render: (text, record) => (
              <Tooltip title={record.description}>
                <Text>{text}</Text>
              </Tooltip>
            ),
          },
          {
            title: 'Group Name',
            dataIndex: 'groupName',
            key: 'groupName',
            render: (text, record) => (
              <Tooltip title={record.groupDescription}>
                <Text>{text}</Text>
              </Tooltip>
            ),
          },
          {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount) => `Tsh ${amount.toLocaleString()}`,
          },
          {
            title: 'Due Date',
            dataIndex: 'dueDate',
            key: 'dueDate',
            render: (dueDate) => dueDate ? dayjs(dueDate).format('YYYY-MM-DD') : 'N/A',
          },
        ]}
      />
    </Modal>
  );
};
const PaidFeesModal = ({ visible, onClose, feeData }) => {
  const { totalPaid, feeBreakdown } = feeData;

  return (
    <Modal
      title="Paid Fees Breakdown"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <div style={{ marginBottom: '16px' }}>
        <Text strong>Total Amount Paid:</Text> <Text style={{ color: 'green', fontSize: 20 }}>Tsh {totalPaid?.toLocaleString()}</Text>
      </div>

      <Table
        dataSource={feeBreakdown}
        pagination={false}
        bordered
        rowKey={(record) => record.feeID + record.groupID + record.dateOfPayment + record.time}
        columns={[
          {
            title: 'Date Received',
            dataIndex: 'time',
            key: 'time',
            render: (time) => time ? dayjs(time).format('YYYY-MM-DD') : 'N/A',
          },
          {
            title: 'Fee Name',
            dataIndex: 'feeName',
            key: 'feeName',
            render: (text, record) => (
              <Tooltip title={record.description}>
                <Text>{text}</Text>
              </Tooltip>
            ),
          },
          {
            title: 'Group Name',
            dataIndex: 'groupName',
            key: 'groupName',
            render: (text, record) => (
              <Tooltip title={record.groupDescription}>
                <Text>{text}</Text>
              </Tooltip>
            ),
          },
          {
            title: 'Amount',
            dataIndex: 'amountPaid',
            key: 'amountPaid',
            render: (amountPaid) => `Tsh ${amountPaid.toLocaleString()}`,
          },
          {
            title: 'Date Paid',
            dataIndex: 'dateOfPayment',
            key: 'dateOfPayment',
            render: (dateOfPayment) => dateOfPayment ? dayjs(dateOfPayment).format('YYYY-MM-DD') : 'N/A',
          },
        ]}
      />
    </Modal>
  );
};

const showErrorModal = (errorMessage) => {
  Modal.error({
    title: <span style={{ color: 'var(--primary-text)' }}>Validation Error</span>,
    content: errorMessage,
  });
};


export default CollectPayments;
